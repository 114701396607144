import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { subDays, addDays } from 'date-fns'
import { Button } from 'reactstrap'
import NewsEditDropdownFilterDatePicker from '../../../components/NewsEditDropdownFilter/NewsEditDropdownFilterDatePicker'
import AutoCompleteFilter from '../../../components/AutoCompleteFilter/AutoCompleteFilter'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import './NewsFeedFilterHeader.css'
import SaveView from './SaveViews'
import store from '../../../Store'
import { Circles } from 'react-loader-spinner'
import RegionHiearchyMultiSelect from './RegionHiearchyMultiSelect'

export default function NewsFeedFilterHeader(props) {
    const { regionHierarchy } = props

    const usertype = store.getState().auth.user.user_type
    const [deletedReasons, setDeletedReasons] = useState({})
    //default for raw and published news - news article date
    const [DateRangeValue, setDateRangeValue] = useState({
        startDate: subDays(new Date(), 365),
        endDate: addDays(new Date(), 1),
    })
    //Raw news pulled date
    const [DatePulledValue, setDatePulledValue] = useState({
        startDate: subDays(new Date(), 7),
        endDate: addDays(new Date(), 1),
    })
    //Published news publish date
    const [DatePublishedValue, setDatePublishedValue] = useState({
        startDate: subDays(new Date(), 7),
        endDate: addDays(new Date(), 1),
    })

    //defaults for deals page
    const [dealsDateRangeValue, setDealsDateRangeValue] = useState({
        startDate: subDays(new Date(), 365),
        endDate: addDays(new Date(), 1),
    })
    const [dealPullDate, setDealPullDate] = useState({
        startDate: subDays(new Date(), 7),
        endDate: addDays(new Date(), 1),
    })

    useEffect(() => {
        if (props.publishedFilters) {
            setDatePulledValue({
                startDate: null,
                endDate: null,
            })
        }
    }, [props.publishedFilters])

    const [allSubCategories, setAllSubCategories] = useState([])
    const [selectedFilters, setSelectedFilters] = useState({
        date: props.onDealsScreen ? dealsDateRangeValue : DateRangeValue,
        pullDate: props.publishedFilters ? { startDate: null, endDate: null } : DatePulledValue,
        publishedDate: DatePublishedValue,
        dealPullDate: dealPullDate,
        industries: [],
        sectors: [],
        companies: [],
        countries: [],
        sources: [],
        content: [],
        subcategories: [],
        newsType: { label: 'All', value: 'All' },
        sent: { label: 'Not Sent', value: 'notSent' },
        pullType: { label: 'Sector & Company', value: 'all' },
        deleted: { label: 'Not Deleted', value: 'notDeleted' },
        featured: { label: 'All', value: 'All' },
        lawFirms: [],
        topics: [],
        underReview: { label: 'Both', value: 'both' },
        textSearch: '',
        deletedReason: '',
        publisher: '',
    })

    useEffect(() => {
        axios.get('/news/discard-reasons/').then(res => {
            setDeletedReasons(
                res.data.results.map(each => {
                    return { value: each.id, label: each.reason }
                })
            )
        })
    }, [])

    const updateFilters = data => {
        setSelectedFilters({
            ...selectedFilters,
            industries: data.industries,
            companies: data.companies,
            countries: data.country,
            sources: data.sources,
            subcategories: data.subcategories,
            topics: data.topics,
            underReview: data.underreview,
            textSearch: data.searchtext,
            deleted: data.deleted,
        })
    }

    useEffect(() => {
        if (props.allCategories) {
            let subCatArray = []
            props.allCategories.forEach(cat => {
                cat.subcategories.forEach(sub => {
                    let subCatObj = { label: sub.name, value: sub.id }
                    subCatArray.push(subCatObj)
                })
            })
            setAllSubCategories(subCatArray)
        }
    }, [props.allCategories])

    const setDateRange = new_date_range => {
        const modified_date_range = {
            startDate: new_date_range.startDate,
            endDate: new_date_range.endDate,
        }
        if (props.onDealsScreen) {
            setDealsDateRangeValue(modified_date_range)
        } else {
            setDateRangeValue(modified_date_range)
        }
        setSelectedFilters({ ...selectedFilters, date: modified_date_range })
    }
    const setRawPullDate = new_date_range => {
        const modified_date_range = {
            startDate: new_date_range.startDate,
            endDate: new_date_range.endDate,
        }
        setDatePulledValue(modified_date_range)
        setSelectedFilters({ ...selectedFilters, pullDate: modified_date_range })
    }
    const setPublishedDate = new_date_range => {
        const modified_date_range = {
            startDate: new_date_range.startDate,
            endDate: new_date_range.endDate,
        }
        setDatePublishedValue(modified_date_range)
        setSelectedFilters({ ...selectedFilters, publishedDate: modified_date_range })
    }
    const setPullDateRangeFunction = new_date_range => {
        const modified_date_range = {
            startDate: new_date_range.value.startDate,
            endDate: new_date_range.value.endDate,
        }
        setDealPullDate(modified_date_range)
        setSelectedFilters({ ...selectedFilters, dealPullDate: modified_date_range })
    }

    const resetFilters = () => {
        setDateRangeValue({
            startDate: subDays(new Date(), 365),
            endDate: addDays(new Date(), 1),
        })
        {
            !props.publishedFilters
                ? setDatePulledValue({
                      startDate: subDays(new Date(), 7),
                      endDate: addDays(new Date(), 1),
                  })
                : setDatePulledValue({
                      startDate: null,
                      endDate: null,
                  })
        }
        setDatePublishedValue({
            startDate: subDays(new Date(), 7),
            endDate: addDays(new Date(), 1),
        })
        setDealsDateRangeValue({
            startDate: subDays(new Date(), 365),
            endDate: addDays(new Date(), 1),
        })
        setDealPullDate({
            startDate: subDays(new Date(), 7),
            endDate: addDays(new Date(), 1),
        })
        setSelectedFilters({
            date: {
                startDate: props.onDealsScreen ? subDays(new Date(), 365) : subDays(new Date(), 7),
                endDate: addDays(new Date(), 1),
            },
            pullDate: {
                startDate: subDays(new Date(), 7),
                endDate: addDays(new Date(), 1),
            },
            publishedDate: {
                startDate: subDays(new Date(), 7),
                endDate: addDays(new Date(), 1),
            },
            dealPullDate: {
                startDate: subDays(new Date(), 7),
                endDate: addDays(new Date(), 1),
            },
            industries: [],
            sectors: [],
            companies: [],
            countries: [],
            country: [],
            sources: [],
            content: [],
            subcategories: [],
            newsType: { label: 'All', value: 'All' },
            sent: { label: 'Not Sent', value: 'notSent' },
            pullType: { label: 'Sector & Company', value: 'all' },
            featured: { label: 'All', value: 'All' },
            lawFirms: [],
            underReview: { label: 'Not Under Review', value: 'notUnderReview' },
            topics: [],
            deleted: { label: 'Not Deleted', value: 'notDeleted' },
            textSearch: '',
            deletedReason: '',
            publisher: '',
        })
        if (props.setSidebarIndustries) {
            props.setSidebarIndustries({
                industry: {},
                sector: {},
                company: {},
            })
        }
        props.getAllRawNews()
        // props.setSelectedStories([])
    }

    const getLawFirmsSearch = value => {
        if (value.length > 2) {
            return axios.get(`/news/auto-complete/?type=lawfirm&string=${value}`).then(response => {
                return response.data.map(item => ({ label: item.name, value: item.id }))
            })
        }
    }
    return (
        <div className="filterHeader__container">
            <h3>Filter News by</h3>

            <div className="filterHeader__filterBoxes">
                <div>
                    <span className="filterHeader__dateLabel">Article date</span>
                    <NewsEditDropdownFilterDatePicker
                        setDateRange={setDateRange}
                        selectedDateRange={props.onDealsScreen ? dealsDateRangeValue : DateRangeValue}
                    />
                </div>
                {usertype !== 'client_analyst' && (
                    <div>
                        <span className="filterHeader__dateLabel">Pull date</span>
                        <NewsEditDropdownFilterDatePicker
                            setDateRange={setRawPullDate}
                            selectedDateRange={DatePulledValue}
                        />
                    </div>
                )}
                {props.publishedFilters && (
                    <div>
                        <span className="filterHeader__dateLabel">Published date</span>
                        <NewsEditDropdownFilterDatePicker
                            setDateRange={setPublishedDate}
                            selectedDateRange={DatePublishedValue}
                        />
                    </div>
                )}

                {props.onDealsScreen && (
                    <div>
                        <span className="filterHeader__dateLabel">Pull date</span>
                        <Select
                            name="pull date"
                            options={[
                                {
                                    label: '1 week',
                                    value: {
                                        startDate: subDays(new Date(), 7),
                                        endDate: addDays(new Date(), 1),
                                    },
                                },
                                {
                                    label: '30 Days',
                                    value: {
                                        startDate: subDays(new Date(), 30),
                                        endDate: addDays(new Date(), 1),
                                    },
                                },
                            ]}
                            onChange={value => {
                                setPullDateRangeFunction(value)
                            }}
                            placeholder="Pull Date"
                            className="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {(props.publishedFilters || props.onRawPage) && (
                    <div>
                        <span className="filterHeader__dateLabel">Industry/Sector</span>
                        <AutoCompleteFilter
                            name="sector"
                            options={props.allIndustries}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, industries: value })
                            }}
                            value={selectedFilters.industries}
                            placeholder="Industry/Sector"
                            classOverride="companiesAutoCompleteBox225"
                        />
                    </div>
                )}

                {(props.onRawPage || props.publishedFilters) && (
                    <div>
                        <span className="filterHeader__dateLabel">Company</span>
                        <AutoCompleteFilter
                            name="company"
                            options={props.allCompanies.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, companies: value })
                            }}
                            value={selectedFilters.companies}
                            placeholder={
                                props.allCompanies.length === 0 ? (
                                    <Circles key={0} type="Grid" color="#adadad" height={20} width={20} />
                                ) : (
                                    'Company'
                                )
                            }
                            classOverride="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {props.onDealsScreen && (
                    <div>
                        <span className="filterHeader__dateLabel">Law Firm</span>
                        <AsyncSelect
                            isMulti
                            name="Law Firm"
                            loadOptions={getLawFirmsSearch}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, lawFirms: value })
                            }}
                            value={selectedFilters.lawFirms}
                            placeholder="Law Firm"
                            className="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                <div>
                    <span className="filterHeader__dateLabel">Region/Country</span>
                    {/* <AutoCompleteFilter
                        name="region"
                        options={props.allRegions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                        onChange={value => {
                            setSelectedFilters({ ...selectedFilters, countries: value })
                        }}
                        value={selectedFilters.regions}
                        placeholder="Country"
                        classOverride="companiesAutoCompleteBox125"
                    /> */}
                    <RegionHiearchyMultiSelect
                        regionHierarchy={regionHierarchy}
                        isAnalyst={true}
                        selectedQueries={selectedFilters}
                        setSelectedQueries={setSelectedFilters}
                    />
                </div>

                {(props.publishedFilters || props.onRawPage) && (
                    <div>
                        {usertype !== 'client_analyst' ? (
                            <span className="filterHeader__dateLabel">Subcategory</span>
                        ) : (
                            <span className="filterHeader__dateLabel">Event types</span>
                        )}
                        <AutoCompleteFilter
                            name="Category"
                            options={props.allSubCategoriesOnly.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, subcategories: value })
                            }}
                            value={selectedFilters.subcategories}
                            placeholder={usertype !== 'client_analyst' ? 'Sub-category' : 'Event types'}
                            classOverride="companiesAutoCompleteBox225"
                        />
                    </div>
                )}

                {usertype !== 'client_analyst' && (
                    <div>
                        <span className="filterHeader__dateLabel">Topics</span>
                        <AutoCompleteFilter
                            name="topics"
                            options={props.allTopics.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, topics: value })
                            }}
                            value={selectedFilters.topics}
                            placeholder="Topics"
                            classOverride="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {props.onRawPage && (
                    <div>
                        <span className="filterHeader__dateLabel">Source</span>
                        <AutoCompleteFilter
                            name="source"
                            options={[
                                {
                                    value: 'Mondaq',
                                    label: 'Mondaq',
                                },
                                {
                                    value: 'Competition',
                                    label: 'Competition',
                                },
                                {
                                    value: 'Webhose',
                                    label: 'Webhose',
                                },
                                {
                                    value: 'Yahoo Finance',
                                    label: 'Yahoo Finance',
                                },
                                {
                                    value: 'RSS',
                                    label: 'RSS',
                                },
                            ]}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, sources: value })
                            }}
                            value={selectedFilters.sources}
                            placeholder="Source"
                            classOverride="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {props.onRawPage && (
                    <>
                        <div>
                            <span className="filterHeader__dateLabel">Deleted</span>
                            <Select
                                name="deleted"
                                options={[
                                    {
                                        value: 'notDeleted',
                                        label: 'Not Deleted',
                                    },
                                    {
                                        value: 'deleted',
                                        label: 'Deleted',
                                    },
                                ]}
                                onChange={value => {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        deleted: value,
                                        deletedReason: value.value === 'deleted' ? selectedFilters.deletedReason : '',
                                    })
                                }}
                                value={selectedFilters.deleted}
                                placeholder="Deleted"
                                className="companiesAutoCompleteBox125"
                            />
                        </div>

                        {selectedFilters.deleted.value === 'deleted' && (
                            <div>
                                <span className="filterHeader__dateLabel">Reason</span>
                                <Select
                                    name="deleted_reason"
                                    options={deletedReasons}
                                    onChange={value => {
                                        setSelectedFilters({ ...selectedFilters, deletedReason: value })
                                    }}
                                    value={selectedFilters.deletedReason}
                                    placeholder="Reason"
                                    className="companiesAutoCompleteBox125"
                                />
                            </div>
                        )}
                    </>
                )}

                {props.publishedFilters && usertype !== 'client_analyst' && (
                    <div>
                        <span className="filterHeader__dateLabel">Publisher</span>
                        <Select
                            name="publish_type"
                            options={[{ label: 'Analyst', value: 'Analyst' }, { label: 'GPT', value: 'GPT' }]}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, publisher: value })
                            }}
                            value={selectedFilters.publisher}
                            className="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {props.publishedFilters && (
                    <div>
                        <span className="filterHeader__dateLabel">Sent</span>
                        <Select
                            name="Sent"
                            options={[
                                { label: 'Not Sent', value: 'notSent' },
                                { label: 'Sent', value: 'sent' },
                                { label: 'All', value: 'all' },
                            ]}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, sent: value })
                            }}
                            value={selectedFilters.sent}
                            className="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {props.publishedFilters && usertype !== 'client_analyst' && (
                    <div>
                        <span className="filterHeader__dateLabel">Featured</span>
                        <Select
                            name="Featured"
                            options={[
                                { label: 'Not Featured', value: 'false' },
                                { label: 'Featured', value: 'true' },
                                { label: 'All', value: 'All' },
                            ]}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, featured: value })
                            }}
                            value={selectedFilters.featured}
                            className="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {(props.onRawPage || props.publishedFilters) && usertype !== 'client_analyst' && (
                    <div>
                        <span className="filterHeader__dateLabel">News Type</span>
                        <Select
                            name="News Type"
                            options={[
                                { label: 'All', value: 'All' },
                                { label: 'Company News', value: 'Company' },
                                { label: 'Topic News', value: 'Sector' },
                                { label: 'Manual News', value: 'Manual' },
                            ]}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, newsType: value })
                            }}
                            value={selectedFilters.newsType}
                            className="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {props.onRawPage && (
                    <div>
                        <span className="filterHeader__dateLabel">Review</span>
                        <Select
                            name="underReview"
                            options={[
                                {
                                    value: 'both',
                                    label: 'Both',
                                },
                                {
                                    value: 'notUnderReview',
                                    label: 'Not Under Review',
                                },
                                {
                                    value: 'underReview',
                                    label: 'Under Review',
                                },
                            ]}
                            onChange={value => {
                                setSelectedFilters({ ...selectedFilters, underReview: value })
                            }}
                            value={selectedFilters.underReview}
                            placeholder="Under Review"
                            className="companiesAutoCompleteBox125"
                        />
                    </div>
                )}

                {props.onRawPage && (
                    <div style={{ width: '250px' }}>
                        <span className="filterHeader__dateLabel">Search title and abstract</span>
                        <input
                            type="search"
                            className="filterHeader__textSearchInput"
                            value={selectedFilters.textSearch}
                            onChange={ev => {
                                setSelectedFilters({ ...selectedFilters, textSearch: ev.target.value })
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="filterHeader__buttons col-md-12">
                <div className="col-md-2">
                    <Button
                        className="filterHeader__searchButton"
                        onClick={() => props.getFilteredRawNews(selectedFilters)}
                    >
                        <p>Search</p>
                    </Button>
                </div>

                <div className="filterHeader__resetButton col-md-2" onClick={resetFilters}>
                    <p>Reset Filters</p>
                </div>
                <div className="col-md-4" />
                {(props.onRawPage || props.publishedFilters) && (
                    <div className="col-md-4 filterHeader__viewButtons">
                        <SaveView data={selectedFilters} updateFilters={updateFilters} />
                    </div>
                )}
            </div>
        </div>
    )
}
