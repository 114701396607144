// import axios from 'axios'
// import html2pdf from 'html2pdf.js'
// import React, { useEffect, useState } from 'react'
// import { AiFillCloseCircle } from 'react-icons/ai'
// import { FaFileDownload } from 'react-icons/fa'
// import { FiMail } from 'react-icons/fi'
// import { toast } from 'react-toastify'
// import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
// import { getCookie } from '../../../utils'
// import ReactECharts from 'echarts-for-react'
// import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
// import ApartmentIcon from '@mui/icons-material/Apartment'
// import Sms from '@mui/icons-material/Sms'
// import { GoLaw } from 'react-icons/go'
// import { getFullFormattedDate } from '../../../utils/dateHelpers'
// import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'

// export default function DetailedReportPreview(props) {
//     const token = getCookie('csrftoken')
//     axios.defaults.headers.common['X-CSRFToken'] = token
//     const [showSpinner, setShowSpinner] = useState(false)
//     const [emailModal, setEmailModal] = useState(false)
//     const [reportView, setReportView] = useState(null)
//     const [isLoading, setLoader] = useState(true)

//     const { jsonData = null, detailedReportModal, setDetailedReportModal, jsonURLID = null } = props

//     useEffect(() => {
//         if (jsonURLID) {
//             axios
//                 .get(`/news/report-request/?id=${jsonURLID}`)
//                 .then(res => {
//                     const data = res.data

//                     setReportView(data)
//                 })
//                 .catch(err => toast('Failed to Fetch Report!, please try again Later.'))
//                 .finally(() => setLoader(false))
//         }
//     }, [jsonURLID !== null])

//     useEffect(() => {
//         if (jsonData) {
//             setReportView(jsonData)
//             setLoader(false)
//         }
//     }, [jsonData !== null])

//     const pdfDownLoad = () => {
//         const element = document.getElementById('quick_report_content')

//         // Configuration for html2pdf
//         const options = {
//             margin: 10,
//             filename: 'downloaded_pdf.pdf',
//             image: { type: 'jpeg', quality: 0.98 },
//             html2canvas: { useCORS: true, scale: 2 },
//             jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//         }

//         html2pdf()
//             .set(options)
//             .from(element)
//             .save()
//     }

//     const sendingMail = e => {
//         e.preventDefault()
//         setShowSpinner(true)
//         const element = document.getElementById('quick_report_content')

//         const htmlContent = element.innerHTML
//         const singleLineString = htmlContent.replace(/\s+/g, ' ').trim()

//         let formData = new FormData()
//         let blob = new Blob([singleLineString], { type: 'text/html' })
//         formData.append('htmlcontent', blob)
//         formData.append('email', e.target.email.value)

//         axios
//             .post('/endusers/custom-newsletters/', formData, {
//                 headers: { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' },
//             })
//             .then(res => {
//                 if (res.status === 200) {
//                     setEmailModal(false)
//                     setShowSpinner(false)
//                     toast('Email sent')
//                 }
//             })
//             .catch(err => {
//                 toast('Email sending failed')
//                 setShowSpinner(false)
//             })
//     }

//     const BarChart = ({ data, type }) => {
//         const extractData = data => {
//             const years = []
//             const revenues = []
//             const currencyTypes = []
//             let overallCurrency = ''

//             for (const key in data) {
//                 if (key === 'Currency') {
//                     overallCurrency = data[key]
//                 }
//                 if (key !== 'Currency' && data[key] !== 'N/A') {
//                     years.push(key)

//                     const amountStr = data[key].match(/[\d,.]+/)[0]
//                     const currencyStr = data[key].replace(amountStr, '').trim()

//                     // Parse the amount and handle commas
//                     const amount = parseFloat(amountStr.replace(/,/g, ''))

//                     revenues.push(amount)
//                     currencyTypes.push(currencyStr)
//                 }
//             }

//             return { years, revenues, currencyTypes, overallCurrency }
//         }

//         const { years, revenues, currencyTypes, overallCurrency } = extractData(data)

//         const option = {
//             title: {
//                 text: type === 'revenue' ? 'Revenue(2021-2023)' : 'Revenue by Geography (2023)',
//                 // subtext: 'Fiscal Years 2022-2023',
//                 left: 'center',
//             },
//             tooltip: {
//                 trigger: 'axis',
//                 axisPointer: {
//                     type: 'shadow',
//                 },
//             },
//             legend: {
//                 data: [`Revenue in ${overallCurrency} ${currencyTypes.length > 0 && currencyTypes[0]}`],
//                 top: 'bottom', // Move legend to the bottom
//                 itemWidth: 10, // Width of the legend item (icon)
//                 itemHeight: 10, // Height of the legend item (icon)
//                 textStyle: {
//                     fontSize: 12, // Font size of legend text
//                 },
//             },
//             grid: {
//                 left: '3%',
//                 right: '4%',
//                 bottom: '10%',
//                 containLabel: true,
//             },
//             xAxis: [
//                 {
//                     type: 'category',
//                     data: years,
//                 },
//             ],
//             yAxis: [
//                 {
//                     // type: 'value',
//                     // name: 'Amount (in billions)',
//                     axisLabel: {
//                         show: false, // Hide the axis labels
//                     },
//                     splitLine: {
//                         // Add splitLine configuration for grid lines
//                         show: true,
//                     },
//                 },
//             ],
//             series: [
//                 {
//                     name: `Revenue in ${overallCurrency} ${currencyTypes.length > 0 && currencyTypes[0]}`,
//                     type: 'bar',
//                     barWidth: 70,
//                     data: revenues,
//                     label: {
//                         show: true,
//                         position: 'top',
//                         // formatter: '{c} Bn', // Add units to the label
//                     },
//                 },
//             ],
//         }

//         return (
//             <div
//                 style={{
//                     border: '1px solid #ccc',
//                     width: '80%',
//                     margin: 'auto',
//                     borderRadius: '5px',
//                     padding: '10px',
//                     marginTop: '20px',
//                 }}
//             >
//                 <ReactECharts option={option} style={{ width: '100%', height: '300px', margin: 'auto' }} />
//             </div>
//         )
//     }

//     const cellStyle = {
//         border: '1px solid #4472C4',
//         padding: '8px',
//         // textAlign: 'center',
//         fontSize: '14px',
//     }

//     return (
//         <>
//             <Modal
//                 isOpen={detailedReportModal}
//                 toggle={() => {
//                     setDetailedReportModal(!detailedReportModal)
//                 }}
//                 size="xl"
//             >
//                 <div className="modal_header_black">
//                     <span style={{ color: 'white' }}>Detailed Report</span>

//                     <div>
//                         {reportView && reportView.data && (
//                             <>
//                                 <span onClick={pdfDownLoad} className="modal_close_btn" style={{ marginRight: '20px' }}>
//                                     <FaFileDownload />
//                                 </span>
//                                 <span
//                                     onClick={() => setEmailModal(!emailModal)}
//                                     className="modal_close_btn"
//                                     style={{ marginRight: '20px' }}
//                                 >
//                                     <FiMail />
//                                 </span>
//                             </>
//                         )}
//                         <span
//                             onClick={() => {
//                                 setDetailedReportModal(false)
//                             }}
//                             className="modal_close_btn"
//                         >
//                             <AiFillCloseCircle />
//                         </span>
//                     </div>
//                 </div>
//                 <ModalBody style={{ height: '100%' }}>
//                     {isLoading && <Spinner animation="border" role="status" />}
//                     {reportView !== null && (
//                         <div
//                             id="quick_report_content"
//                             className="quick_report_content"
//                             style={{ fontFamily: 'Muli', padding: '10px', textAlign: 'justify' }}
//                         >
//                             <div className="kaitongo_logo">
//                                 <img
//                                     crossOrigin="*"
//                                     src={reportView.kaitongo_logo}
//                                     style={{ width: '300px', maxHeight: '80px' }}
//                                     alt="kaitongo_logo"
//                                 />
//                             </div>

//                             <div className="legal_and_conversations" style={{ width: '100%', marginTop: '30px' }}>
//                                 {reportView.detailed_data && reportView.detailed_data['Executive Summary'] && (
//                                     <>
//                                         <div style={{ marginLeft: '10px', marginTop: '20px' }}>
//                                             <label
//                                                 style={{ fontSize: '16px', fontWeight: 800, color: 'rgb(18 56 124)' }}
//                                             >
//                                                 <AutoAwesomeOutlinedIcon style={{ fontSize: '30px' }} />
//                                                 <span style={{ marginLeft: '10px' }}>
//                                                     {' '}
//                                                     What’s Happening in {reportView.name}
//                                                 </span>
//                                             </label>
//                                             <pre
//                                                 style={{
//                                                     fontSize: '14px',
//                                                     textWrap: 'pretty',
//                                                     fontFamily: 'Muli',
//                                                     marginTop: '10px',
//                                                     margin: '5px',
//                                                 }}
//                                                 className="executive_summary"
//                                             >
//                                                 {Object.keys(reportView.detailed_data['Executive Summary']).map(key => {
//                                                     const theme = reportView.detailed_data['Executive Summary'][key]
//                                                     if (theme.Summary !== 'No relevant news.') {
//                                                         return (
//                                                             <div key={key} style={{ padding: '10px' }}>
//                                                                 <label>{theme['Theme Name']}</label>
//                                                                 <ul
//                                                                     style={{
//                                                                         margin: '0px',
//                                                                         fontSize: '14px',
//                                                                     }}
//                                                                 >
//                                                                     <li> {theme.Summary}</li>
//                                                                 </ul>
//                                                             </div>
//                                                         )
//                                                     }
//                                                     return null
//                                                 })}
//                                             </pre>
//                                         </div>
//                                     </>
//                                 )}
//                                 <div>
//                                     <div className="conversation_starters" style={{ marginTop: '20px' }}>
//                                         {reportView.detailed_data &&
//                                             reportView.detailed_data['Conversation Starters'] &&
//                                             reportView.detailed_data['Conversation Starters'][
//                                                 'Conversation Starters'
//                                             ] &&
//                                             reportView.detailed_data['Conversation Starters']['Conversation Starters']
//                                                 .length > 0 && (
//                                                 <div>
//                                                     <label
//                                                         style={{
//                                                             fontSize: '16px',
//                                                             color: 'rgb(18 56 124)',
//                                                             fontWeight: 800,
//                                                         }}
//                                                     >
//                                                         <Sms style={{ fontSize: '30px' }} /> Conversation Starters
//                                                     </label>
//                                                     <div
//                                                         style={{
//                                                             marginTop: '20px',
//                                                             border: '1px solid lightgray',
//                                                             pageBreakInside: 'avoid',
//                                                         }}
//                                                     >
//                                                         <div>
//                                                             {reportView.detailed_data['Conversation Starters'][
//                                                                 'Conversation Starters'
//                                                             ]
//                                                                 .slice(0, 5)
//                                                                 .map((item, index) => (
//                                                                     <div
//                                                                         key={index}
//                                                                         style={{
//                                                                             padding: '10px',
//                                                                             background: 'whitesmoke',
//                                                                             pageBreakInside: 'avoid',
//                                                                             // background: index % 2 == 0 ? '#D9E2F1' : '',
//                                                                         }}
//                                                                     >
//                                                                         {item.Name && <label>{item.Name}</label>}
//                                                                         {item['Summary'] && (
//                                                                             <ul
//                                                                                 style={{
//                                                                                     margin: '0px',
//                                                                                     fontSize: '14px',
//                                                                                 }}
//                                                                             >
//                                                                                 <li>{item['Summary']}</li>
//                                                                             </ul>
//                                                                         )}
//                                                                     </div>
//                                                                 ))}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                     </div>
//                                     <div className="legal_considerations" style={{ marginTop: '20px' }}>
//                                         {reportView.detailed_data &&
//                                             reportView.detailed_data['Legal Considerations'] &&
//                                             reportView.detailed_data['Legal Considerations']['Legal Considerations'] &&
//                                             reportView.detailed_data['Legal Considerations']['Legal Considerations']
//                                                 .length > 0 && (
//                                                 <div>
//                                                     <label
//                                                         style={{
//                                                             fontSize: '16px',
//                                                             color: 'rgb(18 56 124)',
//                                                             fontWeight: 800,
//                                                         }}
//                                                     >
//                                                         <GoLaw style={{ fontSize: '30px' }} /> Legal Considerations
//                                                     </label>
//                                                     <div>
//                                                         <div
//                                                             style={{
//                                                                 marginTop: '20px',
//                                                                 border: '1px solid lightgray',
//                                                                 pageBreakInside: 'avoid',
//                                                             }}
//                                                         >
//                                                             {reportView.detailed_data['Legal Considerations'][
//                                                                 'Legal Considerations'
//                                                             ]
//                                                                 .slice(0, 5)
//                                                                 .map((item, index) => (
//                                                                     <div
//                                                                         key={index}
//                                                                         style={{
//                                                                             padding: '10px',
//                                                                             background: 'whitesmoke',
//                                                                             pageBreakInside: 'avoid',
//                                                                             // background: index % 2 == 0 ? '#D9E2F1' : '',
//                                                                         }}
//                                                                     >
//                                                                         {item.Name && <label>{item.Name}</label>}
//                                                                         {item['Summary'] && (
//                                                                             <ul
//                                                                                 style={{
//                                                                                     margin: '0px',
//                                                                                     fontSize: '14px',
//                                                                                 }}
//                                                                             >
//                                                                                 <li>{item['Summary']}</li>
//                                                                             </ul>
//                                                                         )}
//                                                                     </div>
//                                                                 ))}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                     </div>
//                                 </div>
//                             </div>

//                             {reportView.overview && (
//                                 <div style={{ pageBreakInside: 'avoid' }}>
//                                     <div className="overview" style={{ marginTop: '30px' }}>
//                                         <label style={{ fontSize: '16px', color: 'rgb(18 56 124)', fontWeight: 800 }}>
//                                             {reportView.type && reportView.type} Overview
//                                         </label>
//                                         <div style={{ lineHeight: '25px', fontSize: '14px', marginTop: '10px' }}>
//                                             {reportView.overview}
//                                         </div>
//                                     </div>

//                                     {reportView.detailed_data && reportView.detailed_data['Founded Year'] && (
//                                         <div
//                                             style={{
//                                                 width: '90%',
//                                                 borderRadius: '20px',
//                                                 margin: '20px auto',
//                                                 display: 'flex',
//                                                 alignItems: 'center',
//                                                 justifyContent: 'space-between',
//                                                 padding: '10px',
//                                                 textAlign: 'center',
//                                                 border: '1px solid lightgray',
//                                                 pageBreakInside: 'avoid',
//                                             }}
//                                         >
//                                             {reportView.detailed_data['Founded Year']['Founded Year'] && (
//                                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <PowerSettingsNewIcon
//                                                         style={{ fontSize: '60px', color: 'rgb(18 56 124)' }}
//                                                     />
//                                                     <div style={{ marginLeft: '5px', fontSize: '14px' }}>
//                                                         <label> Year of Establishment</label>
//                                                         <div>
//                                                             {reportView.detailed_data['Founded Year']['Founded Year']}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                             {reportView.detailed_data['Founded Year']['Headquarters Location'] && (
//                                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <ApartmentIcon
//                                                         style={{ fontSize: '60px', color: 'rgb(18 56 124)' }}
//                                                     />
//                                                     <div style={{ marginLeft: '5px', fontSize: '14px' }}>
//                                                         <label> Headquarters</label>
//                                                         <div>
//                                                             {
//                                                                 reportView.detailed_data['Founded Year'][
//                                                                     'Headquarters Location'
//                                                                 ]
//                                                             }
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </div>
//                                     )}
//                                 </div>
//                             )}

//                             <>
//                                 {reportView.detailed_data &&
//                                     reportView.detailed_data['Business Segments'] &&
//                                     reportView.detailed_data['Business Segments']['Business Segments'] &&
//                                     reportView.detailed_data['Business Segments']['Business Segments'].length > 0 && (
//                                         <div className="business_segments" style={{ marginTop: '30px' }}>
//                                             <label
//                                                 style={{ fontSize: '16px', color: 'rgb(18 56 124)', fontWeight: 800 }}
//                                             >
//                                                 Key Business Segments
//                                             </label>
//                                             <div style={{ marginTop: '20px', border: '1px solid lightgray' }}>
//                                                 {reportView.detailed_data['Business Segments']['Business Segments'].map(
//                                                     res => (
//                                                         <div style={{ borderBottom: '1px solid lightgray' }}>
//                                                             <label
//                                                                 style={{
//                                                                     fontSize: '14px',
//                                                                     fontWeight: 800,
//                                                                     margin: '5px 0px 0px 10px',
//                                                                     textAlign: 'center',
//                                                                 }}
//                                                             >
//                                                                 {res['Business Segment']}
//                                                             </label>
//                                                             <pre
//                                                                 style={{
//                                                                     // lineHeight: '25px',
//                                                                     fontSize: '14px',
//                                                                     textWrap: 'pretty',
//                                                                     fontFamily: 'Muli',
//                                                                     marginTop: '10px',
//                                                                     margin: '5px',
//                                                                 }}
//                                                             >
//                                                                 {res['Sub Segments'].map(val => (
//                                                                     <ul style={{ margin: '0px', fontSize: '14px' }}>
//                                                                         <li>{val}</li>
//                                                                     </ul>
//                                                                 ))}
//                                                             </pre>
//                                                         </div>
//                                                     )
//                                                 )}
//                                             </div>
//                                         </div>
//                                     )}
//                                 {reportView.detailed_data &&
//                                     (reportView.detailed_data['Financial Analysis'] ||
//                                         reportView.detailed_data['Financial Summary']) && (
//                                         <div style={{ marginTop: '20px' }}>
//                                             <label
//                                                 style={{ fontSize: '16px', color: 'rgb(18 56 124)', fontWeight: 800 }}
//                                             >
//                                                 Financial Overview
//                                             </label>
//                                             {reportView.detailed_data['Financial Analysis'] &&
//                                                 reportView.detailed_data['Financial Analysis']['Total Revenue'] && (
//                                                     <div
//                                                         style={{
//                                                             marginTop: '20px',
//                                                             marginLeft: '10px',
//                                                             pageBreakInside: 'avoid',
//                                                         }}
//                                                     >
//                                                         <label
//                                                             style={{
//                                                                 fontSize: '15px',
//                                                                 color: 'rgb(18 56 124)',
//                                                                 fontWeight: 800,
//                                                             }}
//                                                         >
//                                                             Revenue Analysis
//                                                         </label>

//                                                         <BarChart
//                                                             data={
//                                                                 reportView.detailed_data['Financial Analysis'][
//                                                                     'Total Revenue'
//                                                                 ]
//                                                             }
//                                                             type="revenue"
//                                                         />
//                                                     </div>
//                                                 )}
//                                             {reportView.detailed_data['Financial Analysis'] &&
//                                                 reportView.detailed_data['Financial Analysis'][
//                                                     'Revenue by Geography'
//                                                 ] && (
//                                                     <div
//                                                         style={{
//                                                             marginTop: '20px',
//                                                             marginLeft: '10px',
//                                                             pageBreakInside: 'avoid',
//                                                         }}
//                                                     >
//                                                         <label
//                                                             style={{
//                                                                 fontSize: '15px',
//                                                                 color: 'rgb(18 56 124)',
//                                                                 fontWeight: 800,
//                                                             }}
//                                                         >
//                                                             Revenue by Geography
//                                                         </label>

//                                                         <BarChart
//                                                             data={
//                                                                 reportView.detailed_data['Financial Analysis'][
//                                                                     'Revenue by Geography'
//                                                                 ]
//                                                             }
//                                                             type="geograpy"
//                                                         />
//                                                     </div>
//                                                 )}
//                                             {reportView.detailed_data['Financial Summary'] && (
//                                                 <pre
//                                                     style={{
//                                                         lineHeight: '25px',
//                                                         fontSize: '14px',
//                                                         textWrap: 'pretty',
//                                                         fontFamily: 'Muli',
//                                                         marginTop: '10px',
//                                                         marginLeft: '10px',
//                                                     }}
//                                                 >
//                                                     {reportView.detailed_data['Financial Summary']}
//                                                 </pre>
//                                             )}
//                                         </div>
//                                     )}

//                                 {reportView.detailed_data &&
//                                     reportView.detailed_data['Strategic Priorities'] &&
//                                     reportView.detailed_data['Strategic Priorities']['Strategic Priorities'] &&
//                                     reportView.detailed_data['Strategic Priorities']['Strategic Priorities'].length >
//                                         0 && (
//                                         <>
//                                             <label
//                                                 style={{
//                                                     fontSize: '16px',
//                                                     marginTop: '20px',
//                                                     color: 'rgb(18 56 124)',
//                                                     fontWeight: 800,
//                                                 }}
//                                             >
//                                                 Strategic Priorities
//                                             </label>
//                                             <div style={{ border: '1px solid lightgray', marginTop: '10px' }}>
//                                                 <div
//                                                     style={{
//                                                         fontSize: '14px',
//                                                         color: 'white',
//                                                         background: '#4472C4',
//                                                         textAlign: 'center',
//                                                     }}
//                                                 >
//                                                     Focus Area
//                                                 </div>

//                                                 <div>
//                                                     {reportView.detailed_data['Strategic Priorities'][
//                                                         'Strategic Priorities'
//                                                     ].map((item, index) => (
//                                                         <div
//                                                             key={index}
//                                                             style={{
//                                                                 borderBottom: '1px solid lightgray',
//                                                                 padding: '10px',
//                                                                 background: index % 2 == 0 ? '#D9E2F1' : '',
//                                                             }}
//                                                         >
//                                                             {item.Name && <label>{item.Name}</label>}
//                                                             {item['Summary'] && item['Summary'].length > 0 && (
//                                                                 <ul style={{ margin: '0px', fontSize: '14px' }}>
//                                                                     {item['Summary'].map(sum => (
//                                                                         <li>{sum}</li>
//                                                                     ))}
//                                                                 </ul>
//                                                             )}
//                                                         </div>
//                                                     ))}
//                                                 </div>
//                                             </div>
//                                         </>
//                                     )}
//                                 {reportView.detailed_data &&
//                                     reportView.detailed_data['Risk Factors'] &&
//                                     reportView.detailed_data['Risk Factors']['Risk Factors'] &&
//                                     reportView.detailed_data['Risk Factors']['Risk Factors'].length > 0 && (
//                                         <div className="risk_factors" style={{ marginTop: '30px' }}>
//                                             <label
//                                                 style={{ fontSize: '16px', color: 'rgb(18 56 124)', fontWeight: 800 }}
//                                             >
//                                                 Risk Factors
//                                             </label>

//                                             <div style={{ border: '1px solid lightgray', marginTop: '10px' }}>
//                                                 <div
//                                                     style={{
//                                                         fontSize: '14px',
//                                                         color: 'white',
//                                                         background: '#4472C4',
//                                                         textAlign: 'center',
//                                                     }}
//                                                 >
//                                                     Risk Factors
//                                                 </div>
//                                                 <div>
//                                                     {reportView.detailed_data['Risk Factors']['Risk Factors'].map(
//                                                         (item, index) => (
//                                                             <div
//                                                                 key={index}
//                                                                 style={{
//                                                                     borderBottom: '1px solid lightgray',
//                                                                     padding: '10px',
//                                                                     background: index % 2 == 0 ? '#D9E2F1' : '',
//                                                                 }}
//                                                             >
//                                                                 {item.Name && <label>{item.Name}</label>}
//                                                                 {item['Summary'] && item['Summary'].length > 0 && (
//                                                                     <ul style={{ margin: '0px', fontSize: '14px' }}>
//                                                                         {item['Summary'].map(sum => (
//                                                                             <li>{sum}</li>
//                                                                         ))}
//                                                                     </ul>
//                                                                 )}
//                                                             </div>
//                                                         )
//                                                     )}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     )}
//                                 {reportView.detailed_data &&
//                                     reportView.detailed_data['Legal Proceedings'] &&
//                                     reportView.detailed_data['Legal Proceedings']['Legal Proceedings'] &&
//                                     reportView.detailed_data['Legal Proceedings']['Legal Proceedings'].length > 0 && (
//                                         <div className="legal_proceedings" style={{ marginTop: '30px' }}>
//                                             <label
//                                                 style={{ fontSize: '16px', color: 'rgb(18 56 124)', fontWeight: 800 }}
//                                             >
//                                                 Legal Proceedings
//                                             </label>
//                                             <div style={{ marginTop: '10px', border: '1px solid lightgray' }}>
//                                                 {reportView.detailed_data['Legal Proceedings']['Legal Proceedings'].map(
//                                                     (item, index) => (
//                                                         <div
//                                                             key={index}
//                                                             style={{
//                                                                 borderBottom: '1px solid lightgray',
//                                                                 padding: '10px',
//                                                                 background: index % 2 == 0 ? '#D9E2F1' : '',
//                                                             }}
//                                                         >
//                                                             {item.Name && <label>{item.Name}</label>}
//                                                             {item['Summary'] && (
//                                                                 <ul style={{ margin: '0px', fontSize: '14px' }}>
//                                                                     <li>{item.Summary}</li>
//                                                                 </ul>
//                                                             )}
//                                                         </div>
//                                                     )
//                                                 )}
//                                             </div>
//                                         </div>
//                                     )}

//                                 {reportView.data && reportView.data.length > 0 && (
//                                     <>
//                                         <label
//                                             style={{
//                                                 fontSize: '16px',
//                                                 fontWeight: 800,
//                                                 marginTop: '20px',
//                                                 color: 'rgb(18 56 124)',
//                                             }}
//                                         >
//                                             Recent Strategic Business Events{' '}
//                                         </label>
//                                         <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
//                                             <thead>
//                                                 <tr style={{ background: 'rgb(38, 116, 187)' }}>
//                                                     <th
//                                                         style={{
//                                                             ...cellStyle,
//                                                             textAlign: 'center',
//                                                             color: 'white',
//                                                         }}
//                                                     >
//                                                         Date
//                                                     </th>
//                                                     <th
//                                                         style={{
//                                                             ...cellStyle,
//                                                             textAlign: 'center',
//                                                             color: 'white',
//                                                         }}
//                                                     >
//                                                         Title
//                                                     </th>
//                                                     <th
//                                                         style={{
//                                                             ...cellStyle,
//                                                             textAlign: 'center',
//                                                             color: 'white',
//                                                         }}
//                                                     >
//                                                         Summary
//                                                     </th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {reportView.data.map((value, index) => (
//                                                     <tr
//                                                         key={value.date}
//                                                         style={{
//                                                             background: index % 2 == 0 ? '#D9E2F1' : '',
//                                                             pageBreakInside: 'avoid',
//                                                         }}
//                                                     >
//                                                         <td
//                                                             style={{
//                                                                 ...cellStyle,
//                                                                 width: '20%',
//                                                                 textAlign: 'center',
//                                                             }}
//                                                         >
//                                                             {value.date}
//                                                         </td>
//                                                         <td
//                                                             style={{
//                                                                 ...cellStyle,
//                                                                 width: '30%',
//                                                             }}
//                                                         >
//                                                             {value.title && (
//                                                                 <a
//                                                                     href={value.source ? value.source : ''}
//                                                                     style={{
//                                                                         color: '#3048A6',
//                                                                         fontSize: '14px',
//                                                                     }}
//                                                                 >
//                                                                     {value.title}
//                                                                 </a>
//                                                             )}
//                                                         </td>
//                                                         <td style={cellStyle}>
//                                                             {value.snippet && (
//                                                                 <div
//                                                                     style={{
//                                                                         fontSize: '14px',
//                                                                     }}
//                                                                     title={value.snippet}
//                                                                 >
//                                                                     {value.snippet}
//                                                                 </div>
//                                                             )}
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </>
//                                 )}
//                                 {reportView.detailed_data &&
//                                     reportView.detailed_data['Litigable Sector Issues'] &&
//                                     reportView.detailed_data['Litigable Sector Issues']['Challenges'] &&
//                                     reportView.detailed_data['Litigable Sector Issues']['Challenges'].length > 0 && (
//                                         <div className="legal_proceedings" style={{ marginTop: '30px' }}>
//                                             <label
//                                                 style={{ fontSize: '16px', color: 'rgb(18 56 124)', fontWeight: 800 }}
//                                             >
//                                                 Litigable issues
//                                                 {reportView.detailed_data['Sector name'] &&
//                                                     ' in ' +
//                                                         reportView.detailed_data['Sector name'] +
//                                                         ' Sector : ' +
//                                                         reportView.name}
//                                             </label>

//                                             <div style={{ marginTop: '10px', border: '1px solid lightgray' }}>
//                                                 {reportView.detailed_data['Litigable Sector Issues']['Challenges'].map(
//                                                     (item, index) => (
//                                                         <div
//                                                             key={index}
//                                                             style={{
//                                                                 borderBottom: '1px solid lightgray',
//                                                                 padding: '10px',
//                                                                 background: index % 2 == 0 ? '#D9E2F1' : '',
//                                                             }}
//                                                         >
//                                                             {item.Challenge && <label>{item.Challenge}</label>}
//                                                             {item['Litigation Issues and Risks'] &&
//                                                                 item['Litigation Issues and Risks'].length > 0 && (
//                                                                     <ul style={{ margin: '0px', fontSize: '14px' }}>
//                                                                         {item['Litigation Issues and Risks'].map(
//                                                                             (risk, riskIndex) => (
//                                                                                 <li>{risk['Issue and Risk']}</li>
//                                                                             )
//                                                                         )}
//                                                                     </ul>
//                                                                 )}
//                                                         </div>
//                                                     )
//                                                 )}
//                                             </div>
//                                         </div>
//                                     )}

//                                 {reportView.detailed_data &&
//                                     reportView.detailed_data['Sector Update'] &&
//                                     reportView.detailed_data['Sector Update'].length > 0 && (
//                                         <>
//                                             <label
//                                                 style={{
//                                                     fontSize: '16px',
//                                                     fontWeight: 800,
//                                                     marginTop: '20px',
//                                                     color: 'rgb(18 56 124)',
//                                                 }}
//                                             >
//                                                 Sector Update
//                                             </label>
//                                             <table
//                                                 style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}
//                                             >
//                                                 <thead>
//                                                     <tr style={{ background: 'rgb(38, 116, 187)' }}>
//                                                         <th
//                                                             style={{
//                                                                 ...cellStyle,
//                                                                 textAlign: 'center',
//                                                                 color: 'white',
//                                                             }}
//                                                         >
//                                                             Date
//                                                         </th>
//                                                         <th
//                                                             style={{
//                                                                 ...cellStyle,
//                                                                 textAlign: 'center',
//                                                                 color: 'white',
//                                                             }}
//                                                         >
//                                                             Title
//                                                         </th>
//                                                         <th
//                                                             style={{
//                                                                 ...cellStyle,
//                                                                 textAlign: 'center',
//                                                                 color: 'white',
//                                                             }}
//                                                         >
//                                                             Summary
//                                                         </th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {reportView.detailed_data['Sector Update'].map((value, index) => (
//                                                         <tr
//                                                             key={value.date}
//                                                             style={{
//                                                                 background: index % 2 == 0 ? '#D9E2F1' : '',
//                                                                 pageBreakInside: 'avoid',
//                                                             }}
//                                                         >
//                                                             <td
//                                                                 style={{
//                                                                     ...cellStyle,
//                                                                     width: '20%',
//                                                                     textAlign: 'center',
//                                                                 }}
//                                                             >
//                                                                 {getFullFormattedDate(value.date)}
//                                                             </td>
//                                                             <td
//                                                                 style={{
//                                                                     ...cellStyle,
//                                                                     width: '30%',
//                                                                 }}
//                                                             >
//                                                                 {value.title && (
//                                                                     <a
//                                                                         href={value.source ? value.source : ''}
//                                                                         style={{
//                                                                             color: '#3048A6',
//                                                                             fontSize: '14px',
//                                                                         }}
//                                                                     >
//                                                                         {value.title}
//                                                                     </a>
//                                                                 )}
//                                                             </td>
//                                                             <td style={cellStyle}>
//                                                                 {value.snippet && (
//                                                                     <div
//                                                                         style={{
//                                                                             fontSize: '14px',
//                                                                         }}
//                                                                         title={value.snippet}
//                                                                     >
//                                                                         {value.snippet}
//                                                                     </div>
//                                                                 )}
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </>
//                                     )}
//                             </>

//                             <div
//                                 className="footer"
//                                 style={{ marginTop: '30px', fontSize: '14px', fontWeight: 400, textAlign: 'center' }}
//                             >
//                                 Copyright © 2024 Kaitongo
//                             </div>
//                         </div>
//                     )}
//                 </ModalBody>
//             </Modal>

//             <Modal isOpen={emailModal} toggle={() => setEmailModal(!emailModal)}>
//                 <ModalHeader>
//                     <strong>Email</strong>
//                 </ModalHeader>

//                 <ModalBody>
//                     <Form onSubmit={e => sendingMail(e)}>
//                         <Label>Email-ID</Label>
//                         <Input type="email" name="email" />
//                         <Button
//                             type="submit"
//                             variant="primary"
//                             disabled={showSpinner ? true : false}
//                             style={{ marginTop: '10px', float: 'right', textAlign: 'center' }}
//                         >
//                             {showSpinner && (
//                                 <Spinner
//                                     as="span"
//                                     animation="border"
//                                     size="sm"
//                                     role="status"
//                                     aria-hidden="true"
//                                     style={{ margin: '1px 5px 0px' }}
//                                 />
//                             )}
//                             Send Email
//                         </Button>
//                     </Form>
//                 </ModalBody>
//             </Modal>
//         </>
//     )
// }
import axios from 'axios'
import html2pdf from 'html2pdf.js'
import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaFileDownload } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { getCookie } from '../../../utils'
import ReactECharts from 'echarts-for-react'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import ApartmentIcon from '@mui/icons-material/Apartment'
import Sms from '@mui/icons-material/Sms'
import { GoLaw } from 'react-icons/go'
import { getFullFormattedDate } from '../../../utils/dateHelpers'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'

export default function DetailedReportPreview(props) {
    const token = getCookie('csrftoken')
    axios.defaults.headers.common['X-CSRFToken'] = token
    const [showSpinner, setShowSpinner] = useState(false)
    const [emailModal, setEmailModal] = useState(false)
    const [reportView, setReportView] = useState(null)
    const [isLoading, setLoader] = useState(true)

    const { jsonData = null, detailedReportModal, setDetailedReportModal, jsonURLID = null } = props

    useEffect(() => {
        if (jsonURLID) {
            axios
                .get(`/news/report-request/?id=${jsonURLID}`)
                .then(res => {
                    const data = res.data

                    setReportView(data)
                })
                .catch(err => toast('Failed to Fetch Report!, please try again Later.'))
                .finally(() => setLoader(false))
        }
    }, [jsonURLID !== null])

    useEffect(() => {
        if (jsonData) {
            setReportView(jsonData)
            setLoader(false)
        }
    }, [jsonData !== null])

    const pdfDownLoad = () => {
        const element = document.getElementById('detail_report_content')

        // Configuration for html2pdf
        const options = {
            margin: 10,
            filename: 'downloaded_pdf.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { useCORS: true, scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }

        html2pdf()
            .set(options)
            .from(element)
            .save()
    }

    const sendingMail = e => {
        e.preventDefault()
        setShowSpinner(true)
        const element = document.getElementById('quick_report_content')

        const htmlContent = element.innerHTML
        const singleLineString = htmlContent.replace(/\s+/g, ' ').trim()

        let formData = new FormData()
        let blob = new Blob([singleLineString], { type: 'text/html' })
        formData.append('htmlcontent', blob)
        formData.append('email', e.target.email.value)

        axios
            .post('/endusers/custom-newsletters/', formData, {
                headers: { 'X-CSRFToken': token, 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                if (res.status === 200) {
                    setEmailModal(false)
                    setShowSpinner(false)
                    toast('Email sent')
                }
            })
            .catch(err => {
                toast('Email sending failed')
                setShowSpinner(false)
            })
    }

    const BarChart = ({ data, type }) => {
        const extractData = data => {
            const years = []
            const revenues = []
            const currencyTypes = []
            let overallCurrency = ''

            for (const key in data) {
                if (key === 'Currency') {
                    overallCurrency = String(data[key])
                }
                if (
                    key !== 'Currency' &&
                    (String(data[key]).toLowerCase() !== 'not available' || String(data[key]).toLowerCase() !== 'n/a')
                ) {
                    years.push(key)

                    const matchResult = String(data[key]).match(/[\d,.]+/)
                    if (matchResult) {
                        const amountStr = matchResult[0]
                        const currencyStr = String(data[key])
                            .replace(amountStr, '')
                            .trim()

                        // Parse the amount and handle commas
                        const amount = parseFloat(amountStr.replace(/,/g, ''))

                        revenues.push(amount)
                        currencyTypes.push(currencyStr)
                    }
                }
            }

            return { years, revenues, currencyTypes, overallCurrency }
        }

        const { years, revenues, currencyTypes, overallCurrency } = extractData(data)

        const option = {
            title: {
                text: type === 'revenue' ? 'Revenue (2021 - 2023)' : 'Revenue by Geography (2023)',
                // subtext: 'Fiscal Years 2022-2023',
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {
                data: [`Revenue in ${overallCurrency} ${currencyTypes.length > 0 && currencyTypes[0]}`],
                top: 'bottom', // Move legend to the bottom
                itemWidth: 10, // Width of the legend item (icon)
                itemHeight: 10, // Height of the legend item (icon)
                textStyle: {
                    fontSize: 12, // Font size of legend text
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '10%',
                containLabel: true,
            },
            xAxis: [
                {
                    type: 'category',
                    data: years,
                    axisLabel: {
                        formatter: function(value) {
                            return value.length > 10 ? value.substring(0, 10) + '...' : value
                        },
                    },
                },
            ],
            yAxis: [
                {
                    // type: 'value',
                    // name: 'Amount (in billions)',
                    axisLabel: {
                        show: false, // Hide the axis labels
                    },
                    splitLine: {
                        // Add splitLine configuration for grid lines
                        show: true,
                    },
                },
            ],
            series: [
                {
                    name: `Revenue in ${overallCurrency} ${currencyTypes.length > 0 && currencyTypes[0]}`,
                    type: 'bar',
                    barWidth: 70,
                    data: revenues,
                    label: {
                        show: true,
                        position: 'top',
                        // formatter: '{c} Bn', // Add units to the label
                    },
                },
            ],
        }

        return (
            <>
                <label
                    className="report-f18"
                    style={{
                        color: '#186ADE',
                        fontWeight: 500,
                        fontFamily: 'Montserrat',
                        display:
                            (overallCurrency === 'Not Available' || overallCurrency === 'N/A') &&
                            currencyTypes.length === 0
                                ? 'none'
                                : 'block',
                    }}
                >
                    {type === 'revenue' ? 'REVENUE ANALYSIS' : 'REVENUE BY GEOGRAPHY'}
                </label>
                <div
                    style={{
                        border: '1px solid #ccc',
                        width: '80%',
                        margin: 'auto',
                        borderRadius: '5px',
                        padding: '10px',
                        marginTop: '20px',
                        display:
                            (overallCurrency === 'Not Available' || overallCurrency === 'N/A') &&
                            currencyTypes.length === 0
                                ? 'none'
                                : 'block',
                        pageBreakInside: 'avoid',
                    }}
                >
                    <ReactECharts
                        option={option}
                        style={{ width: '100%', height: '300px', margin: 'auto', pageBreakInside: 'avoid' }}
                        className="report-f16"
                    />
                </div>
            </>
        )
    }

    const cellStyle = {
        border: '1px solid #4472C4',
        padding: '8px',
        // textAlign: 'center',
        fontSize: '14px',
    }

    return (
        <>
            <Modal
                isOpen={detailedReportModal}
                toggle={() => {
                    setDetailedReportModal(!detailedReportModal)
                }}
                size="lg"
            >
                <div className="modal_header_black">
                    <span style={{ color: 'white' }}>Detailed Report</span>

                    <div>
                        {reportView && reportView.data && (
                            <>
                                <span onClick={pdfDownLoad} className="modal_close_btn" style={{ marginRight: '20px' }}>
                                    <FaFileDownload />
                                </span>
                                <span
                                    onClick={() => setEmailModal(!emailModal)}
                                    className="modal_close_btn"
                                    style={{ marginRight: '20px' }}
                                >
                                    <FiMail />
                                </span>
                            </>
                        )}
                        <span
                            onClick={() => {
                                setDetailedReportModal(false)
                            }}
                            className="modal_close_btn"
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>
                </div>
                <ModalBody style={{ height: '100%' }}>
                    {isLoading && <Spinner animation="border" role="status" />}
                    {reportView !== null && (
                        <div id="detail_report_content">
                            <div className="A4">
                                <div className="kaitongo_logo" style={{ display: 'flex', justifyContent: 'end' }}>
                                    <img
                                        src={reportView.kaitongo_logo}
                                        style={{ width: '300px', maxHeight: '80px' }}
                                        alt="kaitongo_logo"
                                    />
                                </div>
                                <div className="report-t150" style={{ marginLeft: '50px' }}>
                                    <div className="report-f50" style={{ fontWeight: 800 }}>
                                        DETAILED
                                    </div>
                                    <div className="report-f50" style={{ fontWeight: 800 }}>
                                        COMPANY REPORT
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '100px',
                                        marginLeft: '50px',
                                    }}
                                >
                                    <div>
                                        {reportView.logo && (
                                            <img
                                                src={reportView.logo}
                                                style={{ width: '100px', maxHeight: '100px' }}
                                                alt="report_logo"
                                            />
                                        )}
                                    </div>
                                    <div className="report-f40"> {reportView.name}</div>{' '}
                                </div>
                                {/* {reportView.detailed_data && reportView.detailed_data['Sector name'] && (
                                    <>
                                        <div style={{ marginLeft: '50px' }}>Prepared for:</div>

                                        <div style={{ marginLeft: '50px' }}>
                                            {reportView.detailed_data['Sector name']}
                                        </div>
                                    </>
                                )} */}

                                <div className="curved-circle" />
                            </div>
                            <div className="A4">
                                <div
                                    style={{
                                        paddingTop: '50px',
                                        marginLeft: '50px',
                                        marginTop: '10px',
                                        pageBreakBefore: 'always',
                                    }}
                                >
                                    Table of Contents
                                </div>
                                <div style={{ marginTop: '200px', display: 'flex', alignItems: 'center' }}>
                                    <div
                                        className="report-f100"
                                        style={{
                                            transform: 'rotate(270deg)',
                                            marginLeft: '-100px',
                                            color: '#186ADE',
                                        }}
                                    >
                                        CONTENTS
                                    </div>
                                    <div className="report-f14">
                                        {reportView.detailed_data && reportView.detailed_data['Executive Summary'] && (
                                            <div style={{ textTransform: 'uppercase' }}>Executive Summary</div>
                                        )}
                                        {reportView.detailed_data &&
                                            reportView.detailed_data['Conversation Starters'] &&
                                            reportView.detailed_data['Conversation Starters'][
                                                'Conversation Starters'
                                            ] &&
                                            reportView.detailed_data['Conversation Starters']['Conversation Starters']
                                                .length > 0 && <div> CONVERSATION STARTERS</div>}
                                        {reportView.detailed_data &&
                                            reportView.detailed_data['Legal Considerations'] &&
                                            reportView.detailed_data['Legal Considerations']['Legal Considerations'] &&
                                            reportView.detailed_data['Legal Considerations']['Legal Considerations']
                                                .length > 0 && <div>LEGAL CONSIDERATIONS</div>}
                                        {reportView.overview && (
                                            <div style={{ textTransform: 'uppercase' }}>
                                                {reportView.type && reportView.type} Overview
                                            </div>
                                        )}
                                        {/* {reportView.detailed_data &&
                                            reportView.detailed_data['Business Segments'] &&
                                            reportView.detailed_data['Business Segments']['Business Segments'] &&
                                            reportView.detailed_data['Business Segments']['Business Segments'].length >
                                                0 && <div>SEGMENT FOCUS</div>} */}
                                        {reportView.detailed_data &&
                                            (reportView.detailed_data['Financial Analysis'] ||
                                                reportView.detailed_data['Financial Summary']) && (
                                                <div>FINANCIAL OVERVIEW</div>
                                            )}
                                        {reportView.detailed_data &&
                                            reportView.detailed_data['Strategic Priorities'] &&
                                            reportView.detailed_data['Strategic Priorities']['Strategic Priorities'] &&
                                            reportView.detailed_data['Strategic Priorities']['Strategic Priorities']
                                                .length > 0 && <div>STRATEGIC PRIORITIES</div>}
                                        {reportView.detailed_data &&
                                            reportView.detailed_data['Risk Factors'] &&
                                            reportView.detailed_data['Risk Factors']['Risk Factors'] &&
                                            reportView.detailed_data['Risk Factors']['Risk Factors'].length > 0 && (
                                                <div>RISK FACTORS</div>
                                            )}
                                        {reportView.detailed_data &&
                                            reportView.detailed_data['Legal Proceedings'] &&
                                            reportView.detailed_data['Legal Proceedings']['Legal Proceedings'] &&
                                            reportView.detailed_data['Legal Proceedings']['Legal Proceedings'].length >
                                                0 && <div>LEGAL PROCEEDINGS</div>}
                                        {reportView.data && reportView.data.length > 0 && <div>KEY DEVELOPMENTS</div>}
                                        {reportView.detailed_data &&
                                            reportView.detailed_data['Litigable Sector Issues'] &&
                                            reportView.detailed_data['Litigable Sector Issues']['Challenges'] &&
                                            reportView.detailed_data['Litigable Sector Issues']['Challenges'].length >
                                                0 && <div>LITIGABLE ISSUES</div>}
                                        {reportView.detailed_data &&
                                            reportView.detailed_data['Sector Update'] &&
                                            reportView.detailed_data['Sector Update'].length > 0 && (
                                                <div>SECTOR UPDATE</div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Arial',
                                    padding: '20px',
                                    textAlign: 'justify',
                                    boxShadow: '0 0 1.2px gray',
                                    marginTop: '10px',
                                    pageBreakBefore: 'always',
                                }}
                            >
                                <div className="legal_and_conversations" style={{ width: '100%', marginTop: '30px' }}>
                                    {reportView.detailed_data && reportView.detailed_data['Executive Summary'] && (
                                        <>
                                            <div style={{ marginLeft: '10px', marginTop: '20px' }}>
                                                <label
                                                    className="report-f22"
                                                    style={{
                                                        color: '#186ADE',
                                                        fontWeight: 500,
                                                        fontFamily: 'Montserrat',
                                                    }}
                                                >
                                                    {/* <AutoAwesomeOutlinedIcon style={{ fontSize: '30px' }} /> */}
                                                    <span style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
                                                        Executive Summary
                                                    </span>
                                                </label>
                                                <div
                                                    style={{
                                                        marginTop: '10px',
                                                        margin: '5px',
                                                    }}
                                                    className="executive_summary report-f14"
                                                >
                                                    {/* {reportView.detailed_data['Executive Summary']} */}
                                                    {Object.keys(reportView.detailed_data['Executive Summary']).map(
                                                        key => {
                                                            const theme =
                                                                reportView.detailed_data['Executive Summary'][key]
                                                            if (theme.Summary !== 'No relevant news.') {
                                                                return (
                                                                    <div
                                                                        key={key}
                                                                        style={{
                                                                            padding: '10px',
                                                                            background: '#186ADE',
                                                                            color: 'white',
                                                                            marginTop: '10px',
                                                                        }}
                                                                    >
                                                                        <label
                                                                            className="report-f14"
                                                                            style={{ color: 'white' }}
                                                                        >
                                                                            {theme['Theme Name']}
                                                                        </label>
                                                                        <ul
                                                                            style={{
                                                                                margin: '0px',
                                                                            }}
                                                                            className="report-f14"
                                                                        >
                                                                            <li> {theme.Summary}</li>
                                                                        </ul>
                                                                    </div>
                                                                )
                                                            }
                                                            return null
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div>
                                        <div className="conversation_starters" style={{ marginTop: '20px' }}>
                                            {reportView.detailed_data &&
                                                reportView.detailed_data['Conversation Starters'] &&
                                                reportView.detailed_data['Conversation Starters'][
                                                    'Conversation Starters'
                                                ] &&
                                                reportView.detailed_data['Conversation Starters'][
                                                    'Conversation Starters'
                                                ].length > 0 && (
                                                    <div>
                                                        <label
                                                            className="report-f22"
                                                            style={{
                                                                color: '#186ADE',
                                                                fontWeight: 500,
                                                                fontFamily: 'Montserrat',
                                                                pageBreakInside: 'avoid',
                                                            }}
                                                        >
                                                            {/* <Sms style={{ fontSize: '30px' }} />  */}
                                                            CONVERSATION STARTERS
                                                        </label>
                                                        <div
                                                            style={{
                                                                marginTop: '20px',
                                                                // border: '1px solid lightgray',
                                                            }}
                                                        >
                                                            <div>
                                                                {reportView.detailed_data['Conversation Starters'][
                                                                    'Conversation Starters'
                                                                ]
                                                                    .slice(0, 5)
                                                                    .map((item, index) => (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                padding: '10px',
                                                                                // background: 'whitesmoke',
                                                                                pageBreakInside: 'avoid',
                                                                                // background: index % 2 == 0 ? '#D9E2F1' : '',
                                                                                marginTop: '10px',
                                                                            }}
                                                                        >
                                                                            {item.Name && (
                                                                                <label
                                                                                    className="report-f14"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        padding: '5px',
                                                                                        background: '#186ADE',
                                                                                        marginTop: '-15px',
                                                                                        color: 'white',
                                                                                    }}
                                                                                >
                                                                                    {item.Name}
                                                                                </label>
                                                                            )}
                                                                            {item['Summary'] && (
                                                                                <div
                                                                                    className="report-f14"
                                                                                    style={{
                                                                                        margin: '0px',
                                                                                        marginLeft: '15px',
                                                                                        padding: '20px 10px 10px 10px',
                                                                                        border: '2px solid #186ADE',
                                                                                        color: '#186ADE',
                                                                                    }}
                                                                                >
                                                                                    <div>{item['Summary']}</div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="legal_considerations" style={{ marginTop: '20px' }}>
                                            {reportView.detailed_data &&
                                                reportView.detailed_data['Legal Considerations'] &&
                                                reportView.detailed_data['Legal Considerations'][
                                                    'Legal Considerations'
                                                ] &&
                                                reportView.detailed_data['Legal Considerations']['Legal Considerations']
                                                    .length > 0 && (
                                                    <div>
                                                        <label
                                                            className="report-f22"
                                                            style={{
                                                                color: '#186ADE',
                                                                fontWeight: 500,
                                                                fontFamily: 'Montserrat',
                                                                pageBreakInside: 'avoid',
                                                            }}
                                                        >
                                                            {/* <GoLaw style={{ fontSize: '30px' }} /> */}
                                                            LEGAL CONSIDERATIONS
                                                        </label>
                                                        <div>
                                                            <div
                                                                style={{
                                                                    marginTop: '20px',
                                                                    // border: '1px solid lightgray',
                                                                }}
                                                            >
                                                                {reportView.detailed_data['Legal Considerations'][
                                                                    'Legal Considerations'
                                                                ]
                                                                    .slice(0, 5)
                                                                    .map((item, index) => (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                padding: '10px',
                                                                                background: '#186ADE',
                                                                                pageBreakInside: 'avoid',
                                                                                color: 'white',
                                                                                // background: index % 2 == 0 ? '#D9E2F1' : '',
                                                                                marginTop: '10px',
                                                                            }}
                                                                        >
                                                                            {item.Name && (
                                                                                <label
                                                                                    style={{
                                                                                        color: 'white',
                                                                                    }}
                                                                                    className="report-f14"
                                                                                >
                                                                                    {item.Name}
                                                                                </label>
                                                                            )}
                                                                            {item['Summary'] && (
                                                                                <div
                                                                                    style={{
                                                                                        margin: '0px',
                                                                                    }}
                                                                                    className="report-f14"
                                                                                >
                                                                                    <div>{item['Summary']}</div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                {reportView.overview && (
                                    <div style={{ pageBreakInside: 'avoid' }}>
                                        <div className="overview" style={{ marginTop: '30px' }}>
                                            <label
                                                className="report-f22"
                                                style={{
                                                    color: '#186ADE',
                                                    fontWeight: 500,
                                                    fontFamily: 'Montserrat',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {reportView.type && reportView.type} Overview
                                            </label>
                                            <div
                                                className="report-f14"
                                                style={{
                                                    marginTop: '10px',
                                                    marginLeft: '20px',
                                                }}
                                            >
                                                {reportView.overview}
                                            </div>
                                        </div>

                                        {reportView.detailed_data && reportView.detailed_data['Founded Year'] && (
                                            <div
                                                style={{
                                                    width: '90%',
                                                    borderRadius: '2px',
                                                    margin: '20px auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '10px',
                                                    textAlign: 'center',
                                                    border: '1px solid lightgray',
                                                    background: '#D9E2F1',
                                                    boxShadow: '5px 5px 5px 0px #186ADE',
                                                    pageBreakInside: 'avoid',
                                                }}
                                            >
                                                {reportView.detailed_data['Founded Year']['Founded Year'] && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <PowerSettingsNewIcon
                                                            style={{ fontSize: '60px', color: '#186ADE' }}
                                                        />
                                                        <div className="report-f14" style={{ marginLeft: '5px' }}>
                                                            <label style={{}}> Year of Establishment</label>
                                                            <div style={{ fontWeight: 500 }}>
                                                                {
                                                                    reportView.detailed_data['Founded Year'][
                                                                        'Founded Year'
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {reportView.detailed_data['Founded Year']['Headquarters Location'] && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ApartmentIcon style={{ fontSize: '60px', color: '#186ADE' }} />
                                                        <div className="report-f14" style={{ marginLeft: '5px' }}>
                                                            <label> Headquarters</label>
                                                            <div style={{ fontWeight: 500 }}>
                                                                {
                                                                    reportView.detailed_data['Founded Year'][
                                                                        'Headquarters Location'
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}

                                <>
                                    {/* {reportView.detailed_data &&
                                        reportView.detailed_data['Business Segments'] &&
                                        reportView.detailed_data['Business Segments']['Business Segments'] &&
                                        reportView.detailed_data['Business Segments']['Business Segments'].length >
                                            0 && (
                                            <div className="business_segments" style={{ marginTop: '30px' }}>
                                                <label
                                                    className="report-f22"
                                                    style={{
                                                        color: '#186ADE',
                                                        fontWeight: 500,
                                                        fontFamily: 'Montserrat',
                                                        pageBreakInside: 'avoid',
                                                    }}
                                                >
                                                    SEGMENT FOCUS
                                                </label>
                                                <div style={{ marginTop: '20px' }}>
                                                    {reportView.detailed_data['Business Segments'][
                                                        'Business Segments'
                                                    ].map((res, index) => (
                                                        <div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span
                                                                    className="report-f50"
                                                                    style={{ color: '#186ADE' }}
                                                                >
                                                                    {index + 1}
                                                                </span>
                                                                <span
                                                                    className="report-f18"
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        marginLeft: '10px',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    {res['Business Segment']}
                                                                </span>{' '}
                                                            </div>
                                                            <div
                                                                className="report-f14"
                                                                style={{
                                                                    textWrap: 'pretty',
                                                                    fontFamily: 'Arial',
                                                                    marginTop: '-20px',
                                                                    marginLeft: '40px',
                                                                }}
                                                            >
                                                                {res['Sub Segments'].map(val => (
                                                                    <div
                                                                        className="report-f14"
                                                                        style={{ margin: '0px' }}
                                                                    >
                                                                        <div>{val}</div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )} */}
                                    {reportView.detailed_data &&
                                        (reportView.detailed_data['Financial Analysis'] ||
                                            reportView.detailed_data['Financial Summary']) && (
                                            <div style={{ marginTop: '20px' }}>
                                                <label
                                                    className="report-f22"
                                                    style={{
                                                        color: '#186ADE',
                                                        fontWeight: 500,
                                                        fontFamily: 'Montserrat',
                                                        pageBreakInside: 'avoid',
                                                    }}
                                                >
                                                    FINANCIAL OVERVIEW
                                                </label>
                                                {reportView.detailed_data['Financial Analysis'] &&
                                                    reportView.detailed_data['Financial Analysis']['Total Revenue'] &&
                                                    typeof reportView.detailed_data['Financial Analysis'][
                                                        'Total Revenue'
                                                    ] === 'object' && (
                                                        <div
                                                            style={{
                                                                marginTop: '20px',
                                                                marginLeft: '10px',
                                                                pageBreakInside: 'avoid',
                                                            }}
                                                        >
                                                            <BarChart
                                                                data={
                                                                    reportView.detailed_data['Financial Analysis'][
                                                                        'Total Revenue'
                                                                    ]
                                                                }
                                                                type="revenue"
                                                            />
                                                        </div>
                                                    )}
                                                {reportView.detailed_data['Financial Analysis'] &&
                                                    reportView.detailed_data['Financial Analysis'][
                                                        'Revenue by Geography'
                                                    ] &&
                                                    typeof reportView.detailed_data['Financial Analysis'][
                                                        'Revenue by Geography'
                                                    ] === 'object' && (
                                                        <div
                                                            style={{
                                                                marginTop: '20px',
                                                                marginLeft: '10px',
                                                                pageBreakInside: 'avoid',
                                                            }}
                                                        >
                                                            <BarChart
                                                                data={
                                                                    reportView.detailed_data['Financial Analysis'][
                                                                        'Revenue by Geography'
                                                                    ]
                                                                }
                                                                type="geograpy"
                                                            />
                                                        </div>
                                                    )}
                                                {reportView.detailed_data['Financial Summary'] && (
                                                    <pre
                                                        className="report-f14"
                                                        style={{
                                                            textWrap: 'pretty',
                                                            fontFamily: 'Muli',
                                                            marginTop: '10px',
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        {reportView.detailed_data['Financial Summary']}
                                                    </pre>
                                                )}
                                            </div>
                                        )}

                                    {reportView.detailed_data &&
                                        reportView.detailed_data['Strategic Priorities'] &&
                                        reportView.detailed_data['Strategic Priorities']['Strategic Priorities'] &&
                                        reportView.detailed_data['Strategic Priorities']['Strategic Priorities']
                                            .length > 0 && (
                                            <>
                                                <label
                                                    className="report-f22"
                                                    style={{
                                                        color: '#186ADE',
                                                        fontWeight: 500,
                                                        fontFamily: 'Montserrat',
                                                        pageBreakInside: 'avoid',
                                                    }}
                                                >
                                                    STRATEGIC PRIORITIES
                                                </label>
                                                <div style={{ marginTop: '10px' }}>
                                                    {reportView.detailed_data['Strategic Priorities'][
                                                        'Strategic Priorities'
                                                    ] && (
                                                        <table
                                                            style={{
                                                                borderCollapse: 'collapse',
                                                                width: '100%',
                                                                marginTop: '10px',
                                                            }}
                                                        >
                                                            <thead>
                                                                <tr style={{ background: '#D9E2F1' }}>
                                                                    <th
                                                                        className="report-f20"
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            fontWeight: 500,
                                                                            fontFamily: 'Montserrat',
                                                                        }}
                                                                    >
                                                                        Strategic Area
                                                                    </th>
                                                                    <th
                                                                        className="report-f20"
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            fontWeight: 500,
                                                                            fontFamily: 'Montserrat',
                                                                        }}
                                                                    >
                                                                        Description
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {reportView.detailed_data['Strategic Priorities'][
                                                                    'Strategic Priorities'
                                                                ].map((item, index) => (
                                                                    <tr
                                                                        key={item}
                                                                        style={{
                                                                            background: index % 2 != 0 ? '#D9E2F1' : '',
                                                                            pageBreakInside: 'avoid',
                                                                        }}
                                                                    >
                                                                        <td
                                                                            style={{
                                                                                width: '50%',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            <div className="report-f14">
                                                                                {item.Name && item.Name}
                                                                            </div>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '50%',
                                                                            }}
                                                                        >
                                                                            <div style={{ margin: '15px' }}>
                                                                                {item['Summary'] &&
                                                                                    item['Summary'].length > 0 && (
                                                                                        <ul
                                                                                            style={{
                                                                                                margin: '0px',
                                                                                            }}
                                                                                            className="report-f14"
                                                                                        >
                                                                                            {item['Summary'].map(
                                                                                                sum => (
                                                                                                    <li>{sum}</li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    {reportView.detailed_data &&
                                        reportView.detailed_data['Risk Factors'] &&
                                        reportView.detailed_data['Risk Factors']['Risk Factors'] &&
                                        reportView.detailed_data['Risk Factors']['Risk Factors'].length > 0 && (
                                            <div className="risk_factors" style={{ marginTop: '30px' }}>
                                                <label
                                                    className="report-f22"
                                                    style={{
                                                        color: '#186ADE',
                                                        fontWeight: 500,
                                                        fontFamily: 'Montserrat',
                                                        pageBreakInside: 'avoid',
                                                    }}
                                                >
                                                    RISK FACTORS
                                                </label>

                                                <div style={{ marginTop: '10px' }}>
                                                    <div>
                                                        {reportView.detailed_data['Risk Factors']['Risk Factors'].map(
                                                            (item, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        padding: '10px',
                                                                        background: '#D9E2F1',
                                                                        color: '#186ADE',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        margin: '10px',
                                                                        marginTop: '20px',
                                                                        pageBreakInside: 'avoid',
                                                                    }}
                                                                >
                                                                    <div className="report-f60">{index + 1}</div>
                                                                    <div style={{ marginLeft: '10px' }}>
                                                                        {item.Name && (
                                                                            <label
                                                                                style={{
                                                                                    fontWeight: 600,
                                                                                    color: '#186ADE',
                                                                                }}
                                                                                className="report-f14"
                                                                            >
                                                                                {item.Name}
                                                                            </label>
                                                                        )}
                                                                        {item['Summary'] && item['Summary'].length > 0 && (
                                                                            <ul
                                                                                style={{
                                                                                    margin: '0px',
                                                                                    color: '#186ADE',
                                                                                }}
                                                                                className="report-f14"
                                                                            >
                                                                                {item['Summary'].map(sum => (
                                                                                    <li>{sum}</li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {reportView.detailed_data &&
                                        reportView.detailed_data['Legal Proceedings'] &&
                                        reportView.detailed_data['Legal Proceedings']['Legal Proceedings'] &&
                                        reportView.detailed_data['Legal Proceedings']['Legal Proceedings'].length >
                                            0 && (
                                            <div className="legal_proceedings" style={{ marginTop: '30px' }}>
                                                <label
                                                    className="report-f22"
                                                    style={{
                                                        color: '#186ADE',
                                                        fontWeight: 500,
                                                        fontFamily: 'Montserrat',
                                                        pageBreakInside: 'avoid',
                                                    }}
                                                >
                                                    LEGAL PROCEEDINGS
                                                </label>
                                                <div style={{ marginTop: '10px' }}>
                                                    {reportView.detailed_data['Legal Proceedings'][
                                                        'Legal Proceedings'
                                                    ].map((item, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                padding: '10px',
                                                                background: '#D9E2F1',
                                                                color: '#186ADE',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '10px',
                                                                marginTop: '20px',
                                                            }}
                                                        >
                                                            <div className="report-f60">{index + 1}</div>
                                                            <div style={{ marginLeft: '10px' }}>
                                                                {item.Name && (
                                                                    <label
                                                                        className="report-f14"
                                                                        style={{ color: '#186ADE' }}
                                                                    >
                                                                        {item.Name}
                                                                    </label>
                                                                )}
                                                                {item['Summary'] && (
                                                                    <div
                                                                        className="report-f14"
                                                                        style={{ margin: '0px' }}
                                                                    >
                                                                        <div>{item.Summary}</div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                    {reportView.data && reportView.data.length > 0 && (
                                        <>
                                            <label
                                                className="report-f22"
                                                style={{
                                                    color: '#186ADE',
                                                    fontWeight: 500,
                                                    fontFamily: 'Montserrat',
                                                    marginTop: '20px',
                                                    pageBreakInside: 'avoid',
                                                }}
                                            >
                                                {/* Recent Strategic Business Events */}
                                                KEY DEVELOPMENTS
                                            </label>

                                            <div className="news_section" style={{ marginTop: '15px' }}>
                                                {reportView.data.map(value => (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            border: '1px solid lightgray',
                                                            background: 'white',
                                                            marginTop: '10px',
                                                            borderRadius: '5px',
                                                            pageBreakInside: 'avoid',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: value.image ? '60%' : '100%',
                                                                maxHeight: '250px',
                                                            }}
                                                        >
                                                            <div style={{ margin: '10px' }}>
                                                                {value.source_name && (
                                                                    <div
                                                                        className="source report-f14"
                                                                        style={{ marginTop: '10px' }}
                                                                    >
                                                                        {value.source_name}
                                                                    </div>
                                                                )}
                                                                {value.title && (
                                                                    <div className="title">
                                                                        <a
                                                                            href={value.source ? value.source : ''}
                                                                            style={{
                                                                                color: '#3048A6',
                                                                                overflow: 'hidden',
                                                                                display: '-webkit-box',
                                                                                WebkitLineClamp: 2,
                                                                                WebkitBoxOrient: 'vertical',
                                                                                marginTop: '10px',
                                                                                textDecoration: 'none',
                                                                            }}
                                                                            className="report-f14"
                                                                        >
                                                                            {value.title}
                                                                        </a>
                                                                    </div>
                                                                )}
                                                                {value.snippet && (
                                                                    <div
                                                                        className="snippet report-f14"
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            display: '-webkit-box',
                                                                            WebkitLineClamp: 4,
                                                                            WebkitBoxOrient: 'vertical',
                                                                            marginTop: '10px',
                                                                        }}
                                                                        title={value.snippet}
                                                                    >
                                                                        {value.snippet}
                                                                    </div>
                                                                )}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        marginTop: '10px',
                                                                    }}
                                                                >
                                                                    {value.date && (
                                                                        <div
                                                                            className="date report-f14"
                                                                            style={{ color: 'lightgray' }}
                                                                        >
                                                                            {value.date}
                                                                        </div>
                                                                    )}
                                                                    {value.subcategories &&
                                                                        value.subcategories.map(each => (
                                                                            <>
                                                                                <p style={{ margin: '0px 10px 0px' }}>
                                                                                    {' '}
                                                                                    |{' '}
                                                                                </p>
                                                                                <div
                                                                                    className="date report-f14"
                                                                                    style={{
                                                                                        border: 'solid 2px orange',
                                                                                        padding: '5px',
                                                                                        borderRadius: '14px',
                                                                                    }}
                                                                                >
                                                                                    {each.name}
                                                                                </div>
                                                                            </>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {value.image && (
                                                            <div style={{ width: '40%' }}>
                                                                <img
                                                                    src={value.image}
                                                                    style={{
                                                                        width: '100%',
                                                                        maxHeight: '250px',
                                                                        borderRadius: '10px',
                                                                    }}
                                                                    alt="news_image"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    {reportView.detailed_data &&
                                        reportView.detailed_data['Litigable Sector Issues'] &&
                                        reportView.detailed_data['Litigable Sector Issues']['Challenges'] &&
                                        reportView.detailed_data['Litigable Sector Issues']['Challenges'].length >
                                            0 && (
                                            <div className="litigable issues" style={{ marginTop: '30px' }}>
                                                <label
                                                    className="report-f22"
                                                    style={{
                                                        color: '#186ADE',
                                                        fontWeight: 500,
                                                        fontFamily: 'Montserrat',
                                                        pageBreakInside: 'avoid',
                                                        textTransform: 'uppercase',
                                                    }}
                                                >
                                                    LITIGABLE ISSUES
                                                    {reportView.detailed_data['Sector name'] &&
                                                        ' IN ' +
                                                            reportView.detailed_data['Sector name'] +
                                                            ' SECTOR : ' +
                                                            reportView.name}
                                                </label>

                                                <div style={{ marginTop: '10px' }}>
                                                    {reportView.detailed_data['Litigable Sector Issues'][
                                                        'Challenges'
                                                    ].map((item, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                padding: '10px',
                                                                background: '#D9E2F1',
                                                                color: '#186ADE',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '10px',
                                                                marginTop: '20px',
                                                                pageBreakInside: 'avoid',
                                                            }}
                                                        >
                                                            <div className="report-f60">{index + 1}</div>

                                                            <div style={{ marginLeft: '10px' }}>
                                                                {item.Challenge && (
                                                                    <label
                                                                        className="report-f14"
                                                                        style={{ color: '#186ADE' }}
                                                                    >
                                                                        {item.Challenge}
                                                                    </label>
                                                                )}
                                                                {item['Litigation Issues and Risks'] &&
                                                                    item['Litigation Issues and Risks'].length > 0 && (
                                                                        <ul
                                                                            className="report-f14"
                                                                            style={{ margin: '0px' }}
                                                                        >
                                                                            {item['Litigation Issues and Risks'].map(
                                                                                (risk, riskIndex) => (
                                                                                    <li>{risk['Issue and Risk']}</li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                    {reportView.detailed_data &&
                                        reportView.detailed_data['Sector Update'] &&
                                        reportView.detailed_data['Sector Update'].length > 0 && (
                                            <>
                                                <label
                                                    className="report-f22"
                                                    style={{
                                                        color: '#186ADE',
                                                        fontWeight: 500,
                                                        fontFamily: 'Montserrat',
                                                        marginTop: '20px',
                                                        pageBreakInside: 'avoid',
                                                    }}
                                                >
                                                    SECTOR UPDATE
                                                </label>

                                                <div className="news_section" style={{ marginTop: '15px' }}>
                                                    {reportView.detailed_data['Sector Update'].map(value => (
                                                        <div
                                                            style={{
                                                                border: '2px solid #186ADE',
                                                                background: 'white',
                                                                marginTop: '10px',
                                                                borderRadius: '5px',
                                                                pageBreakInside: 'avoid',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <div style={{ margin: '10px' }}>
                                                                    {value.source_name && (
                                                                        <div
                                                                            className="source report-f14"
                                                                            style={{
                                                                                marginTop: '10px',
                                                                            }}
                                                                        >
                                                                            {value.source_name}
                                                                        </div>
                                                                    )}
                                                                    {value.title && (
                                                                        <div
                                                                            className="title"
                                                                            style={{ marginTop: '10px' }}
                                                                        >
                                                                            <a
                                                                                href={value.source ? value.source : ''}
                                                                                style={{
                                                                                    color: '#3048A6',
                                                                                    // overflow: 'hidden',
                                                                                    // display: '-webkit-box',
                                                                                    // WebkitLineClamp: 2,
                                                                                    // WebkitBoxOrient: 'vertical',
                                                                                    marginTop: '10px',
                                                                                    textDecoration: 'none',
                                                                                }}
                                                                                className="report-f14"
                                                                            >
                                                                                {value.title}
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                    {value.snippet && (
                                                                        <div
                                                                            className="snippet report-f14"
                                                                            style={{
                                                                                // overflow: 'hidden',
                                                                                // display: '-webkit-box',
                                                                                // WebkitLineClamp: 4,
                                                                                // WebkitBoxOrient: 'vertical',
                                                                                // lineHeight: '25px',
                                                                                marginTop: '10px',
                                                                            }}
                                                                            title={value.snippet}
                                                                        >
                                                                            {value.snippet}
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            marginTop: '10px',
                                                                        }}
                                                                    >
                                                                        {value.date && (
                                                                            <div className="date report-f14">
                                                                                {value.date}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                </>
                            </div>
                            <div
                                className="A4"
                                style={{
                                    background: '#186ADE',
                                    color: 'white',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                    pageBreakBefore: 'always',
                                }}
                            >
                                <div className="report-f25 report-t150">This Report was Created by Kaitongo</div>
                                <div className="report-f60" style={{ marginTop: '20px' }}>
                                    <div>Explore more in-depth</div>
                                    <div>reports, cutting-edge</div>
                                    <div>insights, and powerful</div>
                                    <div>tools to drive your success.</div>
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <span className="report-f25"> Discover More Features on </span>{' '}
                                    <span className="report-f40" style={{ textDecoration: 'underline' }}>
                                        Kaitongo
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>

            <Modal isOpen={emailModal} toggle={() => setEmailModal(!emailModal)}>
                <ModalHeader>
                    <strong>Email</strong>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={e => sendingMail(e)}>
                        <Label>Email-ID</Label>
                        <Input type="email" name="email" />
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={showSpinner ? true : false}
                            style={{ marginTop: '10px', float: 'right', textAlign: 'center' }}
                        >
                            {showSpinner && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ margin: '1px 5px 0px' }}
                                />
                            )}
                            Send Email
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
