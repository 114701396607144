import React, { Component } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import IndustrySelectionDropdown from './IndustrySelectionDropdown'
import CategoriesDropDownAlt from './CategoriesDropDownAlt'
import RegionSelectWidget from './RegionSelectWidget'
import OpportunitiesWidget from './OpportunitiesWidget'
import { getFullFormattedDate } from '../../utils/dateHelpers'
import linkIcon from '../../assets/icons/linkIcon.svg'
import { Modal, ModalBody } from 'reactstrap'
import './NewsEditCard2020.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import Check from '../../assets/icons/check.svg'
import TopicsDropDown from './TopcisDropDownAlt'
import SendIcon from '../../assets/icons/icon-ionic-ios-send.svg'
import deleteBin from '../../assets/icons/delete-blue-fill.svg'
import EyeIcon from '../../assets/icons/eye-blue-fill.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import { getCookie } from '../../utils'
import RegionHiearchyMultiSelect from '../../screens/NewsFeedScreen/components/RegionHiearchyMultiSelect'
const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token

export default class NewsEditCardAnalyst extends Component {
    state = {
        title: this.props.EachrawNews.title,
        snippet: this.props.EachrawNews.snippet,
        category: this.props.EachrawNews.categories,
        industries: this.props.isPublishedNews ? this.props.EachrawNews.industry : this.props.EachrawNews.industries,
        sectors: this.props.isPublishedNews ? this.props.EachrawNews.sector : this.props.EachrawNews.sectors,
        country: this.props.EachrawNews.allRegions && this.props.allRegions ? [] : [],
        companies: this.props.allCompanies
            ? this.props.allCompanies.filter(comp => this.props.EachrawNews.companies.includes(comp.value))
            : [],
        linkedInGroups:
            this.props.allLinkedInGroups && this.props.EachrawNews.linkedin_groups
                ? this.props.allLinkedInGroups.filter(group =>
                      this.props.EachrawNews.linkedin_groups.includes(group.value)
                  )
                : [],
        modalOpen: false,
        selectedOpportunities: this.props.isPublishedNews ? this.props.EachrawNews.opportunities : [],
        featured: this.props.EachrawNews.featured ? this.props.EachrawNews.featured : false,
        selectedLawFirms: this.props.EachrawNews.law_firms ? this.props.EachrawNews.law_firms : [],
        //under_review: this.props.EachrawNews.under_review ? this.props.EachrawNews.under_review : false,
        selectedTopics: this.props.EachrawNews.topics
            ? this.props.allTopics.filter(top => this.props.EachrawNews.topics.includes(top.value))
            : [],
        collapsed: this.props.collapsed,
        subCategory: this.props.EachrawNews.subcategories,
        selCountry: [],
        allRegCouPro: [
            ...this.props.EachrawNews.region.map(val => {
                return { id: val.id, name: val.name, type: 'Region' }
            }),
            ...this.props.EachrawNews.countries.map(val => {
                return { id: val.id, name: val.name, type: 'Country' }
            }),
            ...this.props.EachrawNews.province.map(val => {
                return { id: val.id, name: val.name, type: 'Province' }
            }),
        ],
        selectAll: this.props.selectAll,
        isAllSelected: this.props.isAllSelected,
    }

    componentDidUpdate = prevProps => {
        if (prevProps.selectAll !== this.props.selectAll) {
            this.setState({ isStorySelected: this.props.selectAll })
            this.prepareAlteredStoryHandler()
        }
        if (prevProps.isAllSelected !== this.props.isAllSelected) {
            this.setState({ isStorySelected: this.props.isAllSelected })
            this.prepareAlteredStoryHandler()
        }
        if (prevProps.allTopics !== this.props.allTopics) {
            this.setState({
                selectedTopics: this.props.allTopics.filter(top => this.props.EachrawNews.topics.includes(top.value)),
            })
        }
        // if (prevProps.allRegions !== this.props.allRegions) {
        //     this.setState({
        //         // country: this.props.allRegions.filter(reg =>
        //         //     this.props.EachrawNews.region_hierarchy.regions.includes(reg.value)
        //         // ),
        //     })
        // }
        if (prevProps.allCompanies !== this.props.allCompanies) {
            this.setState({
                companies: this.props.allCompanies.filter(comp =>
                    this.props.EachrawNews.companies.includes(comp.value)
                ),
            })
        }
        if (prevProps.collapsed !== this.props.collapsed) {
            this.setState({ collapsed: this.props.collapsed })
        }
    }

    prepareAlteredStoryHandler = () => {
        let alteredStory = this.props.EachrawNews
        alteredStory.title = this.state.title
        alteredStory.snippet = this.state.snippet
        if (this.props.isPublishedNews) {
            alteredStory.industry = this.state.industries
            alteredStory.sector = this.state.sectors
        } else {
            alteredStory.industries = this.state.industries
            alteredStory.sectors = this.state.sectors
        }
        alteredStory.countries = this.state.selCountry
            .filter(value => value.type === 'Country')
            .map(value => {
                return parseInt(value.value.split('_')[1])
            })
        alteredStory.region = this.state.selCountry
            .filter(value => value.type === 'Region')
            .map(value => {
                return parseInt(value.value.split('_')[1])
            })
        alteredStory.province = this.state.selCountry
            .filter(value => value.type === 'Province')
            .map(value => {
                return parseInt(value.value.split('_')[1])
            })
        alteredStory.companies = this.state.companies.map(comp => comp.value)
        alteredStory.subcategories = this.state.subCategory
        alteredStory.selectedOpportunities = this.state.selectedOpportunities
        alteredStory.linkedInGroups = this.state.linkedInGroups
        alteredStory.featured = this.state.featured
        alteredStory.law_firms = this.state.selectedLawFirms
        alteredStory.topics = this.state.selectedTopics
        this.props.addToSelection(alteredStory)
    }

    handleSubCategoryChange = (sub, subId) => {
        let subCopy = [...this.state.subCategory]
        if (subCopy.find(arr => arr.name === sub)) {
            subCopy = subCopy.filter(arr => arr.name !== sub)
            this.setState({ subCategory: subCopy })
        } else {
            let newCat = {
                id: subId,
                name: sub,
            }
            subCopy.push(newCat)
            this.setState({ subCategory: subCopy })
        }
    }

    handleCategoryChange = (catOrSub, cat, catId, catIndex, sub, subId) => {
        if (catOrSub === 'cat') {
            let catCopy = [...this.state.category]
            if (catCopy.find(arr => arr.name === cat)) {
                catCopy = catCopy.filter(arr => arr.name !== cat)
                this.setState({ category: catCopy })
            } else {
                let newCat = {
                    name: cat,
                    id: catId,
                    subcategories: [],
                }
                catCopy.push(newCat)
                this.setState({ category: catCopy })
            }
        } else if (catOrSub === 'sub') {
            let catCopy = [...this.state.category]
            let newCatIndex = catIndex

            //create category if it doesn't exist
            if (this.state.category.findIndex(arr => arr.name === cat) === -1) {
                let newCat = {
                    id: catId,
                    name: cat,
                    subcategories: [],
                }
                catCopy.push(newCat)
                newCatIndex = catCopy.length - 1
            }
            if (catCopy[newCatIndex].subcategories.find(arr => arr.id === subId)) {
                //yes sub category exists
                let newSubcategories = catCopy[newCatIndex].subcategories.filter(arr => arr.id !== subId)
                if (newSubcategories.length === 0) {
                    catCopy.splice(newCatIndex, 1)
                } else {
                    catCopy[newCatIndex].subcategories = newSubcategories
                }
                this.setState({ category: catCopy })
            } else {
                //console.log("no sub category doesn't exist")
                catCopy[newCatIndex].subcategories.push({ name: sub, id: subId })
                this.setState({ category: catCopy })
            }
        }
    }

    removeSubCategory = () => {
        this.setState({ subCategory: [] })
    }

    handleTopicChange = topic => {
        if (topic === null) {
            this.setState({
                selectedTopics: [],
            })
        } else {
            this.setState({
                selectedTopics: topic,
            })
        }
    }

    handleIndustryChange = (indId, indType) => {
        if (indType === 'Industry') {
            let indCopy = [...this.state.industries]
            if (indCopy.find(arr => arr === indId)) {
                let indFiltered = indCopy.filter(ind => ind !== indId)
                this.setState({ industries: indFiltered })
            } else {
                indCopy.push(indId)
                this.setState({ industries: indCopy })
            }
        } else if (indType === 'Sector') {
            let indCopy = [...this.state.sectors]
            if (indCopy.find(arr => arr === indId)) {
                let indFiltered = indCopy.filter(ind => ind !== indId)
                this.setState({ sectors: indFiltered })
            } else {
                indCopy.push(indId)
                this.setState({ sectors: indCopy })
            }
        }
    }

    handleRegionChange = country => {
        // 1 = United States, 2 = Canada, 3 = All
        if (this.state.country === null) {
            this.setState({ country: country })
        } else if (this.state.country === 1 && country === 1) {
            this.setState({ country: null })
        } else if (this.state.country === 2 && country === 2) {
            this.setState({ country: null })
        } else if (this.state.country === 1 && country === 2) {
            this.setState({ country: 3 })
        } else if (this.state.country === 2 && country === 1) {
            this.setState({ country: 3 })
        } else if (this.state.country === 3 && country === 2) {
            this.setState({ country: 1 })
        } else if (this.state.country === 3 && country === 1) {
            this.setState({ country: 2 })
        }
    }

    handleCompanyChange = company => {
        if (company === null) {
            this.setState({
                companies: [],
            })
        } else {
            this.setState({
                companies: company,
            })
        }
    }
    handleRegionChanges = cty => {
        if (cty === null) {
            this.setState({
                country: [],
            })
        } else {
            this.setState({
                country: cty,
            })
        }
    }

    handleLinkedInGroupChange = group => {
        if (group === null) {
            this.setState({
                linkedInGroups: [],
            })
        } else {
            this.setState({
                linkedInGroups: group,
            })
        }
    }

    toggleModal = () => {
        if (this.state.modalOpen) {
            this.setState({ modalOpen: false })
        } else {
            this.setState({ modalOpen: true })
        }
    }

    handleOppChange = oppObj => {
        let newSelectedOpportunities = [...this.state.selectedOpportunities]
        if (newSelectedOpportunities.findIndex(arr => arr.id === oppObj.id) === -1) {
            if (this.props.isPublishedNews) {
                let newOppObj = {
                    id: oppObj.id,
                    opportunity_type: oppObj.id,
                    sentence: oppObj.default_sentence,
                    opportunity_type__name: oppObj.name,
                }
                newSelectedOpportunities.push(newOppObj)
            } else {
                newSelectedOpportunities.push(oppObj)
            }
        } else {
            newSelectedOpportunities = newSelectedOpportunities.filter(opp => opp.id !== oppObj.id)
        }
        this.setState({ selectedOpportunities: newSelectedOpportunities })
    }

    handleOppTextChange = (oppId, value) => {
        let oppToEdit = this.state.selectedOpportunities.find(arr => arr.id === oppId)
        let oppIndex = this.state.selectedOpportunities.findIndex(arr => arr.id === oppId)
        if (this.props.isPublishedNews) {
            oppToEdit.sentence = value
        } else {
            oppToEdit.default_sentence = value
        }
        let selectedCopy = [...this.state.selectedOpportunities]
        selectedCopy[oppIndex] = oppToEdit
        this.setState({ selectedOpportunities: selectedCopy })
    }

    handleOppTextChangePeer = (oppId, value) => {
        let oppToEdit = this.state.selectedOpportunities.find(arr => arr.id === oppId)
        let oppIndex = this.state.selectedOpportunities.findIndex(arr => arr.id === oppId)
        if (this.props.isPublishedNews) {
            oppToEdit.peer_sentence = value
        } else {
            oppToEdit.default_peer_sentence = value
        }
        let selectedCopy = [...this.state.selectedOpportunities]
        selectedCopy[oppIndex] = oppToEdit
        this.setState({ selectedOpportunities: selectedCopy })
    }

    getLawFirmsSearch = value => {
        if (value.length > 2) {
            return axios.get(`/news/auto-complete/?type=lawfirm&string=${value}`).then(response => {
                return response.data.map(item => ({ label: item.name, value: item.id }))
            })
        }
    }

    handleSaveStoryForReview = () => {
        let subCatArr = []
        this.state.subCategory.forEach(sub => {
            subCatArr.push(sub.id)
        })
        let newsObj = {
            id: this.props.EachrawNews.id,
            title: this.state.title,
            snippet: this.state.snippet,
            subcategory: subCatArr,
            industry: this.state.industries,
            sector: this.state.sectors,
            companies: this.state.companies.map(comp => comp.value),
            country: this.state.selCountry
                .filter(value => value.type === 'Country')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                }),
            regions: this.state.selCountry
                .filter(value => value.type === 'Region')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                }),
            provinces: this.state.selCountry
                .filter(value => value.type === 'Province')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                }),
            topics: this.state.selectedTopics.map(item => item.value),
            opportunities: this.state.selectedOpportunities,
            under_review: false,
        }

        axios
            .post(`/core/save-raw-news/`, newsObj)
            .then(response => {
                toast('Story Updated')
            })
            .catch(err => {
                toast('Error saving story')
            })
    }

    setSection = props => {
        this.setState({ selCountry: props })
    }

    handleSaveStory = () => {
        if (this.props.isPublishedNews) {
            let subCatArr = []
            this.state.subCategory.forEach(sub => {
                subCatArr.push(sub.id)
            })
            let newsObj = {
                id: this.props.EachrawNews.id,
                title: this.state.title,
                snippet: this.state.snippet,
                opportunities: this.state.selectedOpportunities,
                subcategory: subCatArr,
                industry: this.state.industries,
                sector: this.state.sectors,
                companies: this.state.companies.map(comp => comp.value),
                country: this.state.selCountry
                    .filter(value => value.type === 'Country')
                    .map(value => {
                        return parseInt(value.value.split('_')[1])
                    }),
                regions: this.state.selCountry
                    .filter(value => value.type === 'Region')
                    .map(value => {
                        return parseInt(value.value.split('_')[1])
                    }),
                provinces: this.state.selCountry
                    .filter(value => value.type === 'Province')
                    .map(value => {
                        return parseInt(value.value.split('_')[1])
                    }),
                linkedin_groups: this.state.linkedInGroups.map(group => group.value),
                featured: this.state.featured,
                topics: this.state.selectedTopics.map(item => item.value),
            }

            axios
                .post(`/core/update-published-news/`, newsObj)
                .then(response => {
                    toast('Story Updated')
                })
                .catch(err => {
                    toast('Error saving story')
                })
        }
        if (this.props.isDeal) {
            let dealObj = {
                id: this.props.EachrawNews.id,
                title: this.state.title,
                snippet: this.state.snippet,
                sector: this.state.sectors,
                country: this.state.selCountry
                    .filter(value => value.type === 'Country')
                    .map(value => {
                        return parseInt(value.value.split('_')[1])
                    }),
                regions: this.state.selCountry
                    .filter(value => value.type === 'Region')
                    .map(value => {
                        return parseInt(value.value.split('_')[1])
                    }),
                provinces: this.state.selCountry
                    .filter(value => value.type === 'Province')
                    .map(value => {
                        return parseInt(value.value.split('_')[1])
                    }),
                law_firms: this.state.selectedLawFirms.map(item => item.value),
            }

            axios
                .post(`/core/update-deal/`, dealObj)
                .then(response => {
                    toast('Deal Updated')
                })
                .catch(err => {
                    toast('Error saving deal')
                })
        }
    }

    render() {
        const {
            id,
            source,
            source_name,
            date,
            pull_date,
            content,
            highlight_title,
            highlight_text,
        } = this.props.EachrawNews
        const { isStorySelected, isPublishedNews, isDeal, EachrawNews, isRawNews } = this.props

        if (this.state.collapsed) {
            return (
                <div className="newsCardCollapsed__Container" style={{ width: '92%' }}>
                    <div className="newsCardCollapsed__selectColumn">
                        <div
                            className={isStorySelected ? 'newsCard__selectSquare-active' : 'newsCard__selectSquare'}
                            style={{ width: '15px', height: '15px', margin: '0' }}
                            onClick={
                                isStorySelected
                                    ? () => this.props.removeFromSelection(this.props.EachrawNews)
                                    : this.prepareAlteredStoryHandler
                            }
                        />
                    </div>
                    <div className="newsCardCollapsed__date">
                        <p className="newsCard20__companyDate">{getFullFormattedDate(date)}</p>
                    </div>
                    <div className="newsCardCollapsed__date">
                        <p className="newsCard20__companyDate">{getFullFormattedDate(pull_date)}</p>
                    </div>
                    <div className="newsCardCollapsed__title">
                        <p className="newsCardCollapsed__title__p" onClick={() => this.setState({ collapsed: false })}>
                            {this.state.title}
                        </p>
                    </div>
                    {this.props.isPublishedNews && (
                        <div className="newsCardCollapsed_rightButtons">
                            <IconButton onClick={() => this.setState({ collapsed: false })}>
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    )}
                    {this.props.isRawNews && (
                        <div className="newsCardCollapsed__rightButtons">
                            <span
                                onClick={() => {
                                    this.prepareAlteredStoryHandler()
                                    setTimeout(() => {
                                        this.props.handleBatchPublishStories()
                                    }, 1000)
                                }}
                            >
                                <img src={SendIcon} alt="publish icon" className="newsCardCollapsed__icons" />
                                Publish
                            </span>

                            <span
                                onClick={() => {
                                    this.prepareAlteredStoryHandler()
                                    setTimeout(() => {
                                        this.props.handleBatchPublishStories('review')
                                    }, 1000)
                                }}
                            >
                                <img src={EyeIcon} alt="review icon" className="newsCardCollapsed__icons" />
                                Review
                            </span>

                            <span
                                onClick={() => {
                                    this.prepareAlteredStoryHandler()
                                    setTimeout(() => {
                                        this.props.handleBatchDeleteStories()
                                    }, 1000)
                                }}
                            >
                                <img src={deleteBin} alt="delete icon" className="newsCardCollapsed__icons" />
                                Delete
                            </span>
                        </div>
                    )}
                </div>
            )
        }

        //full view
        return (
            <div
                className="newsCard20__containerAnalyst"
                key={'newsCard' + id}
                style={EachrawNews.under_review && isRawNews ? { backgroundColor: '#d9d8d8' } : null}
            >
                <div className="newsCard__selectColumn">
                    <div
                        className={isStorySelected ? 'newsCard__selectSquare-active' : 'newsCard__selectSquare'}
                        onClick={
                            isStorySelected
                                ? () => this.props.removeFromSelection(this.props.EachrawNews)
                                : this.prepareAlteredStoryHandler
                        }
                    />
                </div>

                <div className="newsCard__bodyColumn">
                    <div className="newsCard20__dateAndSave">
                        <p className="newsCard20__companyDate">{getFullFormattedDate(date)}</p>
                        {EachrawNews.under_review && isRawNews && <p>UNDER REVIEW</p>}
                        {(isPublishedNews || isDeal) && (
                            <p className="newsCard20__saveStoryButton" onClick={this.handleSaveStory}>
                                Save
                            </p>
                        )}
                        {this.props.isRawNews && (
                            <div>
                                <span
                                    className="newsCard20__collapseButton"
                                    onClick={() => this.setState({ collapsed: true })}
                                >
                                    Collapse
                                </span>

                                <span className="newsCard20__saveStoryButton" onClick={this.handleSaveStoryForReview}>
                                    Save raw story
                                </span>
                            </div>
                        )}
                        {this.props.isPublishedNews && (
                            <div>
                                <span
                                    className="newsCard20__collapseButton"
                                    onClick={() => this.setState({ collapsed: true })}
                                >
                                    Collapse
                                </span>
                            </div>
                        )}
                    </div>
                    <input
                        disabled={isStorySelected ? true : false}
                        type="text"
                        name="title"
                        value={this.state.title}
                        className="newsCard20__title"
                        onChange={e => this.setState({ title: e.target.value })}
                    />

                    {(this.props.isRawNews || this.props.isPublishedNews) && (
                        <p
                            className="newsCard20__editAbstractButton"
                            onClick={isStorySelected ? null : () => this.setState({ modalOpen: true })}
                        >
                            Edit abstract in full text
                        </p>
                    )}

                    <textarea
                        disabled={isStorySelected ? true : false}
                        className="newsCard20__snippetBox"
                        name="snippet"
                        cols="30"
                        rows="5"
                        value={this.state.snippet}
                        onChange={e => this.setState({ snippet: e.target.value })}
                    />

                    <div className="newsCard20__sourceLinkSection">
                        <p style={{ margin: '10px 0' }}>
                            <a
                                href={!isDeal ? this.props.EachrawNews.news_url : this.props.EachrawNews.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={process.env.REACT_APP_MEDIA_URL + linkIcon}
                                    alt="link Icon"
                                    style={{ marginRight: '10px' }}
                                />
                                {!isDeal
                                    ? this.props.EachrawNews.source_name
                                        ? this.props.EachrawNews.source_name
                                        : this.props.EachrawNews.source.substring(0, 80)
                                    : this.props.EachrawNews.url
                                    ? this.props.EachrawNews.url.substring(0, 80)
                                    : ''}
                            </a>
                        </p>
                    </div>

                    <div className="newsCard20__industryAndRegion">
                        <IndustrySelectionDropdown
                            allIndustries={this.props.allIndustries}
                            industries={this.state.industries}
                            sectors={this.state.sectors}
                            handleIndustryChange={this.handleIndustryChange}
                            isStorySelected={isStorySelected}
                        />
                        <div style={{ alignSelf: 'end', width: '38%' }}>
                            <div className="newsCard20__oppHeading">
                                <p>Region/Country</p>
                            </div>
                            {/* <Select
                                isMulti
                                isDisabled={isStorySelected}
                                className="addNews2__selectInput"
                                placeholder="Select countries"
                                options={this.props.allRegions}
                                value={this.state.country}
                                onChange={this.handleRegionChanges}
                            /> */}
                            <RegionHiearchyMultiSelect
                                selectedQueries={this.state.selCountry}
                                setSelectedQueries={this.setSection}
                                regionHierarchy={this.props.regionHierarchy}
                                setSection={this.setSection}
                                isAnalystNewsSection={true}
                                selectedPreference={this.state.allRegCouPro}
                            />
                        </div>
                    </div>

                    {(this.props.isRawNews || this.props.isPublishedNews) && (
                        <div className="newsCard20__companyAndFeatured">
                            <div className="width75">
                                <div className="newsCard20__oppHeading">
                                    <p>Company</p>
                                </div>
                                <Select
                                    isMulti
                                    isDisabled={isStorySelected}
                                    className="width100 addNews2__selectInput"
                                    placeholder="Select companies"
                                    options={this.props.allCompanies}
                                    value={this.state.companies}
                                    onChange={this.handleCompanyChange}
                                />
                            </div>

                            {this.props.usertype !== 'client_analyst' && (
                                <div>
                                    <div className="newsCard20__oppHeading">
                                        <p>Featured Story</p>
                                    </div>
                                    <div
                                        className={
                                            this.state.featured ? 'regionSelect__box-active' : 'regionSelect__box'
                                        }
                                        style={{ background: isStorySelected ? '#ebeced' : '' }}
                                        onClick={
                                            isStorySelected
                                                ? null
                                                : () => this.setState({ featured: !this.state.featured })
                                        }
                                    >
                                        <div
                                            className="regionSelect__check"
                                            style={{ background: isStorySelected ? '#ebeced' : '' }}
                                        >
                                            {this.state.featured && (
                                                <img
                                                    src={process.env.REACT_APP_MEDIA_URL + Check}
                                                    alt="region check mark"
                                                />
                                            )}
                                        </div>
                                        <p>Featured</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {(this.props.isRawNews || this.props.isPublishedNews) && (
                        <div className="newsCard20__bottomRow" style={{ marginTop: '0px' }}>
                            <CategoriesDropDownAlt
                                category={this.state.category}
                                subCategory={this.state.subCategory}
                                handleCategoryChange={this.handleCategoryChange}
                                allCategories={this.props.allCategories}
                                handleSubCategoryChange={this.handleSubCategoryChange}
                                allSubCategoriesOnly={this.props.allSubCategoriesOnly}
                                removeSubCategory={this.removeSubCategory}
                                isStorySelected={isStorySelected}
                                extraStyle={{ width: '60%', marginRight: '8px' }}
                            />
                            {this.props.usertype !== 'client_analyst' && (
                                <TopicsDropDown
                                    selectedTopics={this.state.selectedTopics}
                                    allTopics={this.props.allTopics}
                                    handleTopicChange={this.handleTopicChange}
                                    isStorySelected={isStorySelected}
                                    extraStyle={{ width: '40%' }}
                                />
                            )}
                        </div>
                    )}

                    {this.props.usertype !== 'client_analyst' && (this.props.isRawNews || this.props.isPublishedNews) && (
                        <div style={{ marginTop: '10px' }}>
                            <OpportunitiesWidget
                                subCategory={this.state.subCategory}
                                selectedOpportunities={this.state.selectedOpportunities}
                                handleOppChange={this.handleOppChange}
                                handleOppTextChangePeer={this.handleOppTextChangePeer}
                                handleOppTextChange={this.handleOppTextChange}
                                category={this.state.category}
                                storyId={this.props.EachrawNews.id}
                                isDisabled={isStorySelected}
                                industries={this.state.industries}
                                isPublishedNews={this.props.isPublishedNews}
                            />
                        </div>
                    )}

                    {(this.props.isRawNews || this.props.isPublishedNews) && (
                        <div className="multiSelectDropDown__ContainerAlt" style={{ marginTop: '10px' }}>
                            <div className="multiSelectDropDown__headingBoxAlt">
                                <div className="newsCard20__oppHeading">
                                    <p>LinkedIn</p>
                                    <p>Groups</p>
                                </div>
                                <div className="newsCard20__catBorder">
                                    <Select
                                        isMulti
                                        isDisabled={isStorySelected}
                                        className="width100 addNews2__selectInput"
                                        placeholder="Select LinkedIn Groups"
                                        options={this.props.allLinkedInGroups}
                                        value={this.state.linkedInGroups}
                                        onChange={this.handleLinkedInGroupChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {isDeal && (
                        <div className="newsCard20__companyAndFeatured">
                            <div className="width75">
                                <h3 className="newsCard20__oppHeading">Law Firms</h3>
                                <AsyncSelect
                                    isMulti
                                    name="Law Firm"
                                    isDisabled={isStorySelected}
                                    loadOptions={this.getLawFirmsSearch}
                                    onChange={value => {
                                        this.setState({ selectedLawFirms: value })
                                    }}
                                    value={this.state.selectedLawFirms}
                                    placeholder="Select Law Firms"
                                    className="width100 addNews2__selectInput"
                                />
                            </div>
                        </div>
                    )}

                    <Modal
                        isOpen={this.state.modalOpen}
                        toggle={this.toggleModal}
                        className="newsCard20__contentCompareModalContainer"
                        size="xl"
                    >
                        <ModalBody>
                            <h1
                                style={{ textAlign: 'right', cursor: 'pointer' }}
                                onClick={() => this.setState({ modalOpen: false })}
                            >
                                X
                            </h1>

                            <div className="newsCard20__modalBody">
                                <div className="newsCard20__abstractColumn">
                                    <h1>Abstract </h1>
                                    <p className="newsCard20__companyDate">
                                        {source_name ? source_name + ' - ' : ''} {getFullFormattedDate(date)}
                                    </p>
                                    <input
                                        disabled={
                                            this.props.selectedStories.find(arr => arr === this.props.rawNews)
                                                ? true
                                                : false
                                        }
                                        type="text"
                                        name="title"
                                        value={this.state.title}
                                        className="newsCard20__title"
                                        onChange={e => this.setState({ title: e.target.value })}
                                    />

                                    <textarea
                                        disabled={
                                            this.props.selectedStories.find(arr => arr === this.props.rawNews)
                                                ? true
                                                : false
                                        }
                                        className="newsCard20__snippetBox"
                                        name="snippet"
                                        cols="30"
                                        rows="15"
                                        value={this.state.snippet}
                                        onChange={e => this.setState({ snippet: e.target.value })}
                                    />
                                </div>

                                <div className="newsCard20__articleColumn">
                                    <p dangerouslySetInnerHTML={{ __html: highlight_title }} />
                                    <p dangerouslySetInnerHTML={{ __html: highlight_text }} />
                                    <p className="newsCard20__articleContent">{content}</p>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        )
    }
}
