import React, { PureComponent } from 'react'
import './CompanyLogo.css'

class CompanyLogo extends PureComponent {
    render() {
        const { logo, name, id, title_link, OuterStyle, square } = this.props

        let initials = '??'
        if (name && name !== '') {
            const nameSplit = name.trim().split(' ')
            if (nameSplit.length === 1) {
                initials = nameSplit[0][0]
            } else {
                if (nameSplit[1][0] === '&') {
                    initials = nameSplit[0][0] + nameSplit[2][0]
                } else {
                    const filteredArray = nameSplit.filter(value => value.trim() !== '')

                    initials = filteredArray[0][0] + filteredArray[1][0]
                }
            }
        } else if (title_link && title_link !== '') {
            const title = title_link.split('>')[1]
            initials = title.substring(0, 2)
        } else {
            if (this.props.company && this.props.company.name) {
                const companyName = this.props.company.name
                if (companyName && companyName !== '') {
                    const nameSplit = companyName.trim().split(' ')
                    if (nameSplit.length === 1) {
                        initials = nameSplit[0][0]
                    } else {
                        if (nameSplit[1][0] === '&') {
                            initials = nameSplit[0][0] + nameSplit[2][0]
                        } else {
                            initials = nameSplit[0][0] + nameSplit[1][0]
                        }
                    }
                }
            }
        }

        const replaceImage = id => {
            let outer = document.createElement('DIV')
            outer.setAttribute('class', 'companylogo__initials')
            outer.setAttribute('id', id)
            let inner = document.createElement('H3')
            inner.innerText = initials
            outer.appendChild(inner)

            let imageNodeParent = document.getElementById('parent-' + id)
            imageNodeParent.innerHTML = ''
            imageNodeParent.appendChild(outer)
        }

        return (
            <div>
                <div className={square ? 'company-logo-square' : 'company-logo'} style={OuterStyle}>
                    <div className={square ? null : 'company-logo-inner'} id={'parent-' + id}>
                        {logo && logo !== null && logo !== '' && (
                            <img
                                className="img-fluid"
                                alt="company logo"
                                src={logo}
                                id={id}
                                onError={ev => replaceImage(ev.target.id)}
                            />
                        )}

                        {(!logo || logo === null || logo === '') && (
                            <div className="companylogo__initials" id={id}>
                                <h3>{initials}</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CompanyLogo
