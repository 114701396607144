import React, { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Input } from 'reactstrap'
import SearchDropDown from './SearchDropDown'
import axios from 'axios'
import { BiSearch } from 'react-icons/bi'
import { Button } from '@mui/material'
import { AiFillSchedule } from 'react-icons/ai'
import SendIcon from '@mui/icons-material/Send'

export default function NewSearch(props) {
    const {
        basicSearch,
        basicSearchInput,
        setBasicSearchInput,
        clearFilters,
        setSaveQueryName,
        selectedStories,
        setActiveStep,
        isAdvanceSearch,
    } = props
    const {
        wrapperRef,
        allTopics,
        allSubCategories,
        allIndustries,
        basicSearchDrop,
        setBasicSearchDrop,
        setSchedulingSection,
        defaultData,
        setDefaultData,
    } = props

    const [cancelToken, setCancelToken] = useState(null)
    const [cancelSource, setCancelSource] = useState(null)

    useEffect(() => {
        if (cancelToken) {
            cancelToken.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setCancelToken(newCancelSource)
        setCancelSource(newCancelSource.token)

        if (basicSearchInput && basicSearchInput.length > 2) {
            axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(basicSearchInput)}`)
                .then(response => {
                    const filteredData = response.data.map(item => ({
                        value: item.id,
                        label: item.name,
                        url: item.url,
                        type: 'Company',
                        alias: item.alias,
                    }))
                    const uniqueData = new Set([...filteredData])
                    const arrayFromSet = [...uniqueData]
                    setDefaultData(arrayFromSet)
                })
                .catch(err => console.log(err))
        }

        return () => {
            if (cancelToken) {
                cancelToken.cancel('Component unmounted.')
            }
        }
    }, [basicSearchInput])

    return (
        <div className="basic_search_inputs" style={{ width: '100%', margin: 'auto' }}>
            {/* <div style={{ fontWeight: 'bold' }}>Search</div> */}
            <div style={{ marginTop: '8px' }} className="row">
                <div className="col-8" style={{ flex: 4 }}>
                    <div className="d-flex align-items-center">
                        <Input
                            type="text"
                            value={basicSearchInput}
                            placeholder="Search by topic, industry, location or keywords"
                            id="quickinput"
                            style={{
                                height: '40px',
                                border: '1.7px solid #dadada',
                                outline: 'none',
                                borderRadius: '4px',
                            }}
                            onChange={e => {
                                setBasicSearchInput(e.target.value)
                                setBasicSearchDrop(e.target.value.length >= 3 ? true : false)
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    basicSearch()
                                }
                            }}
                            autoComplete="off"
                        />

                        {!isAdvanceSearch && (
                            <button
                                onClick={() => basicSearch()}
                                style={{
                                    background: 'white',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0px 10px',
                                    marginLeft: '20px',
                                    borderRadius: '4px',
                                    border: '2px solid lightgray',
                                }}
                            >
                                <BiSearch style={{ fontSize: '23px', color: 'lightgray' }} />
                            </button>
                        )}
                    </div>
                </div>

                {/* {!isAdvanceSearch && (
                    <div className="col text-end">
                        <Button
                            variant="contained"
                            endIcon={<SendIcon style={{ fontSize: '18px' }} />}
                            style={{
                                background: '#186ADE',
                                borderRadius: '4px',
                                width: 'max-content',
                                marginRight: '20px',
                                fontSize: '14px',
                                height: '40px',
                                color: 'white',
                                opacity: selectedStories.length > 0 ? 1 : 0.6,
                                textTransform: 'none',
                            }}
                            disabled={selectedStories.length > 0 ? false : true}
                            onClick={() => {
                                // setSaveQueryName('Schedule: ' + basicSearchInput)
                                // setSchedulingSection(true)
                                setActiveStep(1)
                            }}
                        >
                            Send Newsletter
                        </Button>
                    </div>
                )} */}
            </div>
            {basicSearchInput && basicSearchInput.length > 2 && basicSearchDrop && (
                <SearchDropDown
                    setBasicSearchDrop={setBasicSearchDrop}
                    setBasicSearchInput={setBasicSearchInput}
                    basicSearch={basicSearch}
                    searchInput={basicSearchInput}
                    allCompanies={defaultData}
                    allIndustries={allIndustries}
                    allCategories={allSubCategories}
                    allTopics={allTopics}
                    wrapperRef={wrapperRef}
                    isWeaviate={true}
                />
            )}
        </div>
    )
}
