import React from 'react'
import Fuse from 'fuse.js'
import './Filter.css'

export default function SearchDropDown(props) {
    const {
        allIndustries,
        allCompanies,
        allCategories,
        allTopics,
        wrapperRef,
        searchInput,
        setBasicSearchDrop,
        setBasicSearchInput,
        basicSearch,
        popularBtns,
    } = props
    function createFuse(list, keys) {
        return new Fuse(list, {
            keys,
            includeScore: true,
            shouldSort: true,
            location: 0,
            threshold: 0,
            minMatchCharLength: 1,
            includeMatches: false,
            ignoreLocation: true,
            ignoreFieldNorm: false,
        })
    }

    const industryFuse = createFuse(allIndustries, ['label'])
    const companyFuse = createFuse(allCompanies, ['label', 'alias'])
    const topicsFuse = createFuse(allTopics, ['label'])
    const categoryFuse = createFuse(allCategories, ['label'])

    function option(type, item) {
        return (
            <div
                className="d-flex basic_drop_options"
                style={{
                    cursor: 'pointer',
                    height: '40px',
                    borderBottom: '1px solid #dadada',
                    borderLeft: '1.5px solid #dadada',
                    borderRight: '1.5px solid #dadada',
                }}
                key={item.item.value + type}
                onClick={() => {
                    setBasicSearchInput(item.item.label)
                    setBasicSearchDrop(false)
                    basicSearch(item.item.label, type)
                    ///remove popularbtn active
                    for (var i = 0; i < popularBtns; i++) {
                        document.getElementById(`btn_${i}`).style.background = null
                        document.getElementById(`btn_${i}`).style.color = 'black'
                    }
                }}
            >
                <span
                    style={{
                        marginLeft: '15px',
                        marginTop: '8px',
                        fontSize: '13px',
                    }}
                >
                    {item.item.label}
                </span>
                {type !== 'Company' ? (
                    <span
                        style={{
                            fontSize: '10px',
                            marginTop: '12px',
                            marginLeft: '10px',
                            color: 'black',
                        }}
                    >
                        {type}
                    </span>
                ) : (
                    <span
                        className="basic_drop_options"
                        style={{
                            fontSize: '10px',
                            marginTop: '11px',
                            marginLeft: '10px',
                            color: 'black',
                        }}
                    >
                        {item.item.url ? item.item.url : type}
                        {/* <a
                            href={
                                item.item.url && item.item.url.startsWith('http')
                                    ? item.item.url
                                    : `http://${item.item.url}`
                            }
                            className="basic_drop_options"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.item.url ? item.item.url : type}
                        </a> */}
                    </span>
                )}
            </div>
        )
    }

    function filterStartsWith(list, input) {
        return list.filter(
            item =>
                item.item.label.toLowerCase().startsWith(input.toLowerCase()) ||
                (item.item.alias && item.item.alias.toLowerCase().includes(input.toLowerCase()))
        )
    }

    return (
        <div
            ref={wrapperRef}
            style={{
                marginTop: '0px',
                maxHeight: '180px',
                overflow: 'overlay',
                width: document.getElementById('quickinput').offsetWidth + 'px',
                position: 'absolute',
                background: 'white',
                zIndex: 1,
            }}
        >
            {filterStartsWith(companyFuse.search(searchInput), searchInput).map(item =>
                option(item.item.type ? item.item.type : 'Company', item)
            )}
            {filterStartsWith(industryFuse.search(searchInput), searchInput).map(item =>
                option(item.item.type ? item.item.type : 'Industry', item)
            )}
            {filterStartsWith(categoryFuse.search(searchInput), searchInput).map(item =>
                option(item.item.type ? item.item.type : 'SubCategory', item)
            )}
            {filterStartsWith(topicsFuse.search(searchInput), searchInput).map(item => option('topic', item))}
        </div>
    )
}
