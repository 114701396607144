import React, { useState, useEffect } from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'
import './components/Filter.css'
import axios from 'axios'
import { getCookie } from '../../utils'
import SendIcon from '../../assets/icons/icon-ionic-ios-send.svg'
import PublishIcon from '../../assets/icons/publish_icon.svg'
import SearchUtility from './components/SearchUtility'
import SelectTemplate from './components/SelectTemplate'
import TemplatePreview from './components/TemplatePreview'
import { CgNotes } from 'react-icons/cg'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { AiOutlineDelete } from 'react-icons/ai'
import { Steppers, StepperButton } from './components/Steppers'
import SendNewsLetter from './components/SendNewsLetter'
import DeleteNews from './components/DeleteNews'
import { toast } from 'react-toastify'
import store from '../../Store'
import uuid from 'react-uuid'
import NewSearchSection from './components/NewSearchSection'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { Button } from '@mui/material'
import ScheduleSucessMsg from './components/ScheduleSucessMsg'
const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token
var lo_ = require('lodash')

export default function SearchComponent(props) {
    const [apiLevel, setApiLevel] = useState('level1')
    useEffect(() => {
        props.location.pathname === '/app/raw-search/' ? setApiLevel('level1') : setApiLevel('level2')
    }, [])
    /// Publishing steps
    const [activeStep, setActiveStep] = useState(0)
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [selectedStories, setSelectedStories] = useState([])
    const [basicSearchResponse, setBasicSearchResponse] = useState({ data: [], next: null })
    const [activeTab, setActiveTab] = useState(0)

    // Template Releated
    const [introText, setIntroText] = useState()
    const [subjectLine, setSubjectLine] = useState()
    const [primaryColor, setPrimaryColor] = useState('')
    const [secondaryColor, setSecondaryColor] = useState('')
    const [title, setTitle] = useState('')

    // Primary Logo
    const [selectedLogo, setSelectedLogo] = useState('')
    const [logoManualImage, setLogoManualImage] = useState(false)
    const [logoFile, setLogoFile] = useState(null)
    const [logoFileName, setFileName] = useState('')
    const [logoSize, setLogoSize] = useState({})

    // Right Side Logo
    const [secondaryLogo, setSecondaryLogo] = useState('')
    const [logo2ManualImage, setLogo2ManualImage] = useState(false)
    const [logo2File, setLogo2File] = useState(null)
    const [seclogoFileName, setsecFileName] = useState('')
    const [secLogoSize, setSecLogoSize] = useState({})

    // Background Image
    const [selectedBg, setSelectedBg] = useState('')
    const [bgManualImage, setBgManualImage] = useState(false)
    const [bgFile, setBgFile] = useState(null)
    const [bgFileName, setBgFileName] = useState('')
    const [categorizedNews, setCategorizedNews] = useState([])
    const [hyperlink, setHyperlink] = useState('')
    const [content, setContent] = useState('')

    const [allCategories, setAllCategories] = useState([])
    const [allTopics, setAllTopics] = useState([])
    const [allIndustries, setAllIndustries] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [allRegions, setAllRegions] = useState([])
    const [allFirms, setAllFirms] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [allFirmUsers, setAllFirmUsers] = useState([])
    const [allSubCategories, setAllSubCategories] = useState([])
    const [allLinkedInGroups, setAllLinkedInGroups] = useState([])
    const [allSources, setAllSources] = useState([])
    const [allHierarchy, setAllHierarchy] = useState([])
    const [practiceSubCategory, setPracticeSubCategory] = useState([])
    const [isQueryEditor, setIsQueryEditor] = useState(false)
    const [isQueryEditorJson, setIsQueryEditorJson] = useState('')
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [advanceResponse, setAdvanceResponse] = useState({ data: [], next: null })
    const usertype = store.getState().auth.user.user_type
    const userEmail = store.getState().auth.user.email
    const userRole = store.getState().auth.user.role

    const [premeetingModel, setPremeetingModal] = useState(false)
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])
    const [PremeetingResponse, setPremeetingResponse] = useState('')
    const [premeetingLoader, setPremeetingLoader] = useState(false)
    const [chat, setChat] = useState(true)
    const [id, setId] = useState(uuid())
    const [selectedLevel, setSelectedLevel] = useState(
        props.location.pathname === '/app/publish-search/' || props.location.pathname === '/app/search/'
            ? 'level2'
            : 'level1'
    )
    const [includeSection, setIncludeSection] = useState(false)
    const [includeBusiness, setIncludeBusiness] = useState(true)
    const [hyperlinkTitle, setHyperLinkTitle] = useState('')
    const [hyperlinkSource, setHyperLinkSource] = useState('')
    const [allCollectionName, setAllCollectionName] = useState([])
    const [users, setUsers] = useState([])
    const [sentUsers, setSentUsers] = useState([])
    const [quickSummary, setQuickSummary] = useState(null)
    const [showFullText, setShowFullText] = useState(false)
    const [searchName, setSearchName] = useState('')
    const [summaryLoader, setSummaryLoader] = useState(false)
    const [overallSummaryLoader, setOverallSummaryLoader] = useState(false)

    const currentPath = props.location.pathname

    useEffect(() => {
        if (currentPath === '/app/search/') {
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent =
                activeStep === 0
                    ? 'Find and Share'
                    : activeStep === 1
                    ? 'Share Newsletter'
                    : activeStep === 2
                    ? 'Preview Editor'
                    : activeStep === 3
                    ? 'Select Users'
                    : 'Newsletter Sent'
        } else if (currentPath === '/app/raw-search/') {
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Publish To Platform'
        } else {
            const headerTitle = document.getElementById('header_title')
            headerTitle.textContent = 'Published News'
        }
    }, [activeStep])

    useEffect(() => {
        axios.get('/news/categories/').then(response => {
            setAllCategories(response.data.results)
        })

        axios.get('/news/all-subcategories/').then(response => {
            setAllSubCategories(response.data)
        })

        getAllCollections()
        getUsers()
        getAllFirms()

        axios.get('/news/practice-areas-subcategories/').then(response => {
            setPracticeSubCategory(
                response.data.results.map((item, index) => ({
                    ...item,
                    id: 'Cat_' + item.id + index,
                    subcategories: item.subcategories.map((it, i) => ({
                        ...it,
                        id: 'Sub_' + it.id + index + i,
                    })),
                }))
            )
        })

        axios
            .get('/news/sources/')
            .then(res => {
                setAllSources(res.data)
            })
            .catch(err => console.log(err))

        axios
            .get('/news/industries-sectors-topics/')
            .then(res => {
                setAllHierarchy(res.data)
            })
            .catch(err => console.log(err))

        axios.get('/news/topics/').then(response => {
            let remappedTopics = response.data.map(item => {
                return { value: item.id, label: item.name }
            })
            remappedTopics = lo_.orderBy(remappedTopics, ['label'], ['asc'])

            setAllTopics(remappedTopics)
        })

        getAllCompanies()

        axios.get(`/news/all-regions/`).then(response => {
            setAllRegions(response.data)
        })

        axios.get(`/news/all-industries-sectors/`).then(response => {
            const updatedIndustries = []
            response.data.forEach(item => {
                if (item.nick_name) {
                    const tempObj = { ...item }
                    tempObj.label = item.nick_name
                    updatedIndustries.push(tempObj)
                } else {
                    updatedIndustries.push(item)
                }
            })
            setAllIndustries(updatedIndustries)
        })

        axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)
        })

        getAllFirmUsers()
    }, [])

    const getAllFirms = () => {
        axios.get('/analyst/firms/').then(response => {
            setAllFirms(
                response.data.firms.map(val => {
                    return {
                        label: val.label,
                        value: val.value,
                    }
                })
            )
        })
    }

    const getUsers = () => {
        axios.get('/endusers/firm-users/').then(res => {
            const filter = [userEmail]
            filter.forEach(email => {
                const existingUser = res.data.find(user => user.email === email)

                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }

                    res.data.push(newUser)
                }
            })
            res.data.sort((a, b) => (a.email === userEmail ? -1 : b.email === userEmail ? 1 : 0))

            setUsers(res.data)
        })
    }

    const getAllCollections = () => {
        axios.get('/news/collection/').then(response => {
            const data = response.data.collections.map(res => {
                return {
                    value: res.id,
                    label: res.name,
                    description: res.description,
                    users: res.users,
                    creator: res.creator,
                    firm: res.firm,
                    record_date: res.record_date,
                    is_active: res.is_active,
                    shared: res.shared,
                    thumbnail: res.thumbnail,
                }
            })

            const newCollection = { value: 'Add into New Newsletter', label: 'Add into New Newsletter' }

            const sortedOptions = [...data].sort((a, b) => a.label.localeCompare(b.label))
            sortedOptions.unshift(newCollection)

            setAllCollectionName(sortedOptions)
        })
    }

    const getAllCompanies = () => {
        axios.get(`/news/all-companies/`).then(response => {
            setAllCompanies(response.data)
        })
    }

    const getAllFirmUsers = async () => {
        await axios
            .get('/endusers/firm-users/')
            .then(res => {
                setAllFirmUsers(res.data)
            })
            .catch(err => console.log(err))
    }

    const handleTemplateSelected = (event, template) => {
        setSelectedTemplate(template)
        if (template !== null) {
            setIntroText(template.introtext)
            setSubjectLine(template.subject)
            setPrimaryColor(template.primary_color)
            setSecondaryColor(template.secondary_color)
            setTitle(template.title)
            setSelectedLogo(template.logo)
            setLogoManualImage(false)
            setFileName(template.logoname)
            setSecondaryLogo(template.secondary_logo)
            setLogo2ManualImage(false)
            setsecFileName(template.seclogoname)
            setBgManualImage(false)
            setBgFileName(template.bgname)
            setSelectedBg(template.background_image)
            // if (usertype === 'analyst') {
            setActiveStep(2)
            // }
        }
    }

    function resetPrimaryLogo() {
        setFileName('')
        setSelectedLogo('')
        setLogoFile(null)
        setLogoManualImage(false)
    }

    function resetSecondaryLogo() {
        setsecFileName('')
        setSecondaryLogo('')
        setLogo2File(null)
        setLogo2ManualImage(false)
    }

    const resetSteps = e => {
        // setSelectedStories([])
        setCategorizedNews()
        setSelectedTemplate()
        setSubjectLine()
        setIntroText()
        setActiveStep(0)
    }

    const setLogo = e => {
        if (e.target.files[0]) {
            setSelectedLogo(URL.createObjectURL(e.target.files[0]))
            setLogoFile(e.target.files[0])
            setLogoManualImage(true)
            setFileName(e.target.files[0].name)
        }
    }

    const setsecondaryLogo = e => {
        if (e.target.files[0]) {
            setSecondaryLogo(URL.createObjectURL(e.target.files[0]))
            setLogo2File(e.target.files[0])
            setLogo2ManualImage(true)
            setsecFileName(e.target.files[0].name)
        }
    }

    const setBackgroundImage = e => {
        if (e.target.files[0]) {
            setSelectedBg(URL.createObjectURL(e.target.files[0]))
            setBgFile(e.target.files[0])
            setBgManualImage(true)
            setBgFileName(e.target.files[0].name)
        }
    }

    const setSize = (width, height, logo) => {
        if (logo === 'primary') {
            setLogoSize({ width: width, height: height })
        }
        if (logo === 'secondary') {
            setSecLogoSize({ width: width, height: height })
        }
    }

    const setNewsCategory = data => {
        setCategorizedNews(data)
    }

    const uploadFile = async (fileToUpload, type) => {
        let formData = new FormData()
        formData.append('logo', fileToUpload)
        formData.append('name', fileToUpload.name)
        formData.append('type', type)
        axios
            .post('/news/upload/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
                if (res.status === 200) {
                    if (type === 'logo') {
                        setSelectedLogo(res.data.logo)
                        setLogoManualImage(false)
                        setLogoFile(null)
                    }
                    if (type === 'logo2') {
                        setSecondaryLogo(res.data.logo)
                        setLogo2ManualImage(false)
                        setLogo2File(null)
                    }
                    if (type === 'bg') {
                        setSelectedBg(res.data.logo)
                        setBgManualImage(false)
                        setBgFile(null)
                    }
                }
            })
    }

    useEffect(() => {
        async function uploadImages() {
            if (logoManualImage) {
                uploadFile(logoFile, 'logo')
            }
            if (bgManualImage) {
                uploadFile(bgFile, 'bg')
            }
            if (logo2ManualImage) {
                uploadFile(logo2File, 'logo2')
            }
        }
        if (activeStep === 3) {
            uploadImages()
        }
    }, [activeStep])

    let interval

    const premeetingBriefSummary = () => {
        setPremeetingModal(true)

        const all = []
        selectedStories.forEach(item => {
            all.push({ id: item.id, index_name: item.index_name })
        })
        const groupedDoc = {}

        all.forEach(item => {
            const { id, index_name } = item

            if (!groupedDoc[index_name]) {
                groupedDoc[index_name] = []
            }

            groupedDoc[index_name].push(id)
        })

        const doc = Object.entries(groupedDoc).map(([groupName, ids]) => ({
            [groupName]: ids,
        }))

        setTimeout(() => {
            setPremeetingLoader(true)
        }, 100)
        setTimeout(() => {
            const search = document.getElementById('pregenerating')
            if (search) {
                const searchPlaceholder = 'Generating...'
                let i = 0
                let placeholder = ''
                typing()
                function typing() {
                    clearInterval(interval)
                    interval = setInterval(() => {
                        if (search) {
                            placeholder += searchPlaceholder[i]
                            i++
                            search.value = placeholder
                            search.scrollTop = search.scrollHeight
                            if (i > searchPlaceholder.length - 1) {
                                clearInterval(interval)
                            }
                        }
                    }, 50)
                }
            }
        }, 500)

        axios
            // .get(`/company/premium?companyId=${companyId}&news=[{docs:${JSON.stringify(doc[0])}}]`)
            .post(`/news/premeeting/premium/`, { news_data: doc })
            .then(res => {
                setPremeetingResponse(res.data)
                setTimeout(() => {
                    const search = document.getElementById('premeeting_text')

                    if (search) {
                        const searchPlaceholder = res.data.summary
                        let placeholder = ''
                        let i = 0
                        clearInterval(interval)
                        interval = setInterval(() => {
                            if (search && i < searchPlaceholder.length) {
                                placeholder += searchPlaceholder[i]
                                i++
                                search.value = placeholder
                                search.scrollTop = search.scrollHeight
                            } else {
                                clearInterval(interval)
                            }
                        }, 10)
                    }
                }, 100)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setPremeetingLoader(false)
            })
    }

    //add & remove selection
    const addToSelection = story => {
        setSelectedStories([...selectedStories, story])
        setId(uuid())
    }

    const generateSummary = news => {
        setSummaryLoader(news)
    }

    const removeFromSelection = story => {
        let selectedCopy = selectedStories.filter(arr => arr.id !== story.id)
        setSelectedStories(selectedCopy)
        setId(uuid())
    }

    const handleLoadMore = () => {
        let data = {
            next: basicSearchResponse.next,
            size: basicSearchResponse.size,
            total: basicSearchResponse.total,
            scroll_id: basicSearchResponse.scroll_id,
        }
        axios.post('/news/scroll/', data).then(response => {
            let newsBefore = [...basicSearchResponse.data, ...response.data.data]
            setBasicSearchResponse({
                total: response.data.total,
                next: response.data.next,
                scroll_id: response.data.scroll_id,
                data: newsBefore,
            })
        })
    }

    //delete news
    const handleBatchDeleteRaw = () => {
        let idsToRemove = []
        let indexName = []
        let allIds = []
        const uniqueSelectedStories = new Set(selectedStories)
        const selectedLength = uniqueSelectedStories.size

        uniqueSelectedStories.forEach(item => {
            idsToRemove.push(item.doc_id)
            indexName.push(item.index_name)
            allIds.push({ id: item.id, index_name: item.index_name })
        })

        const groupedDocs = {}

        allIds.forEach(item => {
            const { id, index_name } = item

            if (!groupedDocs[index_name]) {
                groupedDocs[index_name] = []
            }

            groupedDocs[index_name].push(id)
        })

        const docs = Object.entries(groupedDocs).map(([groupName, ids]) => ({
            [groupName]: ids,
        }))

        axios
            .put(`/news/edit-publish-delete-news/`, { docs: docs })
            .then(res => {
                if (activeTab === 0) {
                    let findAndShareCopy = basicSearchResponse.data && [...basicSearchResponse.data]

                    let findAndShareFiltered = findAndShareCopy.filter(story => !idsToRemove.includes(story.doc_id))
                    basicSearchResponse.next = basicSearchResponse.next - selectedLength
                    basicSearchResponse.total = basicSearchResponse.total - selectedLength
                    setBasicSearchResponse({ ...basicSearchResponse, data: findAndShareFiltered })
                    setSelectedStories([])
                } else {
                    let findAndShareCopy1 = [...advanceResponse.data.data]
                    let findAndShareFiltered1 = findAndShareCopy1.filter(story => !idsToRemove.includes(story.doc_id))
                    setAdvanceResponse({
                        ...advanceResponse,
                        data: {
                            total: advanceResponse.data.total - selectedLength,
                            next: advanceResponse.data.next - selectedLength,
                            scroll_id: advanceResponse.data.scroll_id,
                            data: findAndShareFiltered1,
                        },
                    })
                }
                setDeleteModalOpen(false)
                setSelectedStories([])
                toast('News Deleted')
            })
            .catch(err => {
                console.log(err)
                setDeleteModalOpen(false)
                setSelectedStories([])
            })
    }

    //handle published news

    const handlePublishedNews = () => {
        let allStory = []
        let idsToFilter = []
        const uniqueSelectedStories = new Set(selectedStories)
        const selectedLength = uniqueSelectedStories.size

        uniqueSelectedStories.forEach(item => {
            idsToFilter.push(item.id)

            let newsObj = {
                id: item.id,
                published_title: item.title,
                published_snippet: item.snippet,
                published_subcategories: item.subcategories,
                published_industries: item.industries,
                published_sectors: item.sectors,
                published_companies: item.companies,
                published_countries: item.countries,
                published_topics: item.topics,
                index_name: item.index_name,
                published: true,
                discarded: false,
                is_publish: true,
            }
            allStory.push({ doc: newsObj })
        })
        axios
            .post(`/news/edit-publish-delete-news/`, {
                news: allStory,
            })
            .then(response => {
                toast('Story published')
                if (activeTab === 0) {
                    let findAndShareCopy = basicSearchResponse.data && [...basicSearchResponse.data]

                    let findAndShareFiltered = findAndShareCopy.filter(story => !idsToFilter.includes(story.id))
                    basicSearchResponse.next = basicSearchResponse.next - selectedLength
                    basicSearchResponse.total = basicSearchResponse.total - selectedLength

                    setBasicSearchResponse({ ...basicSearchResponse, data: findAndShareFiltered })
                    setSelectedStories([])
                } else {
                    let findAndShareCopy1 = [...advanceResponse.data.data]
                    let findAndShareFiltered1 = findAndShareCopy1.filter(story => !idsToFilter.includes(story.id))
                    setAdvanceResponse({
                        ...advanceResponse,
                        data: {
                            total: advanceResponse.data.total - selectedLength,
                            next: advanceResponse.data.next - selectedLength,
                            scroll_id: advanceResponse.data.scroll_id,
                            data: findAndShareFiltered1,
                        },
                    })
                    setSelectedStories([])
                }
            })
            .catch(err => {
                setSelectedStories([])
                toast('Error in publishing story')
            })
    }

    return (
        <BaseScreen>
            {
                <DeleteNews
                    deleteModalOpen={deleteModalOpen}
                    setDeleteModalOpen={setDeleteModalOpen}
                    selectedStoriesLength={[...new Set(selectedStories)].length}
                    handleBatchDeleteStories={handleBatchDeleteRaw}
                />
            }
            {usertype !== 'analyst' && activeStep !== 0 && (
                <div style={{ width: '98%', margin: 'auto', marginBottom: '10px' }}>
                    <div class="d-flex align-items-center justify-content-between">
                        {/* <div className="ml-2">
                            <label style={{ fontSize: '20px' }}>
                                {activeStep === 1
                                    ? 'Share Newsletter'
                                    : activeStep === 2
                                    ? 'Preview Editor'
                                    : activeStep === 3
                                    ? 'Select Users'
                                    : 'Newsletter Sent'}
                            </label>
                        </div> */}
                        <div />

                        <div
                            style={{
                                marginLeft: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                variant="contained"
                                startIcon={<MdNavigateBefore />}
                                onClick={() => {
                                    setActiveStep(activeStep => activeStep - 1)
                                    activeStep === 2 && setIncludeSection(false)
                                }}
                                style={{
                                    background: '#cfcfcf00',
                                    color: 'gray',
                                    boxShadow: 'none',
                                    borderRadius: '2px',
                                    marginRight: '20px',
                                }}
                            >
                                Back
                            </Button>

                            {activeStep !== 3 && activeStep !== 4 && activeStep !== 1 && (
                                <Button
                                    variant="contained"
                                    endIcon={<MdNavigateNext />}
                                    onClick={() => {
                                        setActiveStep(activeStep => activeStep + 1)
                                    }}
                                    disabled={
                                        selectedTemplate === undefined ||
                                        selectedTemplate === null ||
                                        selectedTemplate === ''
                                            ? true
                                            : false
                                    }
                                    style={{
                                        background: '#186ADE',
                                        color: 'white',
                                        borderRadius: '2px',
                                        opacity:
                                            selectedTemplate === undefined ||
                                            selectedTemplate === null ||
                                            selectedTemplate === ''
                                                ? 0.6
                                                : 1,
                                    }}
                                >
                                    {activeStep === 3 ? 'Send' : 'Next'}
                                </Button>
                            )}
                        </div>
                    </div>
                    {/* <hr /> */}
                </div>
            )}
            {activeStep === 0 && (
                <>
                    {usertype === 'analyst' && activeTab !== 4 && activeTab !== 5 && activeTab !== 2 && (
                        <div className="sendAndDeleteContainer">
                            <button
                                title={apiLevel === 'level1' ? 'Publish to Platform' : 'Send Newsletter'}
                                className="sendButton"
                                onClick={
                                    apiLevel === 'level2' || usertype === 'Regular User'
                                        ? () => {
                                              setActiveStep(1)
                                          }
                                        : handlePublishedNews
                                }
                                disabled={
                                    selectedStories.length === 0 ||
                                    (props.location.pathname === '/app/raw-search/' && selectedLevel === 'level0')
                                }
                                style={{
                                    marginBottom: usertype !== 'Regular User' ? '10px' : '15px',
                                    background: '#0a294f',
                                }}
                            >
                                <img
                                    src={apiLevel === 'level1' ? PublishIcon : SendIcon}
                                    alt="send"
                                    className={
                                        selectedStories.length === 0 ||
                                        (props.location.pathname === '/app/raw-search/' && selectedLevel === 'level0')
                                            ? 'buttonIconFaded'
                                            : ''
                                    }
                                />
                            </button>

                            <button
                                title="Pre-meeting brief"
                                className="deleteButton"
                                style={{
                                    marginBottom: '0px',
                                    background: 'rgb(14, 74, 147)',
                                }}
                                onClick={premeetingBriefSummary}
                                disabled={selectedStories.length === 0}
                            >
                                <CgNotes
                                    style={{ fontSize: '24', color: 'white' }}
                                    className={selectedStories.length === 0 ? 'buttonIconFaded' : ''}
                                />
                            </button>

                            <button
                                title="Delete News"
                                className="deleteButton"
                                style={{
                                    marginTop: '10px',
                                    display:
                                        usertype === 'Regular User' || usertype === 'Power User' ? 'none' : 'block',
                                }}
                                disabled={selectedStories.length === 0}
                                onClick={() => setDeleteModalOpen(true)}
                            >
                                <AiOutlineDelete
                                    style={{ fontSize: '24', color: 'white' }}
                                    className={selectedStories.length === 0 ? 'buttonIconFaded' : ''}
                                />
                            </button>
                        </div>
                    )}

                    {usertype !== 'analyst' ? (
                        <NewSearchSection
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            selectedStories={selectedStories}
                            setSelectedStories={setSelectedStories}
                            basicSearchResponse={basicSearchResponse}
                            setBasicSearchResponse={setBasicSearchResponse}
                            isQueryEditor={isQueryEditor}
                            setIsQueryEditor={setIsQueryEditor}
                            isQueryEditorJson={isQueryEditorJson}
                            setIsQueryEditorJson={setIsQueryEditorJson}
                            userEmail={userEmail}
                            userRole={userRole}
                            allIndustries={allIndustries}
                            setAllIndustries={setAllIndustries}
                            allCompanies={allCompanies}
                            setAllCompanies={setAllCompanies}
                            allRegions={allRegions}
                            setAllRegions={setAllRegions}
                            allLinkedInGroups={allLinkedInGroups}
                            allTopics={allTopics}
                            setAllTopics={setAllTopics}
                            allCategories={allCategories}
                            setAllCategories={setAllCategories}
                            allSubCategories={allSubCategories}
                            setAllSubCategories={setAllSubCategories}
                            allSources={allSources}
                            setAllSources={setAllSources}
                            allHierarchy={allHierarchy}
                            setAllHierarchy={setAllHierarchy}
                            practiceSubCategory={practiceSubCategory}
                            setPracticeSubCategory={setPracticeSubCategory}
                            handleLoadMore={handleLoadMore}
                            advanceResponse={advanceResponse}
                            setAdvanceResponse={setAdvanceResponse}
                            addToSelection={addToSelection}
                            removeFromSelection={removeFromSelection}
                            premeetingModel={premeetingModel}
                            setPremeetingModal={setPremeetingModal}
                            selectedCompanies={selectedCompanies}
                            setSelectedCompanies={setSelectedCompanies}
                            PremeetingResponse={PremeetingResponse}
                            setPremeetingResponse={setPremeetingResponse}
                            premeetingLoader={premeetingLoader}
                            setPremeetingLoader={setPremeetingLoader}
                            regionHierarchy={regionHierarchy}
                            setRegionHierarchy={setRegionHierarchy}
                            apiLevel={apiLevel}
                            chat={chat}
                            setChat={setChat}
                            getAllCompanies={getAllCompanies}
                            selectedSectors={selectedSectors}
                            setSelectedSectors={setSelectedSectors}
                            id={id}
                            setId={setId}
                            selectedLevel={selectedLevel}
                            setSelectedLevel={setSelectedLevel}
                            allCollectionName={allCollectionName}
                            setAllCollectionName={setAllCollectionName}
                            getAllCollections={getAllCollections}
                            allCollectionUsers={users}
                            setAllCollectionUsers={setUsers}
                            setActiveStep={setActiveStep}
                            subjectLine={subjectLine}
                            setSubjectLine={setSubjectLine}
                            quickSummary={quickSummary}
                            setQuickSummary={setQuickSummary}
                            showFullText={showFullText}
                            setShowFullText={setShowFullText}
                            searchName={searchName}
                            setSearchName={setSearchName}
                            generateSummary={generateSummary}
                            summaryLoader={summaryLoader}
                            overallSummaryLoader={overallSummaryLoader}
                            setOverallSummaryLoader={setOverallSummaryLoader}
                        />
                    ) : (
                        <SearchUtility
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            selectedStories={selectedStories}
                            setSelectedStories={setSelectedStories}
                            basicSearchResponse={basicSearchResponse}
                            setBasicSearchResponse={setBasicSearchResponse}
                            isQueryEditor={isQueryEditor}
                            setIsQueryEditor={setIsQueryEditor}
                            isQueryEditorJson={isQueryEditorJson}
                            setIsQueryEditorJson={setIsQueryEditorJson}
                            userEmail={userEmail}
                            userRole={userRole}
                            allIndustries={allIndustries}
                            setAllIndustries={setAllIndustries}
                            allCompanies={allCompanies}
                            setAllCompanies={setAllCompanies}
                            allRegions={allRegions}
                            setAllRegions={setAllRegions}
                            allLinkedInGroups={allLinkedInGroups}
                            allTopics={allTopics}
                            setAllTopics={setAllTopics}
                            allCategories={allCategories}
                            setAllCategories={setAllCategories}
                            allSubCategories={allSubCategories}
                            setAllSubCategories={setAllSubCategories}
                            allSources={allSources}
                            setAllSources={setAllSources}
                            allHierarchy={allHierarchy}
                            setAllHierarchy={setAllHierarchy}
                            practiceSubCategory={practiceSubCategory}
                            setPracticeSubCategory={setPracticeSubCategory}
                            handleLoadMore={handleLoadMore}
                            advanceResponse={advanceResponse}
                            setAdvanceResponse={setAdvanceResponse}
                            addToSelection={addToSelection}
                            removeFromSelection={removeFromSelection}
                            premeetingModel={premeetingModel}
                            setPremeetingModal={setPremeetingModal}
                            selectedCompanies={selectedCompanies}
                            setSelectedCompanies={setSelectedCompanies}
                            PremeetingResponse={PremeetingResponse}
                            setPremeetingResponse={setPremeetingResponse}
                            premeetingLoader={premeetingLoader}
                            setPremeetingLoader={setPremeetingLoader}
                            regionHierarchy={regionHierarchy}
                            setRegionHierarchy={setRegionHierarchy}
                            apiLevel={apiLevel}
                            chat={chat}
                            setChat={setChat}
                            getAllCompanies={getAllCompanies}
                            selectedSectors={selectedSectors}
                            setSelectedSectors={setSelectedSectors}
                            id={id}
                            setId={setId}
                            selectedLevel={selectedLevel}
                            setSelectedLevel={setSelectedLevel}
                            allCollectionName={allCollectionName}
                            setAllCollectionName={setAllCollectionName}
                            getAllCollections={getAllCollections}
                            allCollectionUsers={users}
                            setAllCollectionUsers={setUsers}
                            setActiveStep={setActiveStep}
                            generateSummary={generateSummary}
                        />
                    )}
                </>
            )}
            {usertype === 'analyst' && activeStep !== 0 && (
                <>
                    <Steppers
                        activeStep={activeStep}
                        steps={[
                            ['Select News', 'Select Newsletter Template', 'Template Preview Editor', 'Mail Recipients'],
                        ]}
                        isSearchPage={true}
                        setIncludeSection={setIncludeSection}
                    />
                    <StepperButton
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        count={selectedStories.length}
                        template={selectedTemplate}
                        resetSteps={resetSteps}
                        isSearchPage={true}
                        setIncludeSection={setIncludeSection}
                    />
                </>
            )}
            {activeStep === 1 && (
                <>
                    <SelectTemplate
                        selectedTemplate={selectedTemplate}
                        handleTemplateSelected={handleTemplateSelected}
                        usertype={usertype}
                    />
                </>
            )}
            {activeStep === 2 && (
                <>
                    <TemplatePreview
                        selectedStories={selectedStories}
                        selectedTemplate={selectedTemplate}
                        setContent={setContent}
                        subjectLine={subjectLine}
                        setSubjectLine={setSubjectLine}
                        title={title}
                        setTitle={setTitle}
                        introText={introText}
                        setIntroText={setIntroText}
                        hyperlink={setHyperlink}
                        setHyperlink={setHyperlink}
                        primaryColor={primaryColor}
                        setPrimaryColor={setPrimaryColor}
                        secondaryColor={secondaryColor}
                        setSecondaryColor={setSecondaryColor}
                        setLogo={setLogo}
                        resetPrimaryLogo={resetPrimaryLogo}
                        logoFileName={logoFileName}
                        secondaryLogo={secondaryLogo}
                        setSecondaryLogo={setsecondaryLogo}
                        resetSecondaryLogo={resetSecondaryLogo}
                        setSize={setSize}
                        seclogoFileName={seclogoFileName}
                        setBackgroundImage={setBackgroundImage}
                        bgFileName={bgFileName}
                        selectedLogo={selectedLogo}
                        selectedBg={selectedBg}
                        categorizedNews={categorizedNews}
                        setNewsCategory={setNewsCategory}
                        usertype={usertype}
                        isSearchComponent={true}
                        includeSection={includeSection}
                        setIncludeSection={setIncludeSection}
                        includeBusiness={includeBusiness}
                        setIncludeBusiness={setIncludeBusiness}
                        hyperlinkTitle={hyperlinkTitle}
                        setHyperLinkTitle={setHyperLinkTitle}
                        hyperlinkSource={hyperlinkSource}
                        setHyperLinkSource={setHyperLinkSource}
                    />
                </>
            )}
            {activeStep == 3 && (
                <>
                    <SendNewsLetter
                        selectedTemplate={selectedTemplate}
                        selectedStories={selectedStories}
                        allCategories={allCategories}
                        allIndustries={allIndustries}
                        allCompanies={allCompanies}
                        allFirmUsers={allFirmUsers}
                        allRegions={allRegions}
                        allTopics={allTopics}
                        allFirms={allFirms}
                        categorizedNews={categorizedNews}
                        subjectLine={subjectLine}
                        introText={introText}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        title={title}
                        selectedLogo={selectedLogo}
                        secondaryLogo={secondaryLogo}
                        selectedBg={selectedBg}
                        logoSize={logoSize}
                        secLogoSize={secLogoSize}
                        hyperlink={hyperlink}
                        content={content}
                        usertype={usertype}
                        includeSection={includeSection}
                        includeBusiness={includeBusiness}
                        hyperlinkTitle={hyperlinkTitle}
                        setHyperLinkTitle={setHyperLinkTitle}
                        hyperlinkSource={hyperlinkSource}
                        setHyperLinkSource={setHyperLinkSource}
                        setActiveStep={setActiveStep}
                        sentUsers={sentUsers}
                        setSentUsers={setSentUsers}
                    />
                </>
            )}

            {usertype !== 'analyst' && activeStep === 4 && (
                <ScheduleSucessMsg subjectLine={subjectLine} setActiveStep={setActiveStep} sentUsers={sentUsers} />
            )}
        </BaseScreen>
    )
}
