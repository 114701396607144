import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Grid, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import store from '../../../Store'

export default function SelectTemplate(props) {
    const { selectedTemplate, handleTemplateSelected, usertype } = props
    const [allTemplates, setAllTemplates] = useState([])
    const msg = store.getState().auth.user.firm_id

    useEffect(() => {
        axios.get('/endusers/templates/').then(response => {
            setAllTemplates(response.data)
        })
    }, [])

    return (
        <Grid container direction="column" style={{ paddingRight: '1rem' }}>
            <Grid item>
                <Paper
                    style={{
                        padding: usertype === 'analyst' ? '1rem' : '',
                        minHeight: '75vh',
                        background: 'rgba(207, 207, 207, 0)',
                        boxShadow: 'none',
                    }}
                >
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={selectedTemplate}
                        exclusive
                        onChange={handleTemplateSelected}
                        style={{ width: '100%' }}
                    >
                        {allTemplates.map(template => {
                            return (
                                <ToggleButton
                                    value={template}
                                    aria-label="list"
                                    style={{
                                        margin: '0.5rem',
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: '4px',
                                    }}
                                    key={template.id}
                                >
                                    <Grid container direction="row" spacing={2}>
                                        <Grid
                                            item
                                            xs={4}
                                            style={{
                                                maxHeight: '10rem',
                                                maxWidth: '10rem',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <img src={template.thumbnail} style={{ maxWidth: '100%' }} alt="thumbail" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <h2 align="left" style={{ color: '#1c2b3e' }}>
                                                        {template.name}
                                                    </h2>
                                                </Grid>
                                                {/* <Grid item>
                                                    <h6 align="left">{template.description} </h6>
                                                    {selectedTemplate === template && (
                                                        <p align="right">
                                                            <CheckCircleIcon color="success" />
                                                        </p>
                                                    )}
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ToggleButton>
                            )
                        })}
                        {allTemplates.length === 0 && (
                            <div
                                style={{
                                    width: '98%',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    padding: '20px',
                                    marginTop: '30px',
                                    fontSize: '20px',
                                }}
                            >
                                {msg === null
                                    ? "No templates are available because you don't have a firm"
                                    : 'No Templates are available for this Firm'}
                            </div>
                        )}
                    </ToggleButtonGroup>
                </Paper>
            </Grid>
        </Grid>
    )
}
