// import React, { useEffect, useState } from 'react'
// import { getFullFormattedDate } from '../../../utils/dateHelpers'
// import { FaLinkedin } from 'react-icons/fa'
// import twitterX from '../../../assets/icons/twitterx-blue.svg'

// export default function ChatRecommendationCards(props) {
//     const { val, isSocial } = props

//     const [posts, setPosts] = useState([])
//     useEffect(() => {
//         setPosts(val.linkedin)
//     }, [val.linkedin])

//     return (
//         <div style={{ width: '100%' }}>
//             <div
//                 className={'card'}
//                 style={{
//                     height: 'auto',
//                     border: '1px solid lightgray',
//                 }}
//             >
//                 <div
//                     className={'card-body'}
//                     style={{
//                         overflow: 'hidden',
//                         maxHeight: '400px',
//                         minHeight: '400px',
//                         textOverflow: 'ellipsis',
//                         whiteSpace: 'normal',
//                         padding: '',
//                         marginTop: '',
//                     }}
//                 >
//                     <div className="d-flex justify-content-between" style={{ marginBottom: '-8px' }}>
//                         <a
//                             href={val.news_url ? val.news_url : ''}
//                             style={{ textDecoration: 'none', width: '50%' }}
//                             target="_blank"
//                         >
//                             <div
//                                 title={val.source}
//                                 style={{
//                                     textDecoration: 'underline',
//                                     color: 'black',
//                                     fontSize: '14px',
//                                     overflow: 'hidden',
//                                     display: '-webkit-box',
//                                     WebkitLineClamp: 1,
//                                     WebkitBoxOrient: 'vertical',
//                                     width: '100%',
//                                 }}
//                             >
//                                 {val.source.toUpperCase()}
//                             </div>
//                         </a>
//                         <div style={{ color: 'lightgray', fontSize: '14px' }}>{getFullFormattedDate(val.date)}</div>
//                     </div>
//                     <a href={val.news_url} style={{ color: '#3048A6' }} target="_blank" title={val.title}>
//                         <h4
//                             className="card-title"
//                             style={{
//                                 cursor: 'pointer',
//                                 overflow: 'hidden',
//                                 display: '-webkit-box',
//                                 WebkitLineClamp: 2,
//                                 WebkitBoxOrient: 'vertical',
//                                 fontSize: '17px',
//                                 marginTop: '',
//                                 color: '#3048A6',
//                             }}
//                         >
//                             {val.title}
//                         </h4>
//                     </a>

//                     <textarea
//                         style={{
//                             height: '300px',
//                             width: '100%',
//                             resize: 'none',
//                             border: '2px solid lightgray',
//                             outline: 'none',
//                             borderRadius: '2px',
//                             minHeight: '300px',
//                         }}
//                         value={posts}
//                         onChange={e => setPosts(e.target.value)}
//                     />
//                 </div>

//                 <div
//                     className="card-footer"
//                     style={{
//                         marginTop: 'auto',
//                         width: '100%',
//                         borderTop: '0px',
//                         background: 'white',
//                         padding: '0rem 1rem 1rem 1rem',
//                     }}
//                 >
//                     {isSocial ? (
//                         <div
//                             className="CompanyNewsCard__container"
//                             style={{
//                                 marginTop: '',
//                                 boxShadow: 'none',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'space-between',
//                             }}
//                         >
//                             <span style={{ fontSize: '14px' }}>Post on :</span>
//                             <a
//                                 href={`http://www.linkedin.com/share?mini=true&title=${encodeURIComponent(
//                                     val.title
//                                 )}%0D&text=${encodeURIComponent(posts)}%0D${encodeURIComponent(val.news_url)}`}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
//                             >
//                                 <span
//                                     style={{
//                                         cursor: 'pointer',
//                                         border: '2px solid lightgray',
//                                         padding: '5px',
//                                         borderRadius: '5px',
//                                         fontSize: '14px',
//                                         fontWeight: 600,
//                                     }}
//                                 >
//                                     LinkedIn
//                                     <FaLinkedin
//                                         style={{
//                                             marginTop: '-5px',
//                                             color: '#197bbd',
//                                             fontSize: '18px',
//                                         }}
//                                     />
//                                 </span>
//                             </a>
//                             <a
//                                 href={`http://twitter.com/share?&title=${encodeURIComponent(
//                                     val.title
//                                 )}%0D&url=${encodeURIComponent(val.news_url)}%0D&text=${encodeURIComponent(posts)}`}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
//                             >
//                                 <span
//                                     style={{
//                                         cursor: 'pointer',
//                                         border: '2px solid lightgray',
//                                         padding: '5px',
//                                         borderRadius: '5px',
//                                         fontSize: '14px',
//                                         fontWeight: 600,
//                                     }}
//                                 >
//                                     Twitter
//                                     <img
//                                         src={twitterX}
//                                         style={{
//                                             marginTop: '-5px',
//                                             width: '18px',
//                                         }}
//                                         alt="twitter"
//                                     />
//                                 </span>
//                             </a>
//                         </div>
//                     ) : (
//                         <div
//                             className="CompanyNewsCard__container"
//                             style={{
//                                 marginTop: '',
//                                 boxShadow: 'none',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'space-between',
//                             }}
//                         >
//                             Edit
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     )
// }
import React, { useEffect, useState } from 'react'
import { getFullFormattedDate } from '../../../utils/dateHelpers'
import { FaLinkedin } from 'react-icons/fa'
import twitterX from '../../../assets/icons/twitterx-blue.svg'
import logger from 'redux-logger'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button } from '@mui/material'
import axios from 'axios'

export default function ChatRecommendationCards(props) {
    const { val, isSocial, expandedView, setExpandedView, index } = props

    const [posts, setPosts] = useState('')
    const [snippet, setSnippet] = useState(val.snippet)
    const [loader, setLoader] = useState(false)
    let retryCount = 0

    // useEffect(() => {
    //     setPosts(val.post)
    // }, [val.post])

    const generatePosts = (title, snippet) => {
        const payload = {
            news_content: [title + ' - ' + snippet],
        }

        return axios.post(`/core/generate-social-post/`, payload)
    }

    const generateSummary = (index, id, snippet) => {
        const payload = {
            id: id,
            snippet: snippet,
        }

        return axios.post('/core/summarise-article/', payload)
    }

    const generateContent = () => {
        setLoader(true)

        const promises = [generatePosts(val.title, val.snippet)]

        // Add generateSummary only if index includes 'level0'
        if (val.index_name && val.index_name.includes('level0')) {
            promises.push(generateSummary(val.index_name, val.id, val.snippet))
        }

        Promise.all(promises)
            .then(responses => {
                const postsResponse = responses[0]
                const summaryResponse = responses[1]

                const postsData = postsResponse.data
                if (postsData && postsData.Post) {
                    setPosts(postsData.Post)
                    setLoader(false)
                } else {
                    // Retry logic for generatePosts
                    if (retryCount < 1) {
                        retryCount += 1
                        generateContent()
                        setLoader(true)
                    } else {
                        retryCount = 0
                        setLoader(false)
                    }
                }

                if (summaryResponse) {
                    setSnippet(summaryResponse.data)
                }
            })
            .catch(err => {
                setLoader(false)
                console.log(err)
            })
    }

    useEffect(() => {
        if (index === 0) {
            setCollapsed(!collapsed)
            generateContent()
        }
    }, [])

    const [collapsed, setCollapsed] = useState(expandedView)

    const collapsedSection = val => {
        return (
            <div
                className="d-flex align-items-center justify-content-between"
                style={{
                    width: '100%',
                    borderRadius: '3px',
                    marginBottom: '1px',
                    background: 'white',
                    padding: '1rem',
                }}
            >
                <div
                    style={{
                        textAlign: 'left',
                        width: 'max-content',
                    }}
                    className="font"
                >
                    <div style={{ color: '#415a77' }} className="">
                        {getFullFormattedDate(val.date)}
                    </div>
                </div>

                <div
                    className="newsCardCollapsed__title"
                    style={{
                        width: '50%',
                        textAlign: 'left',
                    }}
                    onClick={() => {
                        setCollapsed(!collapsed)
                        if (collapsed) {
                            generateContent()
                        }
                    }}
                >
                    <div
                        style={{
                            fontWeight: '600',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            width: '95%',
                            color: '#0D4EA6',
                            cursor: 'pointer',
                        }}
                        className="font"
                        title={val.title}
                    >
                        {val.title}
                    </div>
                </div>

                <div
                    className="newsCardCollapsed_source font d-none d-sm-block"
                    style={{
                        textAlign: 'left',
                    }}
                >
                    {((val.source !== null && val.source !== '') ||
                        (val.source_name !== null && val.source_name !== '')) && (
                        <a
                            href={
                                val.source && val.source.includes('http')
                                    ? val.source
                                    : val.news_url
                                    ? val.news_url
                                    : ''
                            }
                            style={{ color: 'black' }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div
                                style={{
                                    color: 'black',
                                    marginBottom: '0rem',
                                    textAlign: 'left',
                                    overflow: 'hidden',
                                }}
                            >
                                {val.source_name
                                    ? val.source_name
                                    : val.source
                                          .match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim)[0]
                                          .replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim, '')}
                            </div>
                        </a>
                    )}
                </div>

                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setCollapsed(!collapsed)
                        if (collapsed) {
                            generateContent()
                        }
                    }}
                >
                    {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </div>
            </div>
        )
    }

    if (collapsed) {
        return <>{collapsedSection(val)}</>
    }

    return (
        <div style={{ width: '100%' }}>
            <div
                className={'card'}
                style={{
                    height: 'auto',
                    border: '1px solid whitesmoke',
                }}
            >
                <div
                    style={{
                        padding: '',
                        marginTop: '',
                        width: '100%',
                    }}
                >
                    {collapsedSection(val)}

                    <div style={{ background: 'whitesmoke' }}>
                        {loader ? (
                            <div
                                style={{
                                    padding: '15px',
                                    borderRadius: '2px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    height: '100px',
                                    justifyContent: 'center',
                                    border: '2px solid black',
                                    paddingTop: '35px',
                                    margin: '5px',
                                }}
                                className="font"
                            >
                                <span className="round_loader" />{' '}
                                <span style={{ marginLeft: '10px' }} className="font">
                                    Generating Social Media Post...
                                </span>
                            </div>
                        ) : (
                            <div>
                                {val.snippet && (
                                    <div className="snippet" style={{ padding: '0rem 1rem' }}>
                                        <label className="font" style={{ marginTop: '10px' }}>
                                            Article Summary
                                        </label>
                                        <div
                                            style={{
                                                marginTop: '10px',
                                                // display: '-webkit-box',
                                                // WebkitLineClamp: 2,
                                                // WebkitBoxOrient: 'vertical',
                                                // overflow: 'hidden',
                                            }}
                                            className="font"
                                        >
                                            {snippet}
                                        </div>
                                    </div>
                                )}

                                <div style={{ padding: '0rem 1rem' }}>
                                    <label style={{ marginTop: '10px' }} className="font">
                                        AI Generated Post
                                    </label>

                                    <textarea
                                        style={{
                                            height: '100px',
                                            width: '100%',
                                            resize: 'none',
                                            border: '2px solid black',
                                            outline: 'none',
                                            borderRadius: '2px',
                                            minHeight: '100px',
                                            marginTop: '10px',
                                            background: 'whitesmoke',
                                        }}
                                        className="font"
                                        value={posts}
                                        onChange={e => setPosts(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                        <div
                            className="card-footer"
                            style={{
                                marginTop: 'auto',
                                width: '100%',
                                borderTop: '0px',
                                background: 'whitesmoke',
                                padding: '0rem 1rem 1rem 1rem',
                            }}
                        >
                            <div
                                className="CompanyNewsCard__container"
                                style={{
                                    marginTop: '',
                                    boxShadow: 'none',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div className="font">
                                    We've crafted a ready to go post for you.  When you click Approve, you will be
                                    redirected to LinkedIn, can edit your post if required and post.
                                </div>
                                {/* <Button
                                    variant="contained"
                                    style={{
                                        cursor: 'pointer',
                                        color: 'black',
                                        borderRadius: '5px',
                                        fontWeight: 500,
                                        background: 'none',
                                        textTransform: 'none',
                                        textDecoration: 'underline',
                                        boxShadow: 'none',
                                        marginRight: '5px',
                                    }}
                                    className="font"
                                    onClick={() => {
                                        generateContent()
                                    }}
                                >
                                    Regenerate
                                </Button> */}

                                <Button
                                    variant="contained"
                                    style={{
                                        cursor: 'pointer',
                                        // padding: '5px',
                                        borderRadius: '5px',
                                        fontWeight: 500,
                                        background: '#186ADE',
                                        textTransform: 'none',
                                        opacity: loader ? 0.6 : 1,
                                        color: 'white',
                                    }}
                                    className="font"
                                    endIcon={<FaLinkedin />}
                                    disabled={loader}
                                >
                                    <a
                                        href={`http://www.linkedin.com/share?mini=true&title=${encodeURIComponent(
                                            val.title
                                        )}%0D&text=${encodeURIComponent(posts)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            textDecoration: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'white',
                                        }}
                                    >
                                        Approve
                                    </a>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
