import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { getTrans } from '../../../utils'
import { redirectUser } from '../../../utils/handleRedirects'
import { IoMdSettings } from 'react-icons/io'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import TopicIcon from '@mui/icons-material/Topic'
import BusinessIcon from '@mui/icons-material/Business'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
class EnduserSidebar extends Component {
    state = {
        userRole: this.props.user.role,
        planType: this.props.user.plan_type,
        allowedPages: this.props.user.allowed_pages,
        urlCheck: false,
    }

    componentDidMount() {
        this.checkAllowedPage()
    }

    checkAllowedPage() {
        let currentPath = window.location.pathname

        if (currentPath === '/app/recommendations/') {
            currentPath = 'My Recommendations'
        } else if (currentPath === '/app/search/') {
            currentPath = 'Find and Share'
        } else if (currentPath === '/app/preferences/') {
            currentPath = 'My Preferences'
        } else if (currentPath === '/app/companies/') {
            currentPath = 'My Companies'
        } else if (currentPath === '/app/sectors/') {
            currentPath = 'My Topics'
        } else if (currentPath === '/app/competitors/') {
            currentPath = 'My Competitors'
        } else if (currentPath === '/app/assistant/') {
            currentPath = 'Insights Assistant'
        } else if (currentPath === '/app/request/') {
            currentPath = 'My Request'
        } else if (currentPath === '/app/dashboard/') {
            currentPath = 'Dashboard'
        }

        if (this.state.allowedPages.includes(currentPath) && this.state.urlCheck === false) {
            this.setState({ urlCheck: true })
        } else {
            this.setState({ urlCheck: false })
            redirectUser(this.props.user, this.props.history, this.props)
        }
    }

    render() {
        const isPageAllowed = page => this.state.allowedPages.indexOf(page) !== -1

        return (
            <div>
                {isPageAllowed('Insights Assistant') && (
                    <NavLink to="/app/assistant/" className="nav-item" id="assistant_navlink">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <AutoAwesomeOutlinedIcon />
                            </span>
                            <span>{getTrans('Insights Assistant')}</span>
                        </div>
                    </NavLink>
                )}
                {isPageAllowed('My Request') && (
                    <NavLink to="/app/request/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <DashboardOutlinedIcon />
                            </span>
                            <span>{getTrans('My Requests')}</span>
                        </div>
                    </NavLink>
                )}
                {/* {isPageAllowed('Dashboard') && (
                    <NavLink to="/app/dashboard/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <DashboardOutlinedIcon />
                            </span>
                            <span>{getTrans('Dashoard')}</span>
                        </div>
                    </NavLink>
                )} */}
                {isPageAllowed('My Recommendations') && (
                    <NavLink to="/app/recommendations/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <DashboardOutlinedIcon />
                            </span>
                            <span>{getTrans('My')}</span>
                            <span style={{ marginLeft: '5px' }}>{getTrans('Recommendations')}</span>
                        </div>
                    </NavLink>
                )}

                {isPageAllowed('Find and Share') && (
                    <NavLink to="/app/search/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <ManageSearchIcon />
                            </span>
                            <span>{getTrans('Find and Share')}</span>
                        </div>
                    </NavLink>
                )}
                {isPageAllowed('My Topics') && (
                    <NavLink
                        to="/app/sectors/"
                        className={
                            window.location.href.includes('/app/sectors/')
                                ? 'nav-item active flex-between'
                                : 'nav-item flex-between'
                        }
                    >
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <TopicIcon />
                            </span>
                            <span>{getTrans('My Topics')}</span>
                        </div>
                    </NavLink>
                )}
                {isPageAllowed('My Companies') && (
                    <NavLink
                        to="/app/companies/"
                        className={this.props.match.url.includes('/app/companies/') ? 'nav-item active' : 'nav-item'}
                    >
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <BusinessIcon />
                            </span>
                            <span>{getTrans('My Companies')}</span>
                        </div>
                    </NavLink>
                )}
                {isPageAllowed('My Competitors') && (
                    <NavLink
                        to="/app/competitors/"
                        className={
                            window.location.href.includes('/app/competitors/')
                                ? 'nav-item active flex-between'
                                : 'nav-item flex-between'
                        }
                    >
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <BusinessIcon />
                            </span>
                            <span>{getTrans('My Competitors')}</span>
                        </div>
                    </NavLink>
                )}
                {/* <hr style={{ margin: '0px 0px 0px 10px', color: 'black', height: '1.5px' }} /> */}
                {isPageAllowed('My Preferences') && (
                    <NavLink to="/app/preferences/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <AccountCircleIcon />
                            </span>
                            <span>{getTrans('My Preferences')}</span>
                        </div>
                    </NavLink>
                )}
            </div>
        )
    }
}

export default withRouter(EnduserSidebar)
