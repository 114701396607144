import React, { useState } from 'react'
import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo'
import DownCarrotLarge from '../../../assets/icons/down-carrot-large.svg'
import { CgNotes } from 'react-icons/cg'

export default function Description(props) {
    const { Name, Logo, Overview, windowWidth, companyId, screenType } = props
    const [boxExpand, setBoxExpand] = useState(windowWidth > 900 ? true : false)
    const [descExpanded, setDescExpanded] = useState(false)

    return (
        <div className="companyOverview__descriptionContainer">
            <div
                className="companyOverview__heading"
                onClick={windowWidth > 900 ? null : () => setBoxExpand(!boxExpand)}
            >
                <div className="companyOverview__logoAndName" id="companyOverview__logoAndName">
                    <CompanyLogo
                        logo={Logo}
                        name={Name}
                        id={companyId}
                        title_link={null}
                        OuterStyle={{ maxWidth: '56px', maxHeight: '56px', margin: '0 24px' }}
                        square={true}
                    />

                    <div className="companyOverview__nameAndDesc">
                        <p className="companyOverview__companyName">{Name}</p>
                    </div>
                    {screenType === 'companies' && (
                        <button
                            title="Pre-meeting brief"
                            className="deleteButton"
                            style={{
                                marginBottom: '0px',
                                background: 'rgb(14, 74, 147)',
                                marginLeft: '30px',
                            }}
                            onClick={props.getPreMeetingData}
                        >
                            <CgNotes style={{ fontSize: '24', color: 'white' }} />
                        </button>
                    )}
                </div>

                <div className={boxExpand ? 'companyOverview__expand-rotate' : 'companyOverview__expand'}>
                    <img src={DownCarrotLarge} alt="click to expand" />
                </div>
            </div>

            {boxExpand && (
                <div className="companyOverview__descriptionBox">
                    {!descExpanded && <p className="companyOverview__descText">{Overview.substring(0, 120) + '...'}</p>}
                    {descExpanded && <p className="companyOverview__descText">{Overview}</p>}

                    {!descExpanded && (
                        <p className="companyOverview__seeMore" onClick={() => setDescExpanded(!descExpanded)}>
                            See More +
                        </p>
                    )}
                    {descExpanded && (
                        <p className="companyOverview__seeMore" onClick={() => setDescExpanded(!descExpanded)}>
                            See Less -
                        </p>
                    )}
                </div>
            )}
        </div>
    )
}
