import React, { useEffect, useRef, useState } from 'react'
import './Filter.css'
import axios from 'axios'
import NoResults from '../../../components/NoResults/NoResults'
import SearchQueryView from '../../../components/NewsEditedCard/SearchQueryView'
import store from '../../../Store'
import ResultHeader from './ResultsHeader'
import { Grid, Tab, Tabs, Box } from '@mui/material'
import PowerSearchTab from './PowerSearchTab'
import BasicAdvanceDropDown from './BasicAdvanceDropDown'
import SavedQueries from './SavedQueries'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { getYMD } from '../../../utils/dateHelpers'
import { Input, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp'
import PredefinedQueries from './PredefinedQueries'
import { getCookie } from '../../../utils'
import SemanticSearch from './SemanticSearch'
import PreMeetingBriefModal from './PreMeetingBriefModal'
import QuickSearch from './QuickSearch'
import WeaviateQuickSearch from './WeaviateQuickSearch'
import { FiMinimize2 } from 'react-icons/fi'
import { AiOutlineClose, AiOutlineExpandAlt, AiOutlineMinus, AiOutlineSave } from 'react-icons/ai'
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import { BsChatFill, BsFullscreen } from 'react-icons/bs'
import { IoMdSend } from 'react-icons/io'
import Select from 'react-select'
import { FaShareAlt } from 'react-icons/fa'
import SavedQueriesModal from './SavedQueriesModal'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token
const currentPath = window.location.pathname

export default function SearchUtility(props) {
    const currentPath = window.location.pathname
    const [checkedCompanies, setCheckedCompanies] = useState(false)
    const [checkedSources, setCheckedSources] = useState(false)
    const [checkedPractices, setCheckedPractices] = useState(false)
    const [checkedTopics, setCheckedTopics] = useState(false)
    const [checkedRegions, setCheckedRegions] = useState(false)

    const { activeTab, setActiveTab } = props
    const { userEmail, userRole } = props
    const {
        isQueryEditor,
        setIsQueryEditor,
        isQueryEditorJson,
        setIsQueryEditorJson,
        premeetingModel,
        setPremeetingModal,
        selectedCompanies,
        setSelectedCompanies,
        PremeetingResponse,
        setPremeetingResponse,
        premeetingLoader,
        setPremeetingLoader,
        regionHierarchy,
        setRegionHierarchy,
        apiLevel,
        chat,
        setChat,
    } = props

    const {
        allIndustries,
        setAllIndustries,
        allCompanies,
        setAllCompanies,
        allRegions,
        setAllRegions,
        allLinkedInGroups,
        allTopics,
        setAllTopics,
        allCategories,
        setAllCategories,
        allSubCategories,
        setAllSubCategories,
        allSources,
        setAllSources,
        allHierarchy,
        setAllHierarchy,
        practiceSubCategory,
        setPracticeSubCategory,
        addToSelection,
        removeFromSelection,
        getAllCompanies,
        selectedSectors,
        setSelectedSectors,
        id,
        setId,
        selectedLevel,
        setSelectedLevel,
        allCollectionName,
        setAllCollectionName,
        getAllCollections,
        allCollectionUsers,
        setAllCollectionUsers,
    } = props

    const { handleLoadMore } = props

    const wrapperRef = useRef(null)
    closeExpandable(wrapperRef)
    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setBasicSearchDrop(false)
                setRelavanceSection(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }

    const { selectedStories, setSelectedStories } = props
    const { basicSearchResponse, setBasicSearchResponse } = props
    const { advanceResponse, setAdvanceResponse } = props
    const [basicSearchInput, setBasicSearchInput] = useState('')
    const [basicSearchDrop, setBasicSearchDrop] = useState(false)
    const [btnColour, setBtnColour] = useState(false)
    const [editPage, setEditPage] = useState(false)
    const [editqueryName, setEditQueryName] = useState('')
    const [editId, setEditId] = useState('')
    const [directRun, setDirectRun] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [editorQuery, setEditorQuery] = useState('')
    const [selectedOption, setSelectedOption] = useState('29')
    const [level, setLevel] = useState('level2')
    const [preeditPage, setPreeditPage] = useState(false)
    const [runQuery, setRunQuery] = useState(false)
    const [selectedRelavance, setSelectedRelavance] = useState('80')
    const [selectedAlpha, setSelectedAlpha] = useState('50')

    const [expandedView, setExpandedView] = useState(false)
    const [isAllSelected, setAllSelected] = useState(false)
    const [selectedNewsOnly, setSelectedNewsOnly] = useState(false)
    const [premeetingPlan, setPremeetingPlan] = useState(false)
    const [isScore, setIsScore] = useState(false)

    const usertype = store.getState().auth.user.user_type

    var startDate = new Date()
    startDate.setDate(startDate.getDate() - 29)
    const [selectedDatefrom, setSelectedDateFrom] = useState(getYMD(startDate))
    const [selectedDateTo, setSelectedDateTo] = useState(getYMD(new Date()))
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedRegions, setSelectedRegions] = useState([])
    const [selectedSources, setSelectedSources] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [popularsearch, setPopularsearch] = useState([])
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [context, setContext] = useState(null)
    const [newText, setNewText] = useState('')
    const [results, setResults] = useState([])
    const [chatModal, setChatModal] = useState(false)
    const [Message, setMessage] = useState([])
    const [chaticon, setChatIcon] = useState(true)
    const [minContext, setMinContext] = useState(false)
    const [responseMessage, setResponseMessage] = useState([])
    const [isTyping, setIsTyping] = useState(false)
    const [typing, setTyping] = useState(false)
    const [expand, setExpand] = useState(false)
    const [messages, setMessages] = useState([{ text: 'Hi, How can I help? ', sender: 'sender' }])
    const [newMessage, setNewMessage] = useState('')
    const [cache, setChache] = useState([])
    const [cachenews, setCachenews] = useState(false)
    const [semanticSaveModal, setSemanticSaveModal] = useState(false)
    const [saveQueryName, setSaveQueryName] = useState('')
    const [emailSubject, setEmailSubject] = useState('')
    const [includeSection, setIncludeSection] = useState(false)
    const [includeBusiness, setIncludeBusiness] = useState(false)
    const [collectionName, setCollectionName] = useState(null)
    const [collectionDescription, setCollectionDescription] = useState('')
    const [collectionShared, setCollectionShared] = useState(false)
    const [collectionUsers, setCollectionUsers] = useState([])
    const [collectionModal, setCollectionModal] = useState(false)
    const [collectionShareList, setCollectionShareList] = useState([
        { value: true, label: 'Share with Everyone' },
        { value: false, label: 'Private' },
    ])
    const [collectionPrivacy, setCollectionPrivacy] = useState({ value: true, label: 'Share with Everyone' })
    const [collectionId, setCollectionId] = useState(null)
    const [showFullText, setShowFullText] = useState(false)
    const [loadmoreFilter, setLoadMoreFilter] = useState({ value: null, type: null })
    const [quickSummary, setQuickSummary] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        if (directRun && (usertype === 'Regular User' || usertype === 'Power User')) {
            weviateSearch()
        } else if (directRun && usertype !== 'Regular User' && usertype !== 'Power User') {
            generateQuery(selectedOption)
        }
    }, [directRun === true])

    const allIndustriesName = allHierarchy.map(val => val.name)

    const allSectorsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const addingOfIndustriesSectorsNames = allIndustriesName.concat(allSectorsName)

    const saveSemanticQuery = () => {
        const payload = {
            query_name: saveQueryName,
            quick_search_query: basicSearchInput,
            query: {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    company: { operator: 'IS', values: selectedCompanies },
                    sectors: {
                        operator: 'IS',
                        values: addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                    },
                    category: { operator: 'IS', values: selectedCategories },
                    topics: {
                        operator: 'IS',
                        values: allTopicsName.filter(acc => selectedSectors.includes(acc)),
                    },
                    date: selectedOption,
                    // relavance: selectedRelavance,
                },
            },
            section: selectedSection,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            emailSubject: emailSubject,
            query_string: null,
            curation_level: selectedLevel,
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(val => val.id) : [],
        }

        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }

        const saveQuickQuery = () => {
            axios
                .post('/news/search-query/', payload)
                .then(res => {
                    toast('Query has been successfully saved.')
                    setEditQueryName('')
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setSemanticSaveModal(false)
                    setCollectionDescription('')
                    setCollectionName(null)
                    setCollectionUsers([])
                    setCollectionModal(false)
                    getAllCollections()
                })
        }

        if (saveQueryName !== '' && collectionName !== '' && collectionPrivacy !== null) {
            if (collectionModal) {
                axios
                    .post('/news/collection/', collectionPayload)
                    .then(res => {
                        if (res.data && res.data.id) {
                            payload.collection = res.data.id
                            saveQuickQuery()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                payload.collection = collectionName && collectionName.value ? collectionName.value : collectionId
                saveQuickQuery()
            }
        } else {
            toast.warn('Please fill out all the fields.')
        }
    }

    const editSemanticQuery = () => {
        const payload = {
            query_name: editPage ? editqueryName : saveQueryName,
            quick_search_query: basicSearchInput,
            query: {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    company: { operator: 'IS', values: selectedCompanies },
                    sectors: {
                        operator: 'IS',
                        values: addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                    },
                    category: { operator: 'IS', values: selectedCategories },
                    topics: {
                        operator: 'IS',
                        values: allTopicsName.filter(acc => selectedSectors.includes(acc)),
                    },
                    date: selectedOption,
                    // relavance: selectedRelavance,
                },
            },
            query_string: null,
            section: selectedSection,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            subject: emailSubject,
            recipients: selectedUsers.map(val => val.email),
            curation_level: selectedLevel,
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            collection: collectionName && collectionName.value ? collectionName.value : collectionId,
        }
        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }

        const updateEditQuickQuery = () => {
            axios
                .put(`/news/search-query/${editId}/`, payload)
                .then(res => {
                    setEditPage(false)
                    toast('Query has been successfully updated')
                    setBasicSearchInput('')
                    setEditQueryName('')
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setSemanticSaveModal(false)
                    setCollectionDescription('')
                    setCollectionName(null)
                    setCollectionUsers([])
                    setCollectionModal(false)
                    getAllCollections()
                })
        }
        if (editqueryName !== '') {
            if (collectionModal) {
                axios
                    .post('/news/collection/', collectionPayload)
                    .then(res => {
                        if (res.data && res.data.id) {
                            payload.collection = res.data.id
                            updateEditQuickQuery()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                updateEditQuickQuery()
            }
        }
    }

    useEffect(() => {
        isAllSelected
            ? setSelectedStories(current => [
                  ...current,
                  ...basicSearchResponse.data.filter(each => !selectedStories.includes(each)),
              ])
            : setSelectedStories([])
    }, [isAllSelected])

    useEffect(() => {
        if (selectedStories.length > 0) {
            const isSame = JSON.stringify(cache) !== JSON.stringify(selectedStories.map(val => val.id))

            setCachenews(!isSame)
        }
    }, [cache, selectedStories])

    //functions

    const handleLoadQuick = () => {
        if (currentPage <= maxPages) {
            rightScroll()
        } else {
            const relevanceResult = 110 - Number(selectedRelavance)
            const aplhaResult = Number(selectedAlpha)
            let data = {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    [currentPath === '/app/search/' || selectedLevel === 'level0'
                        ? 'articleDate'
                        : apiLevel === 'level1'
                        ? 'pull_date'
                        : 'published_date']: {
                        operator: 'BETWEEN',
                        from: selectedDatefrom,
                        to: selectedDateTo,
                    },
                },
                next: basicSearchResponse.next,
                relevance_cutoff:
                    selectedOption === '1' || selectedOption === '0' || selectedOption === '6'
                        ? 3
                        : selectedOption === '29'
                        ? 13
                        : selectedOption === '59'
                        ? 23
                        : selectedOption === '89'
                        ? 33
                        : 43,
                semantic_preference: aplhaResult / 100,
                type: selectedLevel,
            }
            if (basicSearchInput !== '' && loadmoreFilter.value === null) {
                data.query = basicSearchInput
            }

            if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
                data.filters[loadmoreFilter.type] = {
                    operator: 'IS',
                    values: [loadmoreFilter.value],
                }
            }
            if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
                data.non_entity_query = loadmoreFilter.value
            }
            // const dateFilter = {
            //     [currentPath === '/app/search/' ? 'articleDate' : apiLevel === 'level1' ? 'pull_date' : 'published_date']: {
            //         operator: 'BETWEEN',
            //         from: selectedDatefrom,
            //         to: selectedDateTo,
            //     },
            // }

            // if (selectedDatefrom !== '') {
            //     data.filters = { ...data.filters, ...dateFilter }
            // }
            if (basicSearchResponse.next !== null) {
                axios
                    .post('/semantic/quick-search/', data)
                    .then(response => {
                        let newsBefore = [...basicSearchResponse.data, ...response.data.data]
                        setBasicSearchResponse({
                            total: response.data.total,
                            next: response.data.next,
                            data: newsBefore,
                        })
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            } else {
                setIsLoading(false)
            }
        }
    }

    const weviateSearch = (dropdownValue, type) => {
        setBasicSearchResponse({ data: [], next: null })
        setBasicSearchDrop(false)
        setQuickSummary(null)
        setShowFullText(false)

        const keyName =
            type === 'topic'
                ? 'topics'
                : type === 'SubCategory'
                ? 'category'
                : type === 'Company'
                ? 'company'
                : 'sectors'
        setIsLoading(true)
        if (dropdownValue !== undefined) {
            setLoadMoreFilter({ value: dropdownValue, type: keyName })
        } else {
            setLoadMoreFilter({ value: null, type: null })
        }
        const relevanceResult = 110 - Number(selectedRelavance)
        const aplhaResult = Number(selectedAlpha)

        const payload = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                [currentPath === '/app/search/' || selectedLevel === 'level0'
                    ? 'articleDate'
                    : apiLevel === 'level1'
                    ? 'pull_date'
                    : 'published_date']: {
                    operator: 'BETWEEN',
                    from: selectedDatefrom,
                    to: selectedDateTo,
                },
            },
            relevance_cutoff:
                selectedOption === '1' || selectedOption === '0' || selectedOption === '6'
                    ? 3
                    : selectedOption === '29'
                    ? 13
                    : selectedOption === '59'
                    ? 23
                    : selectedOption === '89'
                    ? 33
                    : 43,

            semantic_preference: aplhaResult / 100,
            type: selectedLevel,
        }
        if (basicSearchInput !== '' && dropdownValue === undefined) {
            payload.query = basicSearchInput
        }
        if (basicSearchInput !== '' && type !== undefined) {
            payload.filters[keyName] = {
                operator: 'IS',
                values: [dropdownValue],
            }
        }

        if (basicSearchInput !== '' && dropdownValue && dropdownValue.length > 0) {
            payload.non_entity_query = dropdownValue
        }

        // const dateFilter = {
        //     [currentPath === '/app/search/' ? 'articleDate' : apiLevel === 'level1' ? 'pull_date' : 'published_date']: {
        //         operator: 'BETWEEN',
        //         from: selectedDatefrom,
        //         to: selectedDateTo,
        //     },
        // }

        // if (selectedDatefrom !== '') {
        //     payload.filters = { ...payload.filters, ...dateFilter }
        // }

        axios
            .post('/semantic/quick-search/', payload)
            .then(res => {
                if (res.data.summary) {
                    setQuickSummary(res.data.summary)
                } else {
                    setQuickSummary(null)
                }

                setBasicSearchResponse({
                    total: res.data.total,
                    next: res.data.next,
                    data: res.data.data,
                })
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const basicSearch = (qPayload, type) => {
        setIsLoading(true)
        if (
            type === 'free' &&
            selectedSectors.length === 0 &&
            selectedRegions.length === 0 &&
            selectedCompanies.length === 0 &&
            selectedCategories.length === 0 &&
            selectedSources.length === 0
        ) {
            toast('use dropdown for further searches')
            setBtnColour(true)
            setTimeout(() => {
                setBtnColour(false)
            }, 1000)
            for (var i = 10; i < popularsearch.length; i++) {
                document.getElementById(`btn_${i}`).style.background = null
                document.getElementById(`btn_${i}`).style.color = 'black'
            }
        }

        const payload = {
            filters: {
                source: {
                    operator: 'IS',
                    values: [],
                },
                [currentPath === '/app/search/'
                    ? 'articleDate'
                    : apiLevel === 'level1'
                    ? 'pull_date'
                    : 'published_date']: {
                    operator: 'BETWEEN',
                    from: selectedDatefrom,
                    to: selectedDateTo,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        type === 'Sector' || type === 'Industry'
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === qPayload)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : selectedSectors,
                },
                company: {
                    operator: 'IS',
                    values:
                        type === 'Company'
                            ? [
                                  ...new Set(
                                      allCompanies
                                          .filter(val => val.label === qPayload)
                                          .map(val => val.label)
                                          .concat(selectedCompanies)
                                  ),
                              ]
                            : selectedCompanies,
                },
                category: {
                    operator: 'IS',
                    values:
                        type === 'SubCategory'
                            ? [
                                  ...new Set(
                                      allSubCategories
                                          .filter(val => val.label === qPayload)
                                          .map(val => val.label)
                                          .concat(selectedCategories)
                                  ),
                              ]
                            : selectedCategories,
                },
                topics: {
                    operator: 'IS',
                    values:
                        type === 'Topic'
                            ? [
                                  ...new Set(
                                      allTopics
                                          .filter(val => val.label === qPayload)
                                          .map(val => val.label)
                                          .concat(selectedTopics)
                                  ),
                              ]
                            : selectedTopics,
                },
                newsTitle: {
                    operator: 'CONTAINS',
                    value: '',
                    fuzziness: 3,
                },
                newsContent: {
                    operator: 'CONTAINS',
                    regex: '',
                },
                values:
                    selectedCompanies.includes(basicSearchInput) ||
                    selectedCategories.includes(
                        basicSearchInput ||
                            selectedSectors.includes(basicSearchInput) ||
                            selectedTopics.includes(basicSearchInput)
                    )
                        ? ''
                        : type === 'free'
                        ? basicSearchInput
                        : '',
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }

        axios
            .post(
                `/news/${currentPath === '/app/search/' ? 'level2' : apiLevel}/search/`,
                type === 'payload' ? qPayload : payload
            )
            .then(response => {
                setBasicSearchResponse({
                    total: response.data.total,
                    next: response.data.next,
                    scroll_id: response.data.scroll_id,
                    data: response.data.data,
                })
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
        setBasicSearchDrop(false)
    }
    const [selectedQueries, setSelectedQueries] = useState({
        articleDateFrom: '',
        articleOperator: 'BETWEEN',
        articleDateTo: '',
        region: [],
        regionOperator: 'IS',
        sectors: [],
        industryOperator: 'IS',
        company: [],
        companyOperator: 'IS',
        category: [],
        categoryOperator: 'IS',
        topic: [],
        topicOperator: 'IS',
        newsTitle: '',
        newsTitleOperator: 'CONTAINS',
        newsContent: '',
        newsContentOperator: 'CONTAINS',
        source: [],
        sourceOperator: 'IS',
    })

    // useEffect(() => {
    //     axios
    //         .get('/news/popular-search/')
    //         .then(res => {
    //             setPopularsearch(res.data)
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })
    // }, [])

    const popularMap = popularsearch.map((ind, i) => {
        return (
            <button
                key={`btn_${i}`}
                id={`btn_${i}`}
                className="btn_pre"
                onClick={() => {
                    setBasicSearchResponse({ data: [], next: null })
                    popularClick(document.getElementById(`btn_${i}`))
                    basicSearch(ind.value, 'payload')
                }}
                style={{ marginLeft: '7px' }}
            >
                {ind.label}
            </button>
        )
    })
    const popularClick = id => {
        setBasicSearchInput('')
        for (var i = 0; i < popularsearch.length; i++) {
            document.getElementById(`btn_${i}`).style.background = null
            document.getElementById(`btn_${i}`).style.color = 'black'
        }
        setSelectedCompanies([])
        setSelectedCategories([])
        setSelectedRegions([])
        setSelectedSources([])
        setSelectedSectors([])
        setSelectedTopics([])
        setAllSelected(false)
        id.style.background = '#3B7DBA'
        id.style.color = 'white'
    }

    const clearFilters = () => {
        setSelectedOption('29')
        setSelectedRelavance('80')
        setSelectedAlpha('50')
        // setSelectedDateFrom('')
        // setSelectedDateTo('')
        setBasicSearchInput('')
        setSelectedCompanies([])
        setSelectedCategories([])
        setSelectedRegions([])
        setSelectedSources([])
        setSelectedSectors([])
        setSelectedTopics([])
        setAllSelected(false)
        setBasicSearchResponse({ data: [], next: null })
        setBasicSearchInput('')
        setCheckedCompanies(false)
        setCheckedPractices(false)
        setCheckedSources(false)
        setCheckedTopics(false)
        setCheckedRegions(false)
        setEditPage(false)
        setDirectRun(false)
        setSelectedSection(null)
        setIncludeBusiness(false)
        setIncludeSection(false)
        setRelavanceSection(false)
        setSelectedStories([])
        setEmailSubject('')
        setSelectedUsers([])
        setQuickSummary(null)
        setShowFullText(false)
        setLoadMoreFilter({ value: null, type: null })

        for (var i = 0; i < popularsearch.length; i++) {
            document.getElementById(`btn_${i}`).style.background = null
            document.getElementById(`btn_${i}`).style.color = 'black'
        }
    }

    const premeetingCopy = () => {
        PremeetingResponse.toString()
        const removebr = PremeetingResponse.replace(/(<([^>]+)>)/gi, '')
        navigator.clipboard.writeText(removebr)
        var tooltip = document.getElementById('premeetingTooltip')
        tooltip.innerHTML = 'Copy to clipboard'
        setTimeout(() => {
            tooltip.innerHTML = ''
        }, 1000)
    }

    const handleSendMessage = () => {
        setChache(selectedStories.map(value => value.id))
        if (newMessage.trim() !== '') {
            const userMessage = { text: newMessage, sender: 'user' }
            setMessages(prevMessages => [...prevMessages, userMessage])
            setNewMessage('')

            setTyping(true)
            setResponseMessage([])

            const search = {
                context_id: id,
                chat_question: newMessage,
                is_new_search: false,
                news_ids:
                    selectedStories.length > 0
                        ? selectedStories.map(value => value.id)
                        : basicSearchResponse.data && basicSearchResponse.data.map(res => res.id),
                cache_news: cachenews,
            }
            axios
                .post('/semantic/search/', search)
                .then(datas => {
                    setTyping(false)
                    const responseText = datas.data.text
                    simulateTypingEffect(responseText)
                })
                .catch(error => {
                    console.error('Error:', error)
                })
        }
    }

    const simulateTypingEffect = text => {
        let currentIndex = 0
        const typingInterval = setInterval(() => {
            setIsTyping(true)
            if (currentIndex < text.length) {
                setResponseMessage(prevMessages => [...prevMessages, text[currentIndex]])
                currentIndex++
            } else {
                setIsTyping(false)
                clearInterval(typingInterval)

                const senderMessage = {
                    text: text,
                    sender: 'sender',
                }
                setMessages(prevMessages => [...prevMessages, senderMessage])
            }
        }, 30)
    }

    const handlechat = () => {
        setChatModal(true)
    }

    const handleQuickPopulate = (sources, countries, companies, sectors, categories, topics, date) => {
        setSelectedSources(sources)
        setSelectedRegions(countries)
        setSelectedCompanies(companies)
        setSelectedSectors([...sectors, ...topics])
        setSelectedCategories(categories)
        setSelectedOption(date)
        // setSelectedRelavance(relavance)
    }
    const [customDate, setcustomDate] = useState({
        startDate: null,
        endDate: null,
    })
    const [dropSearch, setDropSearch] = useState([])
    const [defaultData, setDefaultData] = useState([])
    const [dateSection, setDateSection] = useState(false)
    const [relavancesection, setRelavanceSection] = useState(false)

    function dateFormatChanges(parsedDate) {
        const year = parsedDate.getFullYear()
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0')
        const day = parsedDate
            .getDate()
            .toString()
            .padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`
        return formattedDateString
    }

    const generateQuery = date => {
        setBasicSearchResponse({ data: [], next: null })
        setQuickSummary(null)
        setShowFullText(false)
        // setLoadMoreFilter({ value: null, type: null })

        setIsLoading(true)
        if (date !== 'custom') {
            setcustomDate({
                startDate: null,
                endDate: null,
            })
        }
        for (var i = 0; i < popularsearch.length; i++) {
            document.getElementById(`btn_${i}`).style.background = null
            document.getElementById(`btn_${i}`).style.color = 'black'
        }
        setDateSection(false)
        if (date === 1 || date === '1') {
            date = 0
        }
        var startDate = new Date()
        var endDate = new Date()
        if (date !== 1) {
            startDate.setDate(startDate.getDate() - date)
            startDate = getYMD(startDate)
            setSelectedDateFrom(startDate)
            endDate = getYMD(endDate)
            setSelectedDateTo(endDate)
        } else {
            startDate = getYMD(startDate)
            setSelectedDateFrom(startDate)
            endDate = getYMD(endDate)
            setSelectedDateTo(endDate)
        }

        const relevanceResult = 110 - Number(selectedRelavance)
        const aplhaResult = Number(selectedAlpha)

        const payLoad = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources.map(val => val),
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                [currentPath === '/app/search/' || selectedLevel === 'level0'
                    ? 'articleDate'
                    : apiLevel === 'level1'
                    ? 'pull_date'
                    : 'published_date']: {
                    operator: 'BETWEEN',
                    from: customDate.startDate !== null ? dateFormatChanges(new Date(customDate.startDate)) : startDate,
                    to: customDate.endDate !== null ? dateFormatChanges(new Date(customDate.endDate)) : endDate,
                },
            },
            // relevance_cutoff: relevanceResult / 10,
            relevance_cutoff:
                date === 1 || date === 0 || date === 6
                    ? 3
                    : date === 29
                    ? 13
                    : date === 59
                    ? 23
                    : date === 89
                    ? 33
                    : 43,
            semantic_preference: aplhaResult / 100,
            type: selectedLevel,
        }

        if (basicSearchInput !== '' && loadmoreFilter.value === null) {
            payLoad.query = basicSearchInput
        }

        if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
            payLoad.filters[loadmoreFilter.type] = {
                operator: 'IS',
                values: [loadmoreFilter.value],
            }
        }
        if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
            payLoad.non_entity_query = loadmoreFilter.value
        }

        // const dateFilter = {
        //     [currentPath === '/app/search/' ? 'articleDate' : apiLevel === 'level1' ? 'pull_date' : 'published_date']: {
        //         operator: 'BETWEEN',
        //         from: customDate.startDate !== null ? dateFormatChanges(new Date(customDate.startDate)) : startDate,
        //         to: customDate.endDate !== null ? dateFormatChanges(new Date(customDate.endDate)) : endDate,
        //     },
        // }

        // if (date !== '') {
        //     payLoad.filters = { ...payLoad.filters, ...dateFilter }
        // }
        const payload = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources.map(val => val),
                },
                [currentPath === '/app/search/'
                    ? 'articleDate'
                    : apiLevel === 'level1'
                    ? 'pull_date'
                    : 'published_date']: {
                    operator: 'BETWEEN',
                    from: customDate.startDate !== null ? dateFormatChanges(new Date(customDate.startDate)) : startDate,
                    to: customDate.endDate !== null ? dateFormatChanges(new Date(customDate.endDate)) : endDate,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : //  selectedSectors
                              addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                },
                company: {
                    operator: 'IS',
                    values:
                        defaultData.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      defaultData
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCompanies)
                                  ),
                              ]
                            : selectedCompanies,
                },
                category: {
                    operator: 'IS',
                    values:
                        allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allSubCategories
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCategories)
                                  ),
                              ]
                            : // selectedCategories
                              [
                                  ...new Set(
                                      practiceSubCategory.reduce((allitems, item) => {
                                          const selList = allitems

                                          if (selectedCategories.includes(item.id)) {
                                              if (!selList.includes(item.name)) {
                                                  selList.push(item.name)
                                              }
                                          }
                                          item.subcategories.forEach(subItem => {
                                              if (
                                                  Boolean(selectedCategories.includes(subItem.id)) &&
                                                  !selList.includes(subItem.name)
                                              ) {
                                                  selList.push(subItem.name)
                                              }
                                          })

                                          return selList
                                      }, [])
                                  ),
                              ],
                },
                topics: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : // selectedSectors

                              allTopicsName.filter(acc => selectedSectors.includes(acc)),
                },
                newsTitle: {
                    operator: 'CONTAINS',
                    value: '',
                    fuzziness: 3,
                },
                newsContent: {
                    operator: 'CONTAINS',
                    regex: '',
                },
                values:
                    selectedCompanies.includes(basicSearchInput) ||
                    selectedCategories.includes(
                        basicSearchInput ||
                            selectedSectors.includes(basicSearchInput) ||
                            selectedTopics.includes(basicSearchInput)
                    ) ||
                    allIndustries.filter(val => val.label === basicSearchInput).length > 0 ||
                    allSubCategories.filter(val => val.label === basicSearchInput).length > 0 ||
                    defaultData.filter(val => val.label === basicSearchInput).length > 0
                        ? ''
                        : basicSearchInput,
            },
            search_type: 'advanced',
            next: 0,
            size: 15,
        }

        if (currentPath !== '/app/search/' && selectedLevel !== 'level0') {
            axios
                .post(`/news/${currentPath === '/app/search/' ? 'level2' : apiLevel}/search/`, payload)
                .then(res => {
                    setBasicSearchResponse(res.data)
                    setDropSearch(res.data)
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            axios
                .post('/semantic/quick-search/', payLoad)
                .then(res => {
                    if (res.data.summary) {
                        setQuickSummary(res.data.summary)
                    } else {
                        setQuickSummary(null)
                    }
                    setBasicSearchResponse({
                        total: res.data.total,
                        next: res.data.next,
                        data: res.data.data,
                    })
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }
    const handleLevelChange = event => {
        setSelectedLevel(event.target.value)
        clearFilters()
    }
    const maxPages = Math.ceil(basicSearchResponse.data && basicSearchResponse.data.length / 15)

    const rightScroll = () => {
        if (currentPage <= maxPages && basicSearchResponse.next !== null) {
            setCurrentPage(prevPage => prevPage + 1)
        } else if (maxPages > currentPage) {
            setCurrentPage(prevPage => prevPage + 1)
        } else {
            setCurrentPage(prevPage => prevPage)
        }
    }

    const renderData = () => {
        const cardsPerPage = 15
        const totalCards = basicSearchResponse.data && basicSearchResponse.data.length
        const totalPages = Math.ceil(totalCards / cardsPerPage)

        let startIndex = (currentPage - 1) * cardsPerPage
        let endIndex = Math.min(startIndex + cardsPerPage, totalCards)

        if (totalCards < cardsPerPage) {
            startIndex = 0
            endIndex = totalCards
        }

        return (
            <>
                <div style={{ width: '93%' }}>
                    <ResultHeader
                        style={{ fontSize: '20px' }}
                        isBasicSearchPage={true}
                        allSelected={isAllSelected}
                        setAllSelected={setAllSelected}
                        // resultLength={basicSearchResponse.total}
                        // resultCount={
                        //     basicSearchResponse.total -
                        //     basicSearchResponse.data.filter(
                        //         val =>
                        //             (val.title && val.title.length < 0) || (val.title && val.title === '') || !val.title
                        //     ).length
                        // }
                        resultLength={
                            basicSearchResponse.data.length -
                            basicSearchResponse.data.filter(
                                val =>
                                    (val.title && val.title.length < 0) || (val.title && val.title === '') || !val.title
                            ).length
                        }
                        resultCount={
                            basicSearchResponse.total -
                            basicSearchResponse.data.filter(
                                val =>
                                    (val.title && val.title.length < 0) || (val.title && val.title === '') || !val.title
                            ).length
                        }
                        expanded={expandedView}
                        setExpanded={setExpandedView}
                        selectedNewsOnly={selectedNewsOnly}
                        setSelectedNewsOnly={setSelectedNewsOnly}
                        premeetingPlan={premeetingPlan}
                        setPremeetingPlan={setPremeetingPlan}
                        selectedStoriesLength={selectedStories.length}
                        basicScore={basicSearchResponse.data.length > 0 && basicSearchResponse.data[0].score}
                        isScore={isScore}
                        setIsScore={setIsScore}
                    />
                </div>
                {selectedNewsOnly ? (
                    selectedStories.map(story => {
                        return (
                            <SearchQueryView
                                isFindAndShare={true}
                                key={story.id}
                                EachrawNews={story}
                                isBasicSearch={true}
                                selectedStories={selectedStories}
                                isStorySelected={
                                    selectedStories.findIndex(arr => arr.id === story.id) === -1 ? false : true
                                }
                                addToSelection={addToSelection}
                                allCategories={allCategories}
                                allSubCategories={allSubCategories}
                                allIndustries={allIndustries}
                                allCompanies={allCompanies}
                                setAllCompanies={setAllCompanies}
                                allRegions={allRegions}
                                allLinkedInGroups={allLinkedInGroups}
                                removeFromSelection={removeFromSelection}
                                allTopics={allTopics}
                                collapsed={!expandedView}
                                usertype={usertype}
                                selectAll={isAllSelected}
                                currentPath={currentPath}
                                selectedNewsOnly={selectedNewsOnly}
                                isScore={isScore}
                                selectedLevel={selectedLevel}
                            />
                        )
                    })
                ) : (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={
                            currentPath !== '/app/search/' && selectedLevel !== 'level0'
                                ? handleLoadMore
                                : handleLoadQuick
                        }
                        hasMore={basicSearchResponse.next < basicSearchResponse.total}
                        loader={
                            basicSearchResponse.next !== null && (
                                <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                            )
                        }
                    >
                        {basicSearchResponse.data.slice(0, endIndex).map(story => {
                            return (
                                <SearchQueryView
                                    isFindAndShare={true}
                                    key={story.id}
                                    EachrawNews={story}
                                    isBasicSearch={true}
                                    selectedStories={selectedStories}
                                    isStorySelected={
                                        selectedStories.findIndex(arr => arr.id === story.id) === -1 ? false : true
                                    }
                                    addToSelection={addToSelection}
                                    allCategories={allCategories}
                                    allSubCategories={allSubCategories}
                                    allIndustries={allIndustries}
                                    allCompanies={allCompanies}
                                    setAllCompanies={setAllCompanies}
                                    allRegions={allRegions}
                                    allLinkedInGroups={allLinkedInGroups}
                                    removeFromSelection={removeFromSelection}
                                    allTopics={allTopics}
                                    collapsed={!expandedView}
                                    usertype={usertype}
                                    selectAll={isAllSelected}
                                    currentPath={currentPath}
                                    selectedNewsOnly={selectedNewsOnly}
                                    isScore={isScore}
                                    selectedLevel={selectedLevel}
                                />
                            )
                        })}
                    </InfiniteScroll>
                )}
            </>
        )
    }
    return (
        <>
            <div>
                <div
                    style={{
                        background: 'white',
                        width: '99%',
                        height:
                            activeTab === 0 && usertype !== 'Regular User'
                                ? '240px'
                                : activeTab === 0 && usertype === 'Regular User'
                                ? '240px'
                                : ' ',
                    }}
                >
                    {userRole !== 'BD Professional' && (
                        <div style={{ marginLeft: '15px' }}>
                            <Box sx={{ bgcolor: 'background.paper' }}>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => {
                                        setBasicSearchResponse({ data: [], next: null })
                                        setAdvanceResponse({ data: [], next: null })
                                        setActiveTab(newValue)
                                        setSelectedCompanies([])
                                        setChat(false)
                                        setDirectRun(false)
                                        setEditPage(false)
                                        setSelectedSection('')
                                        setIncludeBusiness(false)
                                        setIncludeSection(false)
                                        setSelectedUsers([])
                                        setEmailSubject('')
                                        setEditQueryName('')
                                        setQuickSummary(null)
                                        setCollectionName(null)
                                        setCollectionUsers([])
                                        setCollectionDescription('')
                                        setCollectionId(null)
                                    }}
                                >
                                    <Tab label="Quick search" />
                                    <Tab
                                        label="Advance search"
                                        style={{
                                            display:
                                                usertype === 'Regular User' || usertype === 'Power User' ? 'none' : '',
                                        }}
                                    />
                                    <Tab label="Chat" style={{ display: 'none' }} />
                                    <Tab label="Power search" />
                                    <Tab label="Saved searches" />
                                    <Tab
                                        label="PreDefined Search"
                                        style={{
                                            display: 'none',
                                        }}
                                    />
                                </Tabs>
                            </Box>
                        </div>
                    )}

                    {activeTab === 0 && (
                        <div style={{ background: 'white', padding: '30px', marginTop: '-15px' }}>
                            {userRole === 'BD Professional' && <div style={{ fontSize: '22px' }}>Quick Search</div>}

                            <div className="d-flex">
                                {usertype === 'Regular User' ||
                                usertype === 'Power User' ||
                                selectedLevel === 'level0' ? (
                                    <WeaviateQuickSearch
                                        basicSearch={weviateSearch}
                                        basicSearchInput={basicSearchInput}
                                        setBasicSearchInput={setBasicSearchInput}
                                        clearFilters={clearFilters}
                                        relavancesection={relavancesection}
                                        setRelavanceSection={setRelavanceSection}
                                        generateQuery={generateQuery}
                                        selectedRelavance={selectedRelavance}
                                        setSelectedRelavance={setSelectedRelavance}
                                        selectedAlpha={selectedAlpha}
                                        setSelectedAlpha={setSelectedAlpha}
                                        selectedOption={selectedOption}
                                        wrapperRef={wrapperRef}
                                        selectedLevel={selectedLevel}
                                        setSelectedLevel={setSelectedLevel}
                                        handleLevelChange={handleLevelChange}
                                        usertype={usertype}
                                        allCompanies={allCompanies}
                                        allTopics={allTopics}
                                        allSubCategories={allSubCategories}
                                        allIndustries={allIndustries}
                                        basicSearchDrop={basicSearchDrop}
                                        setBasicSearchDrop={setBasicSearchDrop}
                                    />
                                ) : (
                                    <QuickSearch
                                        basicSearch={basicSearch}
                                        basicSearchInput={basicSearchInput}
                                        setBasicSearchInput={setBasicSearchInput}
                                        basicSearchDrop={basicSearchDrop}
                                        setBasicSearchDrop={setBasicSearchDrop}
                                        wrapperRef={wrapperRef}
                                        clearFilters={clearFilters}
                                        allCompanies={allCompanies}
                                        allTopics={allTopics}
                                        allSubCategories={allSubCategories}
                                        allIndustries={allIndustries}
                                        popularsearch={popularsearch}
                                        selectedLevel={selectedLevel}
                                        setSelectedLevel={setSelectedLevel}
                                        handleLevelChange={handleLevelChange}
                                        relavancesection={relavancesection}
                                        setRelavanceSection={setRelavanceSection}
                                        usertype={usertype}
                                        currentPath={currentPath}
                                    />
                                )}

                                {/* {basicSearchResponse.data.length > 0 && (
                                    <Button
                                        title="chat"
                                        onClick={() => {
                                            handlechat()
                                        }}
                                        style={{
                                            borderRadius: '50px',
                                            backgroundColor: '#0a294f',
                                            marginLeft: ' 3%',
                                            border: 'none',
                                            display: 'none',
                                        }}
                                    >
                                        <BsChatFill style={{ fontSize: '24px', color: 'white' }} />
                                    </Button>
                                )} */}
                            </div>

                            {/* Advanced Search */}

                            <div
                                className="AdvanceSearch_section"
                                style={{
                                    margin: '30px auto',
                                    width:
                                        usertype === 'Regular User' ||
                                        usertype === 'Power User' ||
                                        selectedLevel === 'level0'
                                            ? '87%'
                                            : '99%',
                                }}
                            >
                                {/* <h3>Further Searches </h3> */}
                                <BasicAdvanceDropDown
                                    allRegions={allRegions}
                                    setAllRegions={setAllRegions}
                                    allCompanies={allCompanies}
                                    setAllCompanies={setAllCompanies}
                                    allIndustries={allIndustries}
                                    setAllIndustries={setAllIndustries}
                                    allCategories={allCategories}
                                    setAllCategories={setAllCategories}
                                    allSubCategories={allSubCategories}
                                    setAllSubCategories={setAllSubCategories}
                                    allTopics={allTopics}
                                    setAllTopics={setAllTopics}
                                    allSources={allSources}
                                    setAllSources={setAllSources}
                                    basicSearchInput={basicSearchInput}
                                    basicSearchResponse={basicSearchResponse}
                                    setBasicSearchResponse={setBasicSearchResponse}
                                    btnColour={btnColour}
                                    setBtnColour={setBtnColour}
                                    selectedCategories={selectedCategories}
                                    setSelectedCategories={setSelectedCategories}
                                    selectedCompanies={selectedCompanies}
                                    setSelectedCompanies={setSelectedCompanies}
                                    selectedTopics={selectedTopics}
                                    setSelectedTopics={setSelectedTopics}
                                    selectedSources={selectedSources}
                                    setSelectedSources={setSelectedSources}
                                    selectedRegions={selectedRegions}
                                    setSelectedRegions={setSelectedRegions}
                                    selectedSectors={selectedSectors}
                                    setSelectedSectors={setSelectedSectors}
                                    selectedDateTo={selectedDateTo}
                                    setSelectedDateTo={setSelectedDateTo}
                                    selectedDatefrom={selectedDatefrom}
                                    setSelectedDateFrom={setSelectedDateFrom}
                                    setIsLoading={setIsLoading}
                                    selectedOption={selectedOption}
                                    setSelectedOption={setSelectedOption}
                                    allHierarchy={allHierarchy}
                                    setAllHierarchy={setAllHierarchy}
                                    practiceSubCategory={practiceSubCategory}
                                    setPracticeSubCategory={setPracticeSubCategory}
                                    regionHierarchy={regionHierarchy}
                                    setRegionHierarchy={setRegionHierarchy}
                                    apiLevel={apiLevel}
                                    popularBtns={popularsearch.length}
                                    currentPath={currentPath}
                                    getAllCompanies={getAllCompanies}
                                    checkedCompanies={checkedCompanies}
                                    setCheckedCompanies={setCheckedCompanies}
                                    checkedPractices={checkedPractices}
                                    setCheckedPractices={setCheckedPractices}
                                    checkedRegions={checkedRegions}
                                    setCheckedRegions={setCheckedRegions}
                                    checkedSources={checkedSources}
                                    setCheckedSources={setCheckedSources}
                                    checkedTopics={checkedTopics}
                                    setCheckedTopics={setCheckedTopics}
                                    usertype={usertype}
                                    allIndustriesName={allIndustriesName}
                                    allSectorsName={allSectorsName}
                                    allTopicsName={allTopicsName}
                                    addingOfIndustriesSectorsNames={addingOfIndustriesSectorsNames}
                                    defaultData={defaultData}
                                    setDefaultData={setDefaultData}
                                    dateFormatChanges={dateFormatChanges}
                                    customDate={customDate}
                                    setcustomDate={setcustomDate}
                                    dateSection={dateSection}
                                    setDateSection={setDateSection}
                                    generateQuery={generateQuery}
                                    selectedRelavance={selectedRelavance}
                                    setSelectedRelavance={setSelectedRelavance}
                                    selectedAlpha={selectedAlpha}
                                    setSelectedAlpha={setSelectedAlpha}
                                    selectedLevel={selectedLevel}
                                    setSelectedLevel={setSelectedLevel}
                                />
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: usertype === 'analyst' ? '' : '87%',
                                    margin: 'auto',
                                }}
                            >
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: selectedLevel !== 'level0' ? '' : '6%',
                                        marginTop:
                                            usertype === 'analyst' && selectedLevel !== 'level0' ? '-7px' : '-100px',
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            setSemanticSaveModal(!semanticSaveModal)
                                        }}
                                        className="btn_color btn_text"
                                    >
                                        {!editPage ? 'Save' : 'Update'}
                                        <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                                            <AiOutlineSave />
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            {quickSummary && (
                                <div style={{ width: '87%', textAlign: 'justify', margin: 'auto' }}>
                                    <label style={{ fontSize: '18px', fontWeight: 700 }}>
                                        Summary <i class="far fa-list-alt" style={{ marginLeft: '5px' }} />
                                    </label>
                                    <div style={{ marginLeft: '5px' }}>
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                overflow: 'hidden',
                                                display: '-webkit-box',
                                                WebkitLineClamp: showFullText ? 'unset' : 3,
                                                WebkitBoxOrient: 'vertical',
                                            }}
                                        >
                                            {quickSummary.split('\n').map((article, index) => (
                                                <div key={index}>{article}</div>
                                            ))}
                                        </span>
                                        {quickSummary.length > 300 && (
                                            <span
                                                style={{ color: '#197bbd', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowFullText(!showFullText)
                                                }}
                                                className="d-flex align-items-center"
                                            >
                                                <span style={{ fontWeight: '600' }}>
                                                    {showFullText ? 'See Less' : 'See More'}
                                                </span>
                                                {showFullText ? (
                                                    <FaChevronUp
                                                        style={{
                                                            fontSize: '16px',
                                                            marginLeft: '4px',
                                                            height: '0.9rem',
                                                        }}
                                                    />
                                                ) : (
                                                    <FaChevronDown
                                                        style={{
                                                            fontSize: '16px',
                                                            marginLeft: '4px',
                                                            height: '0.9rem',
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* <div
                                    className="prebuild_templates"
                                    style={{
                                        margin: 'auto',
                                        width: '87%',
                                        display: usertype === 'Regular User' ? 'block' : 'none',
                                    }}
                                >
                                    <h3>Popular Searches</h3>
                                    <div
                                        className="prebuild_section"
                                        style={{ width: '100%', maxWidth: '75vw', marginLeft: '-10px' }}
                                    >
                                        <ScrollMenu
                                            data={popularMap}
                                            arrowLeft={<ArrowBackIosNewSharpIcon />}
                                            arrowRight={<ArrowForwardIosSharpIcon />}
                                            dragging={true}
                                            wheel={false}
                                            hideSingleArrow={true}
                                            translate={5}
                                        />
                                    </div>
                                </div> */}
                        </div>
                    )}
                    {activeTab === 0 &&
                        basicSearchResponse.data && basicSearchResponse.data.length === 0 &&
                        !isLoading && (
                            <div style={{ marginLeft: '40%', marginTop: '100px' }}>
                                <NoResults />
                            </div>
                        )}
                    {activeTab === 0 && isLoading && (
                        <div style={{ marginLeft: '40%', marginTop: '10px' }}>
                            <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
                        </div>
                    )}
                    {activeTab === 0 &&
                        basicSearchResponse.data.length !== 0 &&
                        basicSearchResponse.data.filter(
                            val => (val.title && val.title.length > 0) || (val.title && val.title !== '') || !val.title
                        ).length > 0 && (
                            <>
                                {currentPath !== '/app/search/' && selectedLevel !== 'level0' && (
                                    <div style={{ width: '93%' }}>
                                        <ResultHeader
                                            style={{ fontSize: '20px' }}
                                            isBasicSearchPage={true}
                                            allSelected={isAllSelected}
                                            setAllSelected={setAllSelected}
                                            resultLength={
                                                basicSearchResponse.data.length -
                                                basicSearchResponse.data.filter(
                                                    val =>
                                                        (val.title && val.title.length < 0) ||
                                                        (val.title && val.title === '') ||
                                                        !val.title
                                                ).length
                                            }
                                            resultCount={
                                                basicSearchResponse.total -
                                                basicSearchResponse.data.filter(
                                                    val =>
                                                        (val.title && val.title.length < 0) ||
                                                        (val.title && val.title === '') ||
                                                        !val.title
                                                ).length
                                            }
                                            expanded={expandedView}
                                            setExpanded={setExpandedView}
                                            selectedNewsOnly={selectedNewsOnly}
                                            setSelectedNewsOnly={setSelectedNewsOnly}
                                            premeetingPlan={premeetingPlan}
                                            setPremeetingPlan={setPremeetingPlan}
                                            selectedStoriesLength={selectedStories.length}
                                            basicScore={
                                                basicSearchResponse.data.length > 0 && basicSearchResponse.data[0].score
                                            }
                                            isScore={isScore}
                                            setIsScore={setIsScore}
                                        />
                                    </div>
                                )}
                                <div style={{ width: '94%' }}>
                                    {currentPath !== '/app/search/' && selectedLevel !== 'level0' ? (
                                        <Grid item>
                                            {selectedNewsOnly ? (
                                                selectedStories.map(story => {
                                                    return (
                                                        <SearchQueryView
                                                            isFindAndShare={true}
                                                            key={story.id}
                                                            EachrawNews={story}
                                                            isBasicSearch={true}
                                                            selectedStories={selectedStories}
                                                            isStorySelected={
                                                                selectedStories.findIndex(
                                                                    arr => arr.id === story.id
                                                                ) === -1
                                                                    ? false
                                                                    : true
                                                            }
                                                            addToSelection={addToSelection}
                                                            allCategories={allCategories}
                                                            allSubCategories={allSubCategories}
                                                            allIndustries={allIndustries}
                                                            allCompanies={allCompanies}
                                                            setAllCompanies={setAllCompanies}
                                                            allRegions={allRegions}
                                                            allLinkedInGroups={allLinkedInGroups}
                                                            removeFromSelection={removeFromSelection}
                                                            allTopics={allTopics}
                                                            collapsed={!expandedView}
                                                            usertype={usertype}
                                                            selectAll={isAllSelected}
                                                            currentPath={currentPath}
                                                            selectedNewsOnly={selectedNewsOnly}
                                                            isScore={isScore}
                                                            selectedLevel={selectedLevel}
                                                        />
                                                    )
                                                })
                                            ) : (
                                                <InfiniteScroll
                                                    pageStart={0}
                                                    loadMore={
                                                        currentPath !== '/app/search/' && selectedLevel !== 'level0'
                                                            ? handleLoadMore
                                                            : handleLoadQuick
                                                    }
                                                    hasMore={basicSearchResponse.next < basicSearchResponse.total}
                                                    loader={
                                                        basicSearchResponse.next !== null && (
                                                            <Circles
                                                                key={0}
                                                                type="Circles"
                                                                color="#adadad"
                                                                height={80}
                                                                width={80}
                                                            />
                                                        )
                                                    }
                                                >
                                                    {basicSearchResponse.data.map(story => {
                                                        return (
                                                            <SearchQueryView
                                                                isFindAndShare={true}
                                                                key={story.id}
                                                                EachrawNews={story}
                                                                isBasicSearch={true}
                                                                selectedStories={selectedStories}
                                                                isStorySelected={
                                                                    selectedStories.findIndex(
                                                                        arr => arr.id === story.id
                                                                    ) === -1
                                                                        ? false
                                                                        : true
                                                                }
                                                                addToSelection={addToSelection}
                                                                allCategories={allCategories}
                                                                allSubCategories={allSubCategories}
                                                                allIndustries={allIndustries}
                                                                allCompanies={allCompanies}
                                                                setAllCompanies={setAllCompanies}
                                                                allRegions={allRegions}
                                                                allLinkedInGroups={allLinkedInGroups}
                                                                removeFromSelection={removeFromSelection}
                                                                allTopics={allTopics}
                                                                collapsed={!expandedView}
                                                                usertype={usertype}
                                                                selectAll={isAllSelected}
                                                                currentPath={currentPath}
                                                                selectedNewsOnly={selectedNewsOnly}
                                                                isScore={isScore}
                                                                selectedLevel={selectedLevel}
                                                            />
                                                        )
                                                    })}
                                                </InfiniteScroll>
                                            )}
                                        </Grid>
                                    ) : (
                                        <>{renderData()}</>
                                    )}
                                </div>
                            </>
                        )}
                    {chatModal && (
                        <div
                            style={{
                                width: expand ? '79%' : '32%',
                                background: 'white',
                                top: minContext ? '90%' : '25%',
                                zIndex: '5',
                                right: '10px',
                                position: 'fixed',
                                boxShadow: '0 0 10px',
                                borderRadius: ' 9px',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '50px',
                                    background: 'black',
                                    color: 'white',
                                    borderRadius: '9px 9px 0px 0px',
                                }}
                            >
                                <p
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '27px',
                                        padding: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        // justifyContent: 'center',
                                        fontWeight: '700',
                                    }}
                                >
                                    <span style={{ fontSize: '20px', fontWeight: '600', marginTop: '5PX' }}>Chat</span>
                                    <span
                                        onClick={() => {
                                            setExpand(!expand)
                                        }}
                                        className="modal_close_btn mins"
                                        style={{ marginLeft: 'auto', marginRight: '5px', fontSize: '23px' }}
                                    >
                                        {expand ? <FiMinimize2 /> : <AiOutlineExpandAlt />}
                                    </span>
                                    <span
                                        onClick={() => {
                                            setMinContext(!minContext)
                                        }}
                                        className="modal_close_btn mins"
                                        style={{ marginRight: '5px' }}
                                    >
                                        {minContext ? <BsFullscreen /> : <AiOutlineMinus />}
                                    </span>
                                    <span
                                        onClick={() => {
                                            setChatModal(false)
                                            setNewMessage('')
                                            setResponseMessage([])
                                            setMessages([{ text: 'Hi, How can I help? ', sender: 'sender' }])
                                        }}
                                        className="modal_close_btn close "
                                        style={{ marginRight: '5px' }}
                                    >
                                        <AiOutlineClose />
                                    </span>
                                </p>
                            </div>
                            {basicSearchResponse.data && basicSearchResponse.data.length > 0 && (
                                <div className="chat-container" style={{ display: minContext ? 'none' : '' }}>
                                    <div className="message-list">
                                        {messages.map((message, index) => (
                                            <div
                                                key={index}
                                                className={message.sender === 'user' ? 'message user' : 'sender'}
                                            >
                                                <p
                                                    style={{
                                                        marginLeft: '20px',
                                                        color: 'black',
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        fontSize: '14px',
                                                    }}
                                                    id={
                                                        message.sender === 'user'
                                                            ? `user_id_${index}`
                                                            : `sender_id_${index}`
                                                    }
                                                >
                                                    {message.text}
                                                </p>
                                            </div>
                                        ))}
                                        {isTyping && (
                                            <div className="sender">
                                                <p
                                                    style={{
                                                        marginLeft: '20px',
                                                        color: 'black',
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {responseMessage.join('')}
                                                </p>
                                            </div>
                                        )}
                                        {typing && (
                                            <div className="sender">
                                                <p
                                                    style={{
                                                        marginLeft: '20px',
                                                        color: 'black',
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    ....
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div class="search">
                                        <input
                                            type="text"
                                            className="chatboxinput"
                                            placeholder="Type a message..."
                                            value={newMessage}
                                            onChange={e => setNewMessage(e.target.value)}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    handleSendMessage()
                                                }
                                            }}
                                            style={{ border: 'none', width: '90%' }}
                                        />
                                        <IoMdSend className="sendico" onClick={handleSendMessage} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {(activeTab === 1 || activeTab == 3) && (
                <PowerSearchTab
                    editPage={editPage}
                    setEditPage={setEditPage}
                    editId={editId}
                    setEditId={setEditId}
                    editqueryName={editqueryName}
                    setEditQueryName={setEditQueryName}
                    directRun={directRun}
                    setDirectRun={setDirectRun}
                    allCategories={allCategories}
                    selectedQueries={selectedQueries}
                    setSelectedQueries={setSelectedQueries}
                    allSources={allSources}
                    setAllSources={setAllSources}
                    isQueryEditor={isQueryEditor}
                    setIsQueryEditor={setIsQueryEditor}
                    isQueryEditorJson={isQueryEditorJson}
                    setIsQueryEditorJson={setIsQueryEditorJson}
                    editorQuery={editorQuery}
                    setEditorQuery={setEditorQuery}
                    selectedStories={selectedStories}
                    setSelectedStories={setSelectedStories}
                    level={level}
                    setLevel={setLevel}
                    activeTab={activeTab}
                    advanceResponse={advanceResponse}
                    setAdvanceResponse={setAdvanceResponse}
                    preeditPage={preeditPage}
                    setPreeditPage={setPreeditPage}
                    runQuery={runQuery}
                    setRunQuery={setRunQuery}
                    allRegions={allRegions}
                    setAllRegions={setAllRegions}
                    allTopics={allTopics}
                    setAllTopics={setAllTopics}
                    allCompanies={allCompanies}
                    setAllCompanies={setAllCompanies}
                    allIndustries={allIndustries}
                    setAllIndustries={setAllIndustries}
                    allSubCategories={allSubCategories}
                    setAllSubCategories={setAllSubCategories}
                    addToSelection={addToSelection}
                    removeFromSelection={removeFromSelection}
                    premeetingPlan={premeetingPlan}
                    setPremeetingPlan={setPremeetingPlan}
                    regionHierarchy={regionHierarchy}
                    setRegionHierarchy={setRegionHierarchy}
                    apiLevel={apiLevel}
                    currentPath={currentPath}
                    selectedCompanies={selectedCompanies}
                    setSelectedCompanies={setSelectedCompanies}
                    getAllCompanies={getAllCompanies}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    emailSubject={emailSubject}
                    setEmailSubject={setEmailSubject}
                    includeBusiness={includeBusiness}
                    setIncludeBusiness={setIncludeBusiness}
                    includeSection={includeSection}
                    setIncludeSection={setIncludeSection}
                    selectedLevel={selectedLevel}
                    setSelectedLevel={setSelectedLevel}
                    collectionDescription={collectionDescription}
                    setCollectionDescription={setCollectionDescription}
                    collectionShared={collectionShared}
                    setCollectionShared={setCollectionShared}
                    allCollectionUsers={allCollectionUsers}
                    collectionUsers={collectionUsers}
                    setCollectionUsers={setCollectionUsers}
                    collectionName={collectionName}
                    setCollectionName={setCollectionName}
                    allCollectionName={allCollectionName}
                    saveModal={semanticSaveModal}
                    setSaveModal={setSemanticSaveModal}
                    saveSemanticQuery={saveSemanticQuery}
                    editSemanticQuery={editSemanticQuery}
                    saveQueryName={saveQueryName}
                    setSaveQueryName={setSaveQueryName}
                    collectionModal={collectionModal}
                    setCollectionModal={setCollectionModal}
                    collectionPrivacy={collectionPrivacy}
                    setCollectionPrivacy={setCollectionPrivacy}
                    collectionShareList={collectionShareList}
                    collectionId={collectionId}
                    getAllCollections={getAllCollections}
                />
            )}

            {activeTab === 2 && (
                <SemanticSearch
                    allCategories={allCategories}
                    selectedStories={selectedStories}
                    setSelectedStories={setSelectedStories}
                    activeTab={activeTab}
                    allSubCategories={allSubCategories}
                    allIndustries={allIndustries}
                    allCompanies={allCompanies}
                    allTopics={allTopics}
                    allRegions={allRegions}
                    collapsed={!expandedView}
                    expandedView={expandedView}
                    setExpandedView={setExpandedView}
                    selectedNewsOnly={selectedNewsOnly}
                    allSelected={isAllSelected}
                    setAllSelected={setAllSelected}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    addToSelection={addToSelection}
                    removeFromSelection={removeFromSelection}
                    chat={chat}
                    setChat={setChat}
                />
            )}

            {activeTab === 4 && (
                <SavedQueries
                    setActiveTab={setActiveTab}
                    editPage={editPage}
                    setEditPage={setEditPage}
                    editId={editId}
                    setEditId={setEditId}
                    editqueryName={editqueryName}
                    setEditQueryName={setEditQueryName}
                    directRun={directRun}
                    setDirectRun={setDirectRun}
                    allSubCategories={allSubCategories}
                    allIndustries={allIndustries}
                    allCompanies={allCompanies}
                    allTopics={allTopics}
                    allRegions={allRegions}
                    allSources={allSources}
                    selectedQueries={selectedQueries}
                    setSelectedQueries={setSelectedQueries}
                    isQueryEditor={isQueryEditor}
                    setIsQueryEditor={setIsQueryEditor}
                    isQueryEditorJson={isQueryEditorJson}
                    editorQuery={editorQuery}
                    setEditorQuery={setEditorQuery}
                    setIsQueryEditorJson={setIsQueryEditorJson}
                    runQuery={runQuery}
                    setRunQuery={setRunQuery}
                    usertype={usertype}
                    setBasicSearchInput={setBasicSearchInput}
                    handleQuickPopulate={handleQuickPopulate}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    emailSubject={emailSubject}
                    setEmailSubject={setEmailSubject}
                    setSelectedOption={setSelectedOption}
                    includeBusiness={includeBusiness}
                    setIncludeBusiness={setIncludeBusiness}
                    includeSection={includeSection}
                    setIncludeSection={setIncludeSection}
                    selectedLevel={selectedLevel}
                    setSelectedLevel={setSelectedLevel}
                    currentPath={currentPath}
                    allCollectionName={allCollectionName}
                    collectionId={collectionId}
                    setCollectionId={setCollectionId}
                    collectionDescription={collectionDescription}
                    setCollectionDescription={setCollectionDescription}
                    collectionName={collectionName}
                    setCollectionName={setCollectionName}
                    collectionPrivacy={collectionPrivacy}
                    setCollectionPrivacy={setCollectionPrivacy}
                    collectionUsers={collectionUsers}
                    setCollectionUsers={setCollectionUsers}
                    collectionShareList={collectionShareList}
                    allCollectionUsers={allCollectionUsers}
                    getAllCollections={getAllCollections}
                />
            )}

            {activeTab === 5 && (
                <PredefinedQueries
                    editPage={editPage}
                    setEditPage={setEditPage}
                    editId={editId}
                    setEditId={setEditId}
                    setActiveTab={setActiveTab}
                    editqueryName={editqueryName}
                    setEditQueryName={setEditQueryName}
                    directRun={directRun}
                    setDirectRun={setDirectRun}
                    setEditorQuery={setEditorQuery}
                    setIsQueryEditorJson={setIsQueryEditorJson}
                    preeditPage={preeditPage}
                    setPreeditPage={setPreeditPage}
                />
            )}

            {premeetingModel && (
                <PreMeetingBriefModal
                    premeetingData={PremeetingResponse}
                    setPremeetingPreview={setPremeetingModal}
                    premeetingPreview={premeetingModel}
                    preMeetingLoader={premeetingLoader}
                    isFindAndShare={true}
                    companyInformation={PremeetingResponse}
                    token={token}
                />
            )}
            <SavedQueriesModal
                collectionDescription={collectionDescription}
                setCollectionDescription={setCollectionDescription}
                collectionShared={collectionShared}
                setCollectionShared={setCollectionShared}
                allCollectionUsers={allCollectionUsers}
                collectionUsers={collectionUsers}
                setCollectionUsers={setCollectionUsers}
                collectionName={collectionName}
                setCollectionName={setCollectionName}
                allCollectionName={allCollectionName}
                editPage={editPage}
                openModal={semanticSaveModal}
                setOpenModal={setSemanticSaveModal}
                saveSemanticQuery={saveSemanticQuery}
                editSemanticQuery={editSemanticQuery}
                editqueryName={editqueryName}
                setEditQueryName={setEditQueryName}
                saveQueryName={saveQueryName}
                setSaveQueryName={setSaveQueryName}
                collectionModal={collectionModal}
                setCollectionModal={setCollectionModal}
                collectionPrivacy={collectionPrivacy}
                setCollectionPrivacy={setCollectionPrivacy}
                collectionShareList={collectionShareList}
                isQuickSearch={true}
            />
        </>
    )
}
