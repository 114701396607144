import React, { useEffect, useRef, useState } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import './InsightsAssistant.css'
import { AiOutlineSave } from 'react-icons/ai'
import { IoIosClose, IoMdClose } from 'react-icons/io'
import { BiEraser } from 'react-icons/bi'
import { CgNotes } from 'react-icons/cg'
import { Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from 'reactstrap'
import { Chip, Grid, Autocomplete, TextField, createFilterOptions, Tab, Tabs, Box, Button } from '@mui/material'
import axios from 'axios'
import CronTab from '../../AnalystAdmin/CronTab'
import { toast } from 'react-toastify'
import store from '../../../Store'
import SendIcon from '@mui/icons-material/Send'
import CancelIcon from '@mui/icons-material/Cancel'
import { getFullFormattedDate, getYMD } from '../../../utils/dateHelpers'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import ArrowBackIosNewSharp from '@mui/icons-material/ArrowBackIosNewSharp'
import ArrowForwardIosSharp from '@mui/icons-material/ArrowForwardIosSharp'
import NewSearchQueryResult from '../../../components/NewsEditedCard/NewSearchQueryResult'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FilterListIcon from '@mui/icons-material/FilterList'
import InfiniteScroll from 'react-infinite-scroller'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ChatRecommendationCards from './ChatRecommendationCards'
import RenderChatCards from './RenderChatCards'
import QuickReportPreview from './QuickReportPreview'
import DetailedReportPreview from './DetailedReportPreview'
import RenderInsightsCards from './RenderInsightsCards'
import companyIcon from '../../../assets/icons/companyReport.svg'
import sectorIcon from '../../../assets/icons/sectorReport.svg'
import alertsIcon from '../../../assets/icons/alerts.svg'
import emailIcon from '../../../assets/icons/emailOutreach.svg'
import linkedInIcon from '../../../assets/icons/linkedin.svg'
import thoughtIcon from '../../../assets/icons/thoughtLeadership.svg'
import appleIcon from '../../../assets/icons/apple.svg'
import gavelIcon from '../../../assets/icons/gavel.svg'
import pharmaIcon from '../../../assets/icons/pharma.svg'
import dealIcon from '../../../assets/icons/deal.svg'
import bucketIcon from '../../../assets/icons/bucket.svg'
import { Circles } from 'react-loader-spinner'
import { AiOutlineDownload } from 'react-icons/ai'
import { FiDownload } from 'react-icons/fi'
import QuickAdvanceFilters from './QuickAdvanceFilters'
import { LastCronValue } from '../../../utils/dashboardStrings'

const filter = createFilterOptions()

const sections = [
    { label: 'Business Events', value: 'subcategories' },
    { label: 'Company', value: 'companies' },
    { label: 'Industry', value: 'industries' },
    { label: 'Sector', value: 'sectors' },
    { label: 'Topic', value: 'topics' },
]

export default function InsightsAssistant() {
    const [userID, setUserID] = useState(null)
    const usertype = store.getState().auth.user.user_type
    const userEmail = store.getState().auth.user.email

    useEffect(() => {
        getUsers()
        if (usertype === 'end_user') {
            getUserData()
        }
        getAllSectors()
        getTemplates()
        getRole()
        getData('/news/all-companies/', setAllCompanies)
        getData('/news/industries-sectors-topics/', setAllHierarchy)
        getData('/news/sources/', setAllSources)
        getData('/news/all-subcategories/', setAllSubCategories)
        getAllHierRegions()
        setUserID(store.getState().auth.user.id)
    }, [userID !== null])

    const getData = (api, setState) => {
        axios.get(api).then(res => setState(res.data))
    }

    useEffect(() => {
        const assistantNavlink = document.getElementById('assistant_navlink')
        const handleClick = () => {
            setMessages([])
            setSelectedState('default')
            setOptionData([])
            setChatLoader(false)
            setChatDownload({})
            setNewMessage('')
            setShowSection(false)
        }

        if (assistantNavlink) {
            assistantNavlink.addEventListener('click', handleClick)
        }

        return () => {
            if (assistantNavlink) {
                assistantNavlink.removeEventListener('click', handleClick)
            }
        }
    }, [])

    const [allUsers, setAllUsers] = useState([])
    const [isEnabled, setIsEnabled] = useState(true)
    const [cronTabValue, setCronTabValue] = useState('0 0 * * 1')
    const [templates, setTemplates] = useState([])
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [emailSubject, setEmailSubject] = useState('')
    const [includeSection, setIncludeSection] = useState(false)
    const [includeBusiness, setIncludeBusiness] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [SchedulingQueryName, setSchedulingQueryName] = useState('')
    const [SchedulingQuery, setSchedulingQuery] = useState('')
    // const [loadingIndex, setLoadingIndex] = useState(null)
    const [allSectors, setAllSectors] = useState([])
    const [userData, setUserData] = useState([])
    const [role, setRole] = useState([])
    const [subTab, setSubTab] = useState(0)

    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState('')
    const [selectedState, setSelectedState] = useState('default')
    const [openOPtions, setOpenOPtions] = useState(false)
    const [optionData, setOptionData] = useState([])
    const [chatLoader, setChatLoader] = useState(false)
    const [chatDownload, setChatDownload] = useState({})
    const [iframeReport, setIframeReport] = useState(false)
    const [expandedView, setExpandedView] = useState(false)
    const [quickReportModal, setQuickReportModal] = useState(false)
    const [reportView, setReportView] = useState(null)
    const [detailedReportModal, setDetailedReportModal] = useState(false)
    const [showSection, setShowSection] = useState(false)
    const [thoughtSection, setThoughtSection] = useState(false)
    const [isAllSelected, setAllSelected] = useState(false)
    const [selectedNewsOnly, setSelectedNewsOnly] = useState(false)
    const [selectedStories, setSelectedStories] = useState([])
    const [basicSearchResponse, setBasicSearchResponse] = useState({ data: [], next: null })
    const [sortBy, setSortBy] = useState('date')
    const [newsFilters, setNewsFilters] = useState(false)
    const [loadmoreFilter, setLoadMoreFilter] = useState({ value: null, type: null })
    const [draftSection, setDraftSection] = useState(false)
    const [draftLoader, setDraftLoader] = useState(false)
    const [draftContent, setDraftContent] = useState(null)
    const [defaultRecommendationSearch, setDefaultRecommendationSearch] = useState(true)
    const [ideaSection, setIdeaSection] = useState(false)
    const [generateIdeas, setGenerateIdeas] = useState([])

    const [allTopics, setAllTopics] = useState([])
    const [allRegions, setAllRegions] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [allIndustries, setAllIndustries] = useState([])
    const [allSubCategories, setAllSubCategories] = useState([])
    const [allSources, setAllSources] = useState([])
    const [allHierarchy, setAllHierarchy] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedRegions, setSelectedRegions] = useState([])
    const [selectedSources, setSelectedSources] = useState([])
    const [practiceSubCategory, setPracticeSubCategory] = useState([])
    const [selectedOption, setSelectedOption] = useState('29')
    const [dateSection, setDateSection] = useState(false)
    const [searchDrop, setSearchDrop] = useState(false)
    const [defaultData, setDefaultData] = useState([])
    const [selectedSubCategories, setSelectedSubCategories] = useState([])

    const [cancelToken, setCancelToken] = useState(null)
    const [token, setToken] = useState(null)
    const [progressContent, setProgressContent] = useState(null)
    const [summaryLoader, setSummaryLoader] = useState(false)
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const toggle = () => setTooltipOpen(!tooltipOpen)

    // const [debouncedInputValue, setDebouncedInputValue] = useState('')

    // useEffect(() => {
    //     const timerId = setTimeout(() => {
    //         setDebouncedInputValue(newMessage)
    //     }, 100) // Adjust the debounce delay as needed

    //     return () => {
    //         clearTimeout(timerId)
    //     }
    // }, [newMessage])

    useEffect(() => {
        isAllSelected
            ? setSelectedStories(current => [
                  ...current,
                  ...basicSearchResponse.data.filter(each => !selectedStories.includes(each)),
              ])
            : setSelectedStories([])
    }, [isAllSelected])

    useEffect(() => {
        if (cancelToken) {
            cancelToken.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setCancelToken(newCancelSource)
        if (newMessage.length < 2) {
            setOptionData([])
        }
        if (
            openOPtions &&
            (selectedState === 'Company' ||
                selectedState === 'alert' ||
                selectedState === 'SocialClient' ||
                selectedState === 'Thought') &&
            newMessage.length > 2
        ) {
            axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(newMessage)}`, {
                    cancelToken: newCancelSource.token,
                })
                .then(response => {
                    const filteredData = response.data
                        .filter(
                            item =>
                                item.name.toLowerCase().startsWith(newMessage.toLowerCase()) ||
                                (item.alias && item.alias.toLowerCase().includes(newMessage.toLowerCase()))
                        )
                        .map(item => ({
                            value: item.id,
                            label: item.name,
                            type: 'company',
                        }))
                    const uniqueData = new Set([...filteredData])
                    const arrayFromSet = [...uniqueData]
                    if (selectedState === 'alert' || selectedState === 'SocialClient' || selectedState === 'Thought') {
                        const filteredTopics = allSectors.filter(item =>
                            item.label.toLowerCase().startsWith(newMessage.toLowerCase())
                        )
                        setOptionData([...filteredTopics, ...arrayFromSet])
                    } else {
                        setOptionData(arrayFromSet)
                    }
                })
                .catch(err => console.log(err))
            // .finally(() => {
            //     setChatLoader(false)
            // })
        }

        return () => {
            if (cancelToken) {
                cancelToken.cancel('Component unmounted.')
            }
        }
    }, [newMessage])

    // const wrapperRef = useRef(null)
    // closeExpandable(wrapperRef)
    // function closeExpandable(ref) {
    //     function handleClickOutside(event) {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //             if (selectedState === 'Thought') {
    //                 setSearchDrop(false)
    //             }
    //         }
    //     }
    //     document.addEventListener('mousedown', handleClickOutside)
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside)
    //     }
    // }

    const handleReportsMatches = (type, texts, selectedtype) => {
        const text = [texts]
        let currentIndex = 0
        const typingInterval = setInterval(() => {
            // setIsTyping(true)
            if (currentIndex < text.length) {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })

                currentIndex++
            } else {
                // setIsTyping(false)
                clearInterval(typingInterval)

                const senderMessage = {
                    text: text,
                    sender: 'sender',
                    type: type,
                    report: 'company',
                    optionType: selectedtype,
                    section_type: selectedState,
                }
                setMessages(prevMessages => [...prevMessages, senderMessage])
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
            }
        }, 0)
        setNewMessage('')
        // setChatLoader(false)
        // setTyping(true)
    }

    useEffect(() => {
        if (selectedState !== 'Thought') {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        }
    }, [messages, chatLoader])

    const handleReports = (text, type, optionType) => {
        let useDetailed_Report = false

        if (type === 'Quick Report') {
            const completionTime = 120000
            const responseTime = 120000
            setProgressContent('Working on it...(5%)')
            setTimeout(() => {
                progressContents(responseTime, completionTime, text[0], type)
            }, 5000)

            const keyName = optionType === 'company' ? 'company' : 'sectors'

            var startDate = new Date()
            const dateFill = selectedState === 'Company' ? 180 : 29
            startDate.setDate(startDate.getDate() - dateFill)

            const payload = {
                filters: {
                    articleDate: {
                        operator: 'BETWEEN',
                        from: getYMD(startDate),
                        to: getYMD(new Date()),
                    },
                },
                relevance_cutoff: 13,
                semantic_preference: 0.5,
                // type: 'level2',
                download_report: true,
                report_type: type,
            }
            if (optionType === undefined) {
                payload.query = text[0]
            }

            if (optionType && optionType.length > 0) {
                payload.non_entity_query = text[0]
                payload.filters[keyName] = {
                    operator: 'IS',
                    values: text,
                }
                payload.type =
                    optionType === 'sectors'
                        ? 'sector'
                        : optionType === 'topics'
                        ? 'topic'
                        : optionType === 'industries'
                        ? 'industry'
                        : 'company'
            }
            const start = new Date().getTime()

            axios
                .post('/semantic/quick-search/', payload)
                .then(res => {
                    const data = res.data
                    const end = new Date().getTime() // End time
                    const duration = end - start
                    if (data.data) {
                        const senderMessage = {
                            text: `Successfully prepared a ${
                                selectedState === 'Company' ? 'company' : 'sector'
                            } report for ${text}`,
                            sender: 'sender',
                            type: type,
                            document: data,
                            query: data.name,
                            section_type: selectedState,
                        }
                        if (duration >= responseTime) {
                            setMessages(prevMessages => [...prevMessages, senderMessage])
                            setProgressContent(null)
                            setChatLoader(false)
                        } else {
                            setTimeout(() => {
                                setMessages(prevMessages => [...prevMessages, senderMessage])
                                setProgressContent(null)
                                setChatLoader(false)
                            }, 2000)
                        }
                    } else {
                        const senderMessage = {
                            text: `Great! Report on ${text[0]}, will be sent to you at ${userEmail}`,
                            sender: 'sender',
                            type: type,
                            section_type: selectedState,
                        }
                        setMessages(prevMessages => [...prevMessages, senderMessage])
                        setProgressContent(null)
                        setChatLoader(false)
                    }
                })
                .catch(err => {
                    console.log(err)
                    simulateTypingEffect('Sorry, there was an error in creating this report. Try again?', selectedState)
                    setProgressContent(null)
                    setChatLoader(false)
                })
                .finally(() => {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                    // setChatLoader(false)
                })
        }

        if (useDetailed_Report || type === 'Detailed Report') {
            // const completionTime = 210000
            // const responseTime = 210000
            // setProgressContent('Working on it...(5%)')
            // setTimeout(() => {
            //     progressContents(responseTime, completionTime, text[0], type)
            // }, 5000)
            let payloads = {
                user: userID,
                query: text[0],
                report_type: type,
            }

            axios.post('/news/report-request/', payloads).then(res => {
                simulateTypingEffect(
                    `Great! Report on ${text}, will be sent to you within 3 business days at <b>${userEmail}</b>`,
                    selectedState
                )
                setChatLoader(false)

                if (res.data) {
                    const keyName = optionType === 'company' ? 'company' : 'sectors'

                    var startDate = new Date()
                    const detailDateFill = selectedState === 'Company' ? 180 : 29
                    startDate.setDate(startDate.getDate() - detailDateFill)

                    const payload = {
                        filters: {
                            articleDate: {
                                operator: 'BETWEEN',
                                from: getYMD(startDate),
                                to: getYMD(new Date()),
                            },
                        },
                        relevance_cutoff: 13,
                        semantic_preference: 0.5,
                        // type: 'level2',
                        download_report: true,
                        report_type: type,
                        detailed_report_id: res.data,
                    }
                    if (optionType === undefined) {
                        payload.query = text[0]
                    }

                    if (optionType && optionType.length > 0) {
                        payload.non_entity_query = text[0]
                        payload.filters[keyName] = {
                            operator: 'IS',
                            values: text,
                        }
                        payload.type =
                            optionType === 'sectors'
                                ? 'sector'
                                : optionType === 'topics'
                                ? 'topic'
                                : optionType === 'industries'
                                ? 'industry'
                                : 'company'
                    }
                    const start = new Date().getTime()

                    axios.post('/semantic/quick-search/', payload).then(res => {
                        // const data = res.data
                        // const end = new Date().getTime() // End time
                        // const duration = end - start
                        // // Ticker not available in DB
                        // if (data && data !== 'Ticker not available in DB') {
                        //     const senderMessage = {
                        //         text: `Successfully prepared a ${
                        //             selectedState === 'Company' ? 'company' : 'sector'
                        //         } report for ${text}`,
                        //         sender: 'sender',
                        //         type: type,
                        //         document: data,
                        //         query: data.name,
                        //         section_type: selectedState,
                        //     }
                        //     if (duration >= responseTime) {
                        //         setMessages(prevMessages => [...prevMessages, senderMessage])
                        //         setProgressContent(null)
                        //         setChatLoader(false)
                        //     } else {
                        //         // setProgressContent('Final Touches')
                        //         setTimeout(() => {
                        //             setMessages(prevMessages => [...prevMessages, senderMessage])
                        //             setProgressContent(null)
                        //             setChatLoader(false)
                        //         }, 2000)
                        //     }
                        // } else {
                        //     const senderMessage = {
                        //         text: `This report needs some analyst intervention to be delivered at the right quality. Your report will be delivered at <b>${userEmail}</b> within 3 business days.`,
                        //         sender: 'sender',
                        //         type: type,
                        //         section_type: selectedState,
                        //     }
                        //     setMessages(prevMessages => [...prevMessages, senderMessage])
                        //     setProgressContent(null)
                        //     setChatLoader(false)
                        // }
                    })
                    // .catch(err => {
                    //     console.log(err)
                    //     simulateTypingEffect(
                    //         'Sorry, there was an error in creating this report. Try again?',
                    //         selectedState
                    //     )
                    //     setProgressContent(null)
                    //     setChatLoader(false)
                    // })
                    // .finally(() => {
                    //     window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                    //     // setChatLoader(false)
                    // })
                }
            })
        }
    }

    const progressSteps = [
        'Searching Kaitongo news database for ',
        'Validating news results',
        'Searching for financial data',
        'Summarising news and financial data',
        'Writing strategic priorities and risks',
        'Writing executive summary',
        'Composing the report',
        'Final touches',
    ]

    const QuickprogressSteps = [
        'Searching Kaitongo news database for ',
        'Validating news results',
        'Searching for financial data',
        'Summarising news and financial data',
        'Composing the report',
        'Final touches',
    ]

    const progressContents = (responseTime = 210000, completionTime = 210000, name, type) => {
        const stepsArray = type === 'Quick Report' ? QuickprogressSteps : progressSteps

        stepsArray.forEach((step, index) => {
            setTimeout(() => {
                const percentage = Math.round(((index + 1) / stepsArray.length) * 100)
                console.log(percentage)
                const displayPercentage = percentage > 94 ? 94 : percentage
                const steps = `${index === 0 ? step + '' + name : step}...(${displayPercentage}%)`
                setProgressContent(steps)
            }, index * (responseTime / stepsArray.length))
        })

        // Ensure to display the final message after all steps are shown
        // setTimeout(() => {
        //     setChatLoader(false)
        //     setProgressContent(null)
        // }, responseTime)
    }

    const pdfDownLoad = () => {
        var link = document.createElement('a')
        link.href = chatDownload.document
        link.download = 'downloaded_file.pdf'
        link.target = '_blank'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const wordDownload = () => {
        var html =
            "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Drafts</title></head><body>"
        var element = document.getElementById('draft_content').innerHTML
        var footer = '</body></html>'
        // var html = html+document.getElementById(element).innerHTML+footer;
        var html = html + element + footer

        //link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)

        //file name
        const filename = newMessage.length > 0 ? newMessage + '.doc' : 'document.doc'

        // Creates the  download link element dynamically
        var downloadLink = document.createElement('a')

        document.body.appendChild(downloadLink)

        //Link to the file
        downloadLink.href = url

        //Setting up file name
        downloadLink.download = filename

        //triggering the function
        downloadLink.click()
        //Remove the a tag after donwload starts.
        document.body.removeChild(downloadLink)
    }

    const reportTypes = {
        'Quick Report':
            selectedState === 'Company'
                ? 'A brief snapshot of the company, highlighting key developments. Ideal for early-stage targeting and prospecting.​'
                : 'A brief snapshot of the sector, highlighting key developments. Ideal for a quick review of developments.​',
        'Detailed Report':
            selectedState === 'Company'
                ? 'A comprehensive analysis of the company and its sector. Suitable for key accounts and companies you are already familiar with.'
                : 'A comprehensive analysis of the sector with key trends, growth drivers and competitive forces. Suitable for strategizing and planning.',
    }

    const handleSendMessage = type => {
        if (newMessage.trim() !== '') {
            if (type === 'default') {
                setChatLoader(true)

                const userMessage = { text: newMessage, sender: 'user', type: type, section_type: selectedState }
                setMessages(prevMessages => [...prevMessages, userMessage])
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                axios
                    .post('/semantic/semantic-chat/', {
                        question: newMessage,
                    })
                    .then(res => {
                        if ((res.data && res.data.summary) || (res.data && res.data.news && res.data.news.length > 0)) {
                            const message = {
                                text: res.data.summary,
                                sender: 'sender',
                                news: res.data.news,
                                type: type,
                                section_type: selectedState,
                            }
                            setMessages(history => [...history, message])
                        } else {
                            const message = {
                                text: `I apologize, but that task is beyond the scope of my capabilities. Please review the below options to understand what I can help you with.`,
                                sender: 'sender',
                                type: type,
                                section_type: selectedState,
                            }
                            setMessages(history => [...history, message])
                        }
                    })
                    .finally(() => {
                        setChatLoader(false)
                        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                    })
            }

            if (selectedState !== 'Thought') {
                setNewMessage('')
            }
            if (selectedState === 'Thought') {
                setSearchDrop(false)
            }

            // setTyping(true)
            // setChatLoader(false)
        }
    }

    const simulateTypingEffect = (text, type) => {
        let currentIndex = 0
        const typingInterval = setInterval(() => {
            // setIsTyping(true)
            if (currentIndex < text.length) {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })

                currentIndex++
            } else {
                // setIsTyping(false)
                clearInterval(typingInterval)

                const senderMessage = {
                    text: text,
                    sender: 'sender',
                    type: type,
                    section_type: type,
                }
                setMessages(prevMessages => [...prevMessages, senderMessage])
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
            }
        }, 0)
        setChatLoader(false)
    }

    const reports = ['Company', 'Sector']

    const getUsers = () => {
        axios.get('/endusers/firm-users/').then(res => {
            setAllUsers(res.data)
        })
    }

    const getTemplates = () => {
        axios.get('/endusers/templates/').then(response => {
            setTemplates(
                response.data
                    .filter(each => each.custom_template === false)
                    .map(each => {
                        return {
                            value: each.id,
                            label: each.name,
                            imageUrl: each.thumbnail,
                            has_sections: each.has_sections,
                        }
                    })
            )
        })
    }

    const getAllHierRegions = () => {
        axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)
        })
    }
    const getRole = () => {
        axios
            .get(`/accounts/user-roles/`)
            .then(response => {
                setRole(response.data)
            })
            .catch(err => console.log(err))
    }

    const getUserData = () => {
        if (userID !== null && userID !== undefined && userID !== '') {
            axios
                .get(`/endusers/all-endusers/?pk=${userID}`)
                .then(res => {
                    setUserData(res.data)
                })
                .catch(err => console.log(err))
        }
    }

    const getAllSectors = () => {
        axios
            .get('/news/industries-sectors-topics/')
            .then(res => {
                const flattenData = data => {
                    const flattenedArray = []

                    data.forEach(item => {
                        flattenedArray.push({ value: item.id, label: item.name, type: 'industries' })

                        if (item.sectors) {
                            item.sectors.forEach(sector => {
                                flattenedArray.push({ value: sector.id, label: sector.name, type: 'sectors' })

                                if (sector.topics) {
                                    sector.topics.forEach(topic => {
                                        flattenedArray.push({ value: topic.id, label: topic.name, type: 'topics' })
                                    })
                                }
                            })
                        }
                    })

                    return flattenedArray
                }

                const flattenedData = flattenData(res.data)

                setAllSectors(flattenedData)
            })
            .catch(err => console.log(err))
    }

    const getRecommendations = (type, question = null) => {
        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)

        const userMessage = {
            sender: 'user',
            type: type,
            text: '',
            section_type: 'SocialClient',
        }
        // if (question) {
        //     userMessage.text = question
        // } else {
        //     userMessage.text = `Give me recommendations for social media posts`
        // }
        setMessages(prevMessages => [...prevMessages, userMessage])
        setNewMessage('')

        setSelectedState(type)

        let endpoint = `/semantic/semantic-chat/?action=${type}`

        if (question) {
            endpoint += `&question=${question}`
            setChatLoader(true)

            const arr = messages.filter(val => val.question && val.question === null).map(val => val)

            if (arr.length > 0) {
                arr.splice(-1)
            }
            setMessages(arr)
        }

        axios
            .get(endpoint, { cancelToken: newCancelSource.token })
            .then(res => {
                setChatLoader(false)
                const botMessage = {
                    sender: 'sender',
                    // text:
                    //     res.data.data.length === 0
                    //         ? 'Can't Find recommendations post for your settings. Please use free search below'
                    //         : question
                    //         ? 'Certainly! Here are some social media recommendations for you based on your search'
                    //         : `Certainly! Here are some social media recommendations for you based on your settings.`,
                    text: '',
                    type: type,
                    recommendationPosts: res.data,
                    seeMore: res.data.next ? true : false,
                    showExtra: question ? false : res.data && res.data.data && res.data.data.length > 0 ? true : false,
                    posts: true,
                    question: question,
                    section_type: 'SocialClient',
                }
                setMessages(prevMessages => [...prevMessages, botMessage])
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setChatLoader(true)
                } else {
                    setChatLoader(false)
                    console.log('POST err:', err.message)
                    simulateTypingEffect(
                        `I Apologize, unable to fetch recommendations. Please try again`,
                        selectedState
                    )
                }
            })
            .finally(() => {
                // setChatLoader(false)
            })

        // setChatLoader(false)
    }

    const allRegionName = regionHierarchy.map(val => val.name)

    const allCountryName = regionHierarchy
        .map(val => val.countries.map(cty => cty.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allProvinceName = regionHierarchy
        .map(val => val.countries.map(cty => cty.provinces.map(pro => pro.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const weviateSearch = (dropdownValue, type, searchtext) => {
        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)
        setChatLoader(true)
        setMessages([])
        setBasicSearchResponse({ data: [], next: null })
        setLoadMoreFilter({ value: null, type: null })
        setSelectedStories([])

        var startDate = new Date()
        var endDate = new Date()

        startDate.setDate(startDate.getDate() - selectedOption)
        startDate = getYMD(startDate)
        endDate = getYMD(endDate)

        const keyName =
            type === 'topics'
                ? 'topics'
                : type === 'SubCategory'
                ? 'category'
                : type === 'company'
                ? 'company'
                : 'sectors'
        if (dropdownValue !== undefined) {
            setLoadMoreFilter({ value: dropdownValue, type: keyName })
        } else {
            setLoadMoreFilter({ value: null, type: null })
        }

        const allIndName = allSectors.filter(acc => acc.type === 'industries').map(val => val.label)

        const allSecName = allSectors.filter(acc => acc.type === 'industries').map(val => val.label)

        const allTopName = allSectors.filter(acc => acc.type === 'topics').map(val => val.label)

        const payload = {
            filters: {
                articleDate: {
                    operator: 'BETWEEN',
                    from: getYMD(startDate),
                    to: getYMD(new Date()),
                },
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                company: {
                    operator: 'IS',
                    values: type === 'company' ? [...selectedCompanies, ...[dropdownValue]] : selectedCompanies,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        type === 'sectors' || type === 'industries'
                            ? [dropdownValue]
                            : allSecName.filter(acc => selectedSectors.includes(acc)),
                },
                category: {
                    operator: 'IS',
                    values: type === 'category' ? [dropdownValue] : selectedCategories,
                },
                topics: {
                    operator: 'IS',
                    values:
                        type === 'topics' ? [dropdownValue] : allTopName.filter(acc => selectedSectors.includes(acc)),
                },
                date: selectedOption,
                // relavance: selectedRelavance,
            },
            relevance_cutoff:
                selectedOption === '1' || selectedOption === '0' || selectedOption === '2' || selectedOption === '6'
                    ? 3
                    : selectedOption === '29'
                    ? 13
                    : selectedOption === '59'
                    ? 23
                    : selectedOption === '89'
                    ? 33
                    : 43,

            semantic_preference: 0.5,
            type: dropdownValue !== undefined ? 'level2' : 'level0',
        }
        if ((searchtext !== undefined || newMessage !== '') && dropdownValue === undefined) {
            payload.query = newMessage !== '' ? newMessage : searchtext
        }
        if (newMessage !== '' && type !== undefined) {
            payload.filters[keyName] = {
                operator: 'IS',
                values: [dropdownValue],
            }
        }

        if (newMessage !== '' && dropdownValue && dropdownValue.length > 0) {
            payload.non_entity_query = dropdownValue
        }

        payload.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            sectors: allSecName.filter(acc => selectedSectors.includes(acc)),
            industries: allIndName.filter(acc => selectedSectors.includes(acc)),
            companies: selectedCompanies,
            topics: allTopName.filter(acc => selectedSectors.includes(acc)),
            subcategories: selectedSubCategories,
        }

        axios
            .post('/semantic/quick-search/', payload, { cancelToken: newCancelSource.token })
            .then(res => {
                const senderMessage = {
                    text: '',
                    sender: 'sender',
                    type: newMessage,
                    thoughtNews: res.data.data,
                    section_type: selectedState,
                }
                setMessages(prevMessages => [...prevMessages, senderMessage])
                setBasicSearchResponse({
                    total: res.data.total,
                    next: res.data.next,
                    data: res.data.data,
                })
                setChatLoader(false)
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setChatLoader(true)
                } else {
                    console.log('POST err:', err.message)
                    setChatLoader(false)
                }
            })
            .finally(() => {
                // setChatLoader(false)
            })
    }

    const handleLoadQuick = () => {
        var startDate = new Date()
        var endDate = new Date()

        startDate.setDate(startDate.getDate() - selectedOption)
        startDate = getYMD(startDate)
        endDate = getYMD(endDate)

        const allSecName = allSectors
            .filter(acc => acc.type === 'sectors' || acc.type === 'industries')
            .map(val => val.label)

        const allTopName = allSectors.filter(acc => acc.type === 'topics').map(val => val.label)

        const allIndName = allSectors.filter(acc => acc.type === 'industries').map(val => val.label)

        let data = {
            filters: {
                articleDate: {
                    operator: 'BETWEEN',
                    from: getYMD(startDate),
                    to: getYMD(new Date()),
                },
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                company: {
                    operator: 'IS',
                    values:
                        loadmoreFilter.type === 'company'
                            ? [...selectedCompanies, ...[loadmoreFilter.value]]
                            : selectedCompanies,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        loadmoreFilter.type === 'sectors' || loadmoreFilter.type === 'industries'
                            ? [loadmoreFilter.value]
                            : allSecName.filter(acc => selectedSectors.includes(acc)),
                },
                category: {
                    operator: 'IS',
                    values: loadmoreFilter.type === 'category' ? [loadmoreFilter.value] : selectedCategories,
                },
                topics: {
                    operator: 'IS',
                    values:
                        loadmoreFilter.type === 'topics'
                            ? [loadmoreFilter.value]
                            : allTopName.filter(acc => selectedSectors.includes(acc)),
                },
                date: selectedOption,
            },
            next: basicSearchResponse.next,
            relevance_cutoff:
                selectedOption === '1' || selectedOption === '0' || selectedOption === '2' || selectedOption === '6'
                    ? 3
                    : selectedOption === '29'
                    ? 13
                    : selectedOption === '59'
                    ? 23
                    : selectedOption === '89'
                    ? 33
                    : 43,
            semantic_preference: 0.5,
            type: loadmoreFilter.value === null ? 'level0' : 'level2',
        }
        if (newMessage !== '' && loadmoreFilter.value === null) {
            data.query = newMessage
        }

        if (newMessage !== '' && loadmoreFilter.value !== null) {
            data.filters[loadmoreFilter.type] = {
                operator: 'IS',
                values: [loadmoreFilter.value],
            }
        }
        if (newMessage !== '' && loadmoreFilter.value !== null) {
            data.non_entity_query = loadmoreFilter.value
        }

        data.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            sectors: allSecName.filter(acc => selectedSectors.includes(acc)),
            industries: allIndName.filter(acc => selectedSectors.includes(acc)),
            companies: selectedCompanies,
            topics: allTopName.filter(acc => selectedSectors.includes(acc)),
            subcategories: selectedSubCategories,
        }

        if (basicSearchResponse.next !== null) {
            axios
                .post('/semantic/quick-search/', data)
                .then(response => {
                    let newsBefore = [...basicSearchResponse.data, ...response.data.data]
                    setBasicSearchResponse({
                        total: response.data.total,
                        next: response.data.next,
                        data: newsBefore,
                    })
                })
                .finally(() => {
                    setChatLoader(false)
                })
        } else {
            setChatLoader(false)
        }
    }

    const getRecommendationQuick = (text = null, directSearch = null) => {
        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)
        setDefaultRecommendationSearch(false)
        const userMessage = {
            sender: 'user',
            type: selectedState,
            text: '',
            section_type: selectedState,
        }

        setMessages(prevMessages => [...prevMessages, userMessage])
        setNewMessage('')

        setChatLoader(true)

        // if (directSearch) {
        const arr = messages

        if (arr.length > 0) {
            arr.splice(-1)
        }
        setMessages(arr)
        // }
        var startDate = new Date()
        startDate.setDate(startDate.getDate() - 29)

        const payload = {
            filters: {
                articleDate: {
                    operator: 'BETWEEN',
                    from: getYMD(startDate),
                    to: getYMD(new Date()),
                },
            },
            relevance_cutoff: 13,
            semantic_preference: 0.5,
            type: directSearch ? 'level2' : 'level0',
            social_posts: true,
        }

        if (directSearch) {
            payload.non_entity_query = text
            payload.filters[directSearch] = {
                operator: 'IS',
                values: [text],
            }
            payload.type =
                directSearch === 'sectors'
                    ? 'sector'
                    : directSearch === 'topics'
                    ? 'topic'
                    : directSearch === 'industries'
                    ? 'industry'
                    : 'company'
        } else {
            payload.query = text || newMessage
        }
        axios
            .post('/semantic/quick-search/', payload, { cancelToken: newCancelSource.token })
            .then(res => {
                setChatLoader(false)
                if (res.data && res.data.data && res.data.data.length > 0) {
                    const botMessage = {
                        sender: 'sender',
                        // text:
                        //     res.data.data.length === 0
                        //         ? 'Can't Find recommendations post for your settings. Please use free search below'
                        //         : question
                        //         ? 'Certainly! Here are some social media recommendations for you based on your search'
                        //         : `Certainly! Here are some social media recommendations for you based on your settings.`,
                        text: '',
                        type: selectedState,
                        recommendationPosts: res.data,
                        seeMore: res.data.next ? true : false,
                        showExtra: text ? false : res.data && res.data.data && res.data.data.length > 0 ? true : false,
                        posts: true,
                        question: text || newMessage,
                        section_type: selectedState,
                    }
                    setMessages(prevMessages => [...prevMessages, botMessage])
                } else {
                    const userMessage = {
                        sender: 'sender',
                        type: selectedState,
                        text: `No News Available for ${text || newMessage}`,
                        question: text || newMessage,
                        section_type: selectedState,
                    }

                    setMessages(prevMessages => [...prevMessages, userMessage])
                }
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setChatLoader(true)
                } else {
                    console.log('POST err:', err.message)
                    setChatLoader(false)
                    simulateTypingEffect(
                        `I Apologize, unable to fetch recommendations. Please try again`,
                        selectedState
                    )
                }
            })
            .finally(() => {
                // setChatLoader(false)
            })
    }

    const saveQuery = () => {
        const saveQuery = {
            query_name: SchedulingQueryName,
            query_string: SchedulingQuery,
            template: selectedTemplate && selectedTemplate.value ? selectedTemplate.value : null,
            user_recipients: selectedUsers.map(val => val.id).filter(val => !isNaN(val) && typeof val === 'number'),
            scheduled: isEnabled,
            cron_expression: cronTabValue,
            // recipients: selectedUsers.map(val => val.email),
            newsletter: 'saved_search_newsletter',
            section:
                selectedTemplate && selectedTemplate.has_sections && selectedSection ? selectedSection.value : null,

            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: 'level0',
        }

        if (
            SchedulingQuery !== '' &&
            emailSubject !== '' &&
            SchedulingQueryName !== '' &&
            selectedTemplate &&
            selectedTemplate.value &&
            selectedUsers.length > 0
        ) {
            axios
                .post('/news/search-query/', saveQuery)
                .then(res => {
                    toast('Query has been successfully saved')
                    setSchedulingQuery('')
                })
                .catch(err => console.log(err))
        } else {
            toast.warn('Please fill out all the fields.')
        }
    }

    const topicAlerts = (text = null, directSearch = null) => {
        setChatLoader(true)
        const userMessage = {
            text: text || newMessage,
            sender: 'user',
            type: selectedState,
            section_type: selectedState,
        }
        setMessages(prevMessages => [...prevMessages, userMessage])

        var startDate = new Date()
        startDate.setDate(startDate.getDate() - 29)

        const payload = {
            filters: {
                articleDate: {
                    operator: 'BETWEEN',
                    from: getYMD(startDate),
                    to: getYMD(new Date()),
                },
            },
            relevance_cutoff: 13,
            semantic_preference: 0.5,
            type: 'level2',
        }

        if (directSearch) {
            payload.non_entity_query = newMessage
            payload.filters[directSearch] = {
                operator: 'IS',
                values: [newMessage],
            }
            payload.type =
                directSearch === 'sectors'
                    ? 'sector'
                    : directSearch === 'topics'
                    ? 'topic'
                    : directSearch === 'industries'
                    ? 'industry'
                    : 'company'
        } else {
            payload.query = text || newMessage
        }

        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })

        axios
            .post('/semantic/quick-search/', payload)
            .then(res => {
                const data = res.data
                if ((data && data.summary) || (data && data.data && data.data.length > 0)) {
                    const summarize = data && data.summary ? `<br/> ${data.summary}` : ''

                    const senderMessage = {
                        text:
                            '<b>Great! I pulled some relevant news for the topic. If this looks good, set up your alert frequency below:</b> <br/>' +
                            summarize,
                        sender: 'sender',
                        type: newMessage,
                        alertNews: data.data,
                        section_type: selectedState,
                    }
                    setMessages(prevMessages => [...prevMessages, senderMessage])
                } else {
                    const message = {
                        text: `I‘m currently unable to find any relevant news for this search. Please schedule an alert and I will update you via email as more news becomes available.`,
                        sender: 'sender',
                        type: selectedState,
                        section_type: selectedState,
                    }
                    setMessages(history => [...history, message])
                }

                setTimeout(() => {
                    const userMessages = {
                        text: ``,
                        sender: 'sender',
                        type: text || newMessage,
                        schedule: true,
                        section_type: selectedState,
                    }
                    setMessages(prevMessages => [...prevMessages, userMessages])
                }, 1000)
            })
            .catch(err => console.log(err))
            .finally(() => {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                setChatLoader(false)
            })

        setNewMessage('')
        // setTyping(true)
    }

    const InputSection = () => {
        return (
            <>
                <div class="icon-with-input">
                    <div style={{ width: '80%' }}>
                        {/* <MdAttachFile className="leftside_attachicon" /> */}

                        <input
                            type="text"
                            value={newMessage}
                            id="search_input"
                            placeholder={
                                selectedState === 'alert'
                                    ? 'Start typing to see a list of Companies and Topics'
                                    : selectedState === 'Company'
                                    ? 'Starting typing to see a list of Companies'
                                    : selectedState === 'Sector'
                                    ? 'Starting typing to see a list of Sectors'
                                    : selectedState === 'SocialClient'
                                    ? 'Search for a Company or Topic'
                                    : selectedState === 'Thought'
                                    ? 'Search for a Company or Topic'
                                    : 'Enter Your Question or Request...'
                            }
                            onKeyPress={e => {
                                if (e.key === 'Enter' && e.target.value.length > 0) {
                                    e.preventDefault()
                                    handleSendMessage(
                                        selectedState === 'Company'
                                            ? 'Company'
                                            : selectedState === 'Sector'
                                            ? 'Sector'
                                            : selectedState === 'alert'
                                            ? 'alert'
                                            : selectedState === 'SocialClient'
                                            ? 'SocialClient'
                                            : selectedState === 'Thought'
                                            ? 'Thought'
                                            : 'default'
                                    )
                                    if (selectedState === 'Company' || selectedState === 'Sector') {
                                        const userMessage = {
                                            text: newMessage,
                                            sender: 'user',
                                            type: selectedState,
                                            section_type: selectedState,
                                        }
                                        setMessages(prevMessages => [...prevMessages, userMessage])
                                        handleReportsMatches(selectedState, newMessage)

                                        // setOpenOPtions(false)
                                    }
                                    if (selectedState === 'alert') {
                                        topicAlerts()
                                    }

                                    if (selectedState === 'SocialClient') {
                                        // getRecommendations(selectedState, newMessage)
                                        getRecommendationQuick()
                                    }

                                    if (selectedState === 'Thought') {
                                        weviateSearch()
                                    }
                                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                                }
                            }}
                            onChange={e => {
                                setNewMessage(e.target.value)

                                if (openOPtions && e.target.value.length > 2 && selectedState === 'Sector') {
                                    const filteredData = allSectors.filter(item =>
                                        item.label.toLowerCase().startsWith(e.target.value.toLowerCase())
                                    )

                                    setOptionData(filteredData)
                                }
                                if (selectedState === 'Thought' && e.target.value.length > 3) {
                                    setSearchDrop(true)
                                }
                            }}
                            autoComplete="off"
                            onBlur={() => {
                                if (selectedState === 'Thought') {
                                    setTimeout(() => {
                                        setSearchDrop(false)
                                    }, 300)
                                }
                            }}
                            // disabled={selectedState === 'SocialClient' && chatLoader}
                        />
                        <ArrowForwardIcon
                            className={newMessage.length > 0 ? 'rightside_searchicon done' : 'rightside_searchicon'}
                            // style={{ fontSize: '40px', background: newMessage.length > 0 ? '#186ADE' : 'ligtgray' }}
                            onClick={() => {
                                if (newMessage.length > 0) {
                                    handleSendMessage(
                                        selectedState === 'Company'
                                            ? 'Company'
                                            : selectedState === 'Sector'
                                            ? 'Sector'
                                            : selectedState === 'alert'
                                            ? 'alert'
                                            : selectedState === 'SocialClient'
                                            ? 'SocialClient'
                                            : selectedState === 'Thought'
                                            ? 'Thought'
                                            : 'default'
                                    )
                                    if (selectedState === 'Company' || selectedState === 'Sector') {
                                        const userMessage = {
                                            text: newMessage,
                                            sender: 'user',
                                            type: selectedState,
                                            section_type: selectedState,
                                        }
                                        setMessages(prevMessages => [...prevMessages, userMessage])
                                        handleReportsMatches(selectedState, newMessage)
                                        // setOpenOPtions(false)
                                    }
                                    if (selectedState === 'alert') {
                                        topicAlerts()
                                    }
                                    if (selectedState === 'SocialClient') {
                                        // getRecommendations(selectedState, newMessage)
                                        getRecommendationQuick()
                                    }

                                    if (selectedState === 'Thought') {
                                        weviateSearch()
                                    }

                                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                                }
                            }}
                        />
                        {((selectedState === 'SocialClient' && defaultRecommendationSearch) ||
                            (searchDrop && selectedState === 'Thought')) &&
                            openOPtions &&
                            newMessage.length > 2 &&
                            optionData.length > 0 && (
                                <div
                                    style={{
                                        background: 'white',
                                        position: 'absolute',
                                        zIndex: 1,
                                        width: document.getElementById('search_input').offsetWidth + 'px',
                                        maxHeight: '250px',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                    }}
                                    // ref={wrapperRef}
                                >
                                    {recommendationsdataMatches}
                                </div>
                            )}
                    </div>
                </div>
            </>
        )
    }

    const dataMatches = optionData.map((value, index) => (
        <span
            key={index}
            style={{
                border: '1px solid lightgray',
                padding: '5px',
                borderRadius: '20px',
                marginRight: '5px',
                fontWeight: 'bold',
                cursor: 'pointer',
                margin: '5px',
                display: 'inline-block',
            }}
            className="font"
            onClick={() => {
                if (selectedState === 'Sector' || selectedState === 'Company') {
                    const userMessage = {
                        text: value.label,
                        sender: 'user',
                        type: optionData.length > 0 ? 'Company' : 'Sector',
                        report: value.label,
                        section_type: selectedState,
                    }
                    setMessages(prevMessages => [...prevMessages, userMessage])
                    setChatDownload({})
                    handleReportsMatches(optionData.length > 0 ? 'Company' : 'Sector', value.label, value.type)
                }
                if (selectedState === 'alert') {
                    topicAlerts(value.label, value.type === 'company' ? 'company' : 'sectors')
                }
                if (selectedState === 'SocialClient') {
                    // getRecommendations(selectedState, value.label)
                    getRecommendationQuick(value.label, value.type === 'company' ? 'company' : 'sectors')
                }
            }}
        >
            {value.label}
        </span>
    ))

    const recommendationsdataMatches = optionData
        .filter(value => value.label.toLowerCase().startsWith(newMessage.toLowerCase()))
        .map((value, index) => (
            <div
                key={index}
                className="recommendation_matches font-8"
                onClick={() => {
                    if (selectedState === 'SocialClient') {
                        // getRecommendations(selectedState, value.label)
                        getRecommendationQuick(value.label, value.type === 'company' ? 'company' : 'sectors')
                    }
                    if (selectedState === 'Thought') {
                        setNewMessage(value.label)
                        weviateSearch(value.label, value.type)
                    }
                }}
            >
                {value.label}
            </div>
        ))

    const handleLoadMore = URL => {
        const scrollUrl = URL.replace('http://localhost:8000', '') && URL.replace('http://kaitongo-backend:8000', '')

        if (scrollUrl) {
            setChatLoader(true)
            axios
                .get(scrollUrl)
                .then(res => {
                    const botMessage = {
                        sender: 'sender',
                        type: selectedState,
                        text: '',
                        recommendationPosts: res.data,
                        seeMore: res.data.next ? true : false,
                        showExtra: res.data.data ? true : false,
                        section_type: selectedState,
                    }
                    setMessages(prevMessages => [...prevMessages, botMessage])
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setChatLoader(false)
                })
        }
    }

    const clearChat = () => {
        setMessages([])
        setSelectedState('default')
        setOptionData([])
        setChatLoader(false)
        setChatDownload({})
        setNewMessage('')
        setShowSection(false)
        setThoughtSection(false)
        setOpenOPtions(false)
        setDraftSection(false)
        setBasicSearchResponse({ data: [], next: null })
        setLoadMoreFilter({ value: null, type: null })
        setDefaultRecommendationSearch(true)
        setIdeaSection(false)
        setGenerateIdeas([])
        setProgressContent(null)
    }

    const addToSelection = story => {
        setSelectedStories([...selectedStories, story])
    }

    const removeFromSelection = story => {
        let selectedCopy = selectedStories.filter(arr => arr.id !== story.id)
        setSelectedStories(selectedCopy)
    }

    const generateSummary = news => {
        setSummaryLoader(news)
    }

    let retryCount = 0

    const generateDraft = (ideas = null) => {
        if (selectedStories.length > 0) {
            setIdeaSection(false)
            setDraftLoader(true)
            setDraftSection(true)
            setNewsFilters(false)
            const payload = {
                search_query: newMessage,
                news_content:
                    ideas === null
                        ? selectedStories.map(v => v.title + ' - ' + v.snippet)
                        : [ideas.Title + '-' + ideas.Storyline],
                type: 'Thought Leadership Draft',
            }

            axios
                .post('/core/generate-thought-draft/', payload)
                .then(res => {
                    const data = res.data
                    if (data !== 'Sorry, there was an error in generating. Try again') {
                        setDraftContent(data)
                        retryCount = 0 // Reset retry count on success
                        setDraftLoader(false)
                    } else {
                        if (retryCount < 1) {
                            retryCount += 1
                            generateDraft(ideas)
                            setDraftLoader(true)
                        } else {
                            setDraftContent(null)
                            retryCount = 0 // Reset retry count after final failure
                            setDraftLoader(false)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    setDraftLoader(false)
                })
        }
    }

    const clearFilters = () => {
        setSelectedSubCategories([])
        setSelectedCompanies([])
        setSelectedSectors([])
        setSelectedSources([])
        setSelectedRegions([])
        setSelectedOption('29')
    }

    const generateQuery = date => {
        setBasicSearchResponse({ data: [], next: null })
        setMessages([])

        setChatLoader(true)

        // setDateSection(false)
        if (date === 1 || date === '1') {
            date = 0
        }
        var startDate = new Date()
        var endDate = new Date()
        if (date !== 1) {
            startDate.setDate(startDate.getDate() - date)
            startDate = getYMD(startDate)
            endDate = getYMD(endDate)
        } else {
            startDate = getYMD(startDate)
            endDate = getYMD(endDate)
        }
        const allIndName = allSectors.filter(acc => acc.type === 'industries').map(val => val.label)

        const allSecName = allSectors.filter(acc => acc.type === 'sectors').map(val => val.label)

        const allTopName = allSectors.filter(acc => acc.type === 'topics').map(val => val.label)

        const payLoad = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                articleDate: {
                    operator: 'BETWEEN',
                    from: getYMD(startDate),
                    to: getYMD(new Date()),
                },
                company: {
                    operator: 'IS',
                    values:
                        loadmoreFilter.type === 'company'
                            ? [...selectedCompanies, ...[loadmoreFilter.value]]
                            : selectedCompanies,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        loadmoreFilter.type === 'sectors'
                            ? [allSecName.filter(acc => selectedSectors.includes(acc)), ...[loadmoreFilter.value]]
                            : allSecName.filter(acc => selectedSectors.includes(acc)),
                },
                category: {
                    operator: 'IS',
                    values:
                        loadmoreFilter.type === 'category'
                            ? [...selectedCategories, ...[loadmoreFilter.value]]
                            : selectedCategories,
                },
                topics: {
                    operator: 'IS',
                    values:
                        loadmoreFilter.type === 'category'
                            ? [allTopName.filter(acc => selectedSectors.includes(acc)), , ...[loadmoreFilter.value]]
                            : allTopName.filter(acc => selectedSectors.includes(acc)),
                },
            },
            relevance_cutoff:
                date === '1' || date === '0' || date === '2' || date === '6'
                    ? 3
                    : date === '29'
                    ? 13
                    : date === '59'
                    ? 23
                    : date === '89'
                    ? 33
                    : 43,
            semantic_preference: 0.5,
            type: loadmoreFilter.value !== null ? 'level2' : 'level0',
        }

        if (newMessage !== '' && loadmoreFilter.value === null) {
            payLoad.query = newMessage
        }

        if (newMessage !== '' && loadmoreFilter.value !== null) {
            payLoad.filters[loadmoreFilter.type] = {
                operator: 'IS',
                values: [loadmoreFilter.value],
            }
        }
        if (newMessage !== '' && loadmoreFilter.value !== null) {
            payLoad.non_entity_query = loadmoreFilter.value
        }

        payLoad.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            sectors: allSecName.filter(acc => selectedSectors.includes(acc)),
            industries: allIndName.filter(acc => selectedSectors.includes(acc)),
            companies: selectedCompanies,
            topics: allTopName.filter(acc => selectedSectors.includes(acc)),
            subcategories: selectedSubCategories,
        }

        axios
            .post('/semantic/quick-search/', payLoad)
            .then(res => {
                const senderMessage = {
                    text: '',
                    sender: 'sender',
                    type: newMessage,
                    thoughtNews: res.data.data,
                    section_type: selectedState,
                }
                setMessages(prevMessages => [...prevMessages, senderMessage])
                setBasicSearchResponse({
                    total: res.data.total,
                    next: res.data.next,
                    data: res.data.data,
                })
            })
            .catch(err => console.log(err))
            .finally(() => {
                setChatLoader(false)
            })
    }

    const generateIdeaFunction = () => {
        setIdeaSection(true)
        setNewsFilters(false)
        setDraftLoader(true)
        const payload = {
            search_query: newMessage,
            news_content: selectedStories.map(v => v.title + ' - ' + v.snippet),
            type: 'Thought Leadership Generate Ideas',
        }

        axios
            .post('/core/generate-thought-draft/', payload)
            .then(res => {
                const data = res.data
                if (data !== 'Sorry, there was an error in generating. Try again') {
                    setGenerateIdeas(data)
                    retryCount = 0 // Reset retry count on success
                    setDraftLoader(false)
                } else {
                    if (retryCount < 1) {
                        retryCount += 1
                        generateIdeaFunction()
                        setDraftLoader(true)
                    } else {
                        setGenerateIdeas([])
                        retryCount = 0 // Reset retry count after final failure
                        setDraftLoader(false)
                    }
                }
            })
            .catch(err => {
                console.log(err)
                setDraftLoader(false)
            })
    }

    const predefinedThoughts = [
        { icon: gavelIcon, query: 'Use of generative AI in law firms' },
        { icon: appleIcon, query: 'Apple monopoly lawsuits' },
        { icon: pharmaIcon, query: 'Antitrust investigations in biotech or pharma' },
        { icon: bucketIcon, query: 'Trademark issues related to consumer goods' },
        { icon: dealIcon, query: 'Dealmaking in tech' },
    ]

    const CardView = ({ values }) => {
        // Function to split the array into chunks
        const chunkArray = (arr, chunkSize) => {
            const result = []
            for (let i = 0; i < arr.length; i += chunkSize) {
                result.push(arr.slice(i, i + chunkSize))
            }
            return result
        }

        const rows = [...chunkArray(values.slice(0, 3), 3), ...chunkArray(values.slice(3), 2)]

        return (
            <div className="mt-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {rows.map((row, rowIndex) => (
                    <div
                        key={rowIndex}
                        style={{ display: 'flex', width: '78%', justifyContent: 'center', marginBottom: '10px' }}
                    >
                        {row.map((value, colIndex) => (
                            <div className="card-thoughts  m-2" style={{ backgroundColor: '', width: '33%' }}>
                                <div
                                    className="card-body d-flex align-items-center  justify-content-between"
                                    onClick={() => {
                                        setNewMessage(value.query)
                                        weviateSearch(undefined, undefined, value.query)
                                    }}
                                >
                                    <span className="d-flex align-items-center" style={{ padding: '10px' }}>
                                        <img src={value.icon} alt="email_outreach" style={{ fontSize: '24px' }} />
                                        <span
                                            className="card-text font d-none d-sm-block"
                                            style={{ marginLeft: '10px', fontWeight: 600 }}
                                        >
                                            {value.query}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <BaseScreen>
            <div style={{ width: '95%', margin: 'auto', marginBottom: '20px' }}>
                {((messages.length === 0 && selectedState !== 'SocialClient' && selectedState !== 'Thought') ||
                    (!showSection &&
                        !thoughtSection &&
                        (selectedState === 'SocialClient' || selectedState === 'Thought'))) && (
                    <div style={{ width: '90%', margin: 'auto' }}>
                        <h2
                            style={{
                                textAlign: 'center',
                                // fontSize: '35px',
                                color: '#3048A6',
                                borderBottom: '0rem',
                                margin: '1rem 3rem 1rem 3rem',
                            }}
                            className="font-bh"
                        >
                            Use Kaitongo to fuel your Business Development today
                        </h2>
                        <div className="row" style={{ marginTop: '75px' }}>
                            <div className="col-sm">
                                <div
                                    className=" align-items-center"
                                    style={{ borderRadius: '5px', padding: '10px 20px 20px 20px' }}
                                >
                                    <label className="d-flex align-items-center justify-content-center heading font-h">
                                        <b> Meeting Preparation</b>
                                    </label>
                                    {reports.map((val, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setMessages([])
                                                    setSelectedState(val)
                                                    setOptionData([])
                                                    const userMessage = {
                                                        text: `Generate a ${val} Report for me`,
                                                        sender: 'user',
                                                        type: val,
                                                        section_type: val,
                                                    }
                                                    setChatLoader(true)
                                                    setMessages(prevMessages => [...prevMessages, userMessage])
                                                    simulateTypingEffect(
                                                        `What ${val} Report would you like to create?`,
                                                        val
                                                    )
                                                    setOpenOPtions(true)
                                                }}
                                                className={
                                                    index === reports.length - 1
                                                        ? 'card assisstant'
                                                        : 'card assisstant  mb-3'
                                                }
                                            >
                                                <div className="card-body d-flex align-items-center justify-content-between">
                                                    <span className="d-flex align-items-center">
                                                        {index === 0 ? (
                                                            <img
                                                                src={companyIcon}
                                                                alt="company_report"
                                                                style={{ fontSize: '24px' }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={sectorIcon}
                                                                alt="sector_report"
                                                                style={{ fontSize: '24px' }}
                                                            />
                                                        )}
                                                        <span
                                                            className="card-text font"
                                                            style={{
                                                                marginLeft: '10px',
                                                                // fontSize: '13px',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {val} <span>Reports</span>
                                                        </span>
                                                    </span>

                                                    <span style={{ marginLeft: '4px' }}>
                                                        <ArrowForwardIcon
                                                            style={{ fontSize: '24px', color: 'black' }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-sm">
                                <div
                                    className="align-items-center"
                                    style={{ borderRadius: '5px', padding: '10px 20px 20px 20px' }}
                                >
                                    <label className="d-flex align-items-center justify-content-center heading font-h">
                                        <b> Client Prospecting/Targeting</b>
                                    </label>

                                    <div
                                        className="card assisstant mb-3"
                                        onClick={() => {
                                            setMessages([])
                                            setSelectedState('alert')
                                            setOptionData([])
                                            const userMessage = {
                                                text: `Generate a Company/Topic alert for me`,
                                                sender: 'user',
                                                type: 'Sector',
                                                section_type: 'alert',
                                            }
                                            setChatLoader(true)
                                            setMessages(prevMessages => [...prevMessages, userMessage])
                                            simulateTypingEffect(
                                                `What Company/Topic alert would you like to receive?`,
                                                'alert'
                                            )
                                            setOpenOPtions(true)
                                        }}
                                    >
                                        <div className="card-body d-flex align-items-center justify-content-between">
                                            <span className="d-flex align-items-center">
                                                <img src={alertsIcon} alt="alerts" style={{ fontSize: '24px' }} />
                                                <span
                                                    className="card-text font"
                                                    style={{ marginLeft: '10px', fontWeight: 600 }}
                                                >
                                                    Send me alerts for a Company/Topic
                                                </span>
                                            </span>
                                            <span style={{ marginLeft: '4px' }}>
                                                <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        className="card"
                                        id="Tooltip-email-outreach"
                                        style={{ backgroundColor: '', border: 'none' }}
                                    >
                                        <div
                                            className="card-body d-flex align-items-center  justify-content-between"
                                            style={{
                                                backgroundColor: '#E5E5E5',
                                                borderRadius: '5px',
                                                border: '1px solid lightgray',
                                            }}
                                        >
                                            <span className="d-flex align-items-center" style={{ opacity: '0.5' }}>
                                                <img
                                                    src={emailIcon}
                                                    alt="email_outreach"
                                                    style={{ fontSize: '24px' }}
                                                />
                                                <span
                                                    className="card-text font"
                                                    style={{ marginLeft: '10px', fontWeight: 600 }}
                                                >
                                                    Send me content for Email Outreach
                                                </span>
                                            </span>
                                            <span style={{ marginLeft: '4px', opacity: '0.5' }}>
                                                <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                            </span>
                                        </div>
                                        <Tooltip
                                            placement={'bottom'}
                                            isOpen={tooltipOpen}
                                            target={'Tooltip-email-outreach'}
                                            toggle={toggle}
                                            className="custom-tooltips"
                                        >
                                            This feature is coming soon!
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div
                                    className=" align-items-center"
                                    style={{ borderRadius: '5px', padding: '10px 20px 20px 20px' }}
                                >
                                    <label className="d-flex align-items-center justify-content-center heading font-h">
                                        <b> Marketing/Thought Leadership</b>{' '}
                                    </label>
                                    <div className="card assisstant  mb-3" style={{ backgroundColor: '' }}>
                                        <div
                                            className="card-body d-flex align-items-center  justify-content-between"
                                            // style={{ backgroundColor: 'lightgray' }}
                                            onClick={() => {
                                                setMessages([])
                                                setOptionData([])
                                                setSelectedState('Thought')
                                                setOpenOPtions(true)
                                                setThoughtSection(true)
                                            }}
                                        >
                                            <span className="d-flex align-items-center">
                                                <img src={thoughtIcon} alt="thought" style={{ fontSize: '24px' }} />
                                                <span
                                                    className="card-text font"
                                                    style={{ marginLeft: '10px', fontWeight: 600 }}
                                                >
                                                    Give me content for Thought Leadership
                                                </span>
                                            </span>
                                            <span style={{ marginLeft: '4px' }}>
                                                <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="card assisstant" style={{ backgroundColor: '' }}>
                                        <div
                                            className="card-body d-flex align-items-center  justify-content-between"
                                            onClick={() => {
                                                setMessages([])
                                                setOptionData([])
                                                setSelectedState('SocialClient')
                                                getRecommendations('SocialClient')
                                                setChatLoader(true)
                                                setOpenOPtions(true)
                                                setShowSection(true)
                                            }}
                                        >
                                            <span className="d-flex align-items-center">
                                                <img src={linkedInIcon} alt="linkedin" style={{ fontSize: '24px' }} />
                                                <span
                                                    className="card-text font"
                                                    style={{ marginLeft: '10px', fontWeight: 600 }}
                                                >
                                                    Give me content for Social Media Posts
                                                </span>
                                            </span>
                                            <span style={{ marginLeft: '4px' }}>
                                                <ArrowForwardIcon style={{ fontSize: '24px', color: 'black' }} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div
                    style={{ position: 'fixed', paddingRight: '0.5%', right: 0, cursor: 'pointer', marginTop: '-10px' }}
                    onClick={() => {
                        clearChat()
                    }}
                    title="clear chat"
                >
                    {messages.length > 0 && selectedState === 'default' && (
                        <CancelIcon
                            style={{
                                color: '#051340',
                                fontSize: '45px',
                                padding: '7px',
                                borderRadius: '50%',
                            }}
                        />
                    )}
                </div>
                {!draftSection && !ideaSection && (
                    <>
                        {' '}
                        {newsFilters && (
                            <div>
                                <div
                                    className="news_filter_section bg-white"
                                    style={{
                                        height: '100vh',
                                        transition: 'opacity 3s ease',
                                        overflow: 'auto',
                                        position: 'fixed',
                                        width: '240px',
                                        top: 0,
                                        left: 0,
                                        zIndex: 10,
                                    }}
                                >
                                    <div style={{}}>
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            style={{ width: '80%', margin: '21.5px auto' }}
                                        >
                                            <label style={{ fontSize: '16px' }}>Filters</label>
                                            <span style={{ cursor: 'pointer' }} onClick={() => setNewsFilters(false)}>
                                                <IoIosClose style={{ fontSize: '26px' }} />
                                            </span>
                                        </div>
                                        <hr style={{ background: 'lightgray' }} />
                                        <div style={{ width: '80%', margin: 'auto' }}>
                                            <QuickAdvanceFilters
                                                allRegions={allRegions}
                                                setAllRegions={setAllRegions}
                                                allCompanies={allCompanies}
                                                setAllCompanies={setAllCompanies}
                                                allIndustries={allIndustries}
                                                setAllIndustries={setAllIndustries}
                                                allCategories={allCategories}
                                                setAllCategories={setAllCategories}
                                                allSubCategories={allSubCategories}
                                                setAllSubCategories={setAllSubCategories}
                                                allTopics={allTopics}
                                                setAllTopics={setAllTopics}
                                                allSources={allSources}
                                                setAllSources={setAllSources}
                                                basicSearchInput={newMessage}
                                                basicSearchResponse={basicSearchResponse}
                                                setBasicSearchResponse={setBasicSearchResponse}
                                                selectedCategories={selectedCategories}
                                                setSelectedCategories={setSelectedCategories}
                                                selectedCompanies={selectedCompanies}
                                                setSelectedCompanies={setSelectedCompanies}
                                                selectedTopics={selectedTopics}
                                                setSelectedTopics={setSelectedTopics}
                                                selectedSources={selectedSources}
                                                setSelectedSources={setSelectedSources}
                                                selectedRegions={selectedRegions}
                                                setSelectedRegions={setSelectedRegions}
                                                selectedSectors={selectedSectors}
                                                setSelectedSectors={setSelectedSectors}
                                                setIsLoading={setChatLoader}
                                                selectedOption={selectedOption}
                                                setSelectedOption={setSelectedOption}
                                                allHierarchy={allHierarchy}
                                                setAllHierarchy={setAllHierarchy}
                                                practiceSubCategory={practiceSubCategory}
                                                setPracticeSubCategory={setPracticeSubCategory}
                                                regionHierarchy={regionHierarchy}
                                                setRegionHierarchy={setRegionHierarchy}
                                                currentPath={window.location.pathname}
                                                usertype={usertype}
                                                defaultData={defaultData}
                                                setDefaultData={setDefaultData}
                                                dateSection={dateSection}
                                                setDateSection={setDateSection}
                                                generateQuery={generateQuery}
                                                clearFilters={clearFilters}
                                                selectedSubCategories={selectedSubCategories}
                                                setSelectedSubCategories={setSelectedSubCategories}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div style={{ padding: selectedState === 'SocialClient' ? '10px' : '10px 20px' }}>
                            {selectedState !== 'default' &&
                                selectedState !== 'SocialClient' &&
                                selectedState !== 'Thought' && (
                                    <div className="d-flex align-items-center font-h" style={{ cursor: 'pointer' }}>
                                        <span style={{ zIndex: '10' }}>
                                            <ArrowBackIcon
                                                onClick={() => {
                                                    clearChat()
                                                }}
                                            />
                                        </span>{' '}
                                        <label className="font-h" style={{ marginLeft: '10px' }}>
                                            {selectedState === 'Company'
                                                ? 'Company Reports'
                                                : selectedState === 'Sector'
                                                ? 'Sector Reports'
                                                : selectedState === 'alert'
                                                ? 'Company/Topic Alerts'
                                                : ''}
                                        </label>
                                    </div>
                                )}
                            {selectedState === 'SocialClient' && (
                                <>
                                    <div className="d-flex align-items-center font-h" style={{ cursor: 'pointer' }}>
                                        <span style={{ zIndex: '10' }}>
                                            <ArrowBackIcon
                                                onClick={() => {
                                                    clearChat()
                                                }}
                                            />
                                        </span>{' '}
                                        <label className="font-h" style={{ marginLeft: '10px' }}>
                                            Social Media Posts
                                        </label>
                                    </div>

                                    {defaultRecommendationSearch && (
                                        <>
                                            <div className="mt-5 text-center">
                                                <h2 style={{ color: '#186ADE' }} id="use_search" className="font-sh">
                                                    Type in the name of a company or topic into the search box to find
                                                    articles.
                                                </h2>
                                                {(
                                                    messages.length > 0 &&
                                                    messages.filter(
                                                        message =>
                                                            message.recommendationPosts &&
                                                            message.recommendationPosts.data &&
                                                            message.recommendationPosts.data.length > 0
                                                    )
                                                ).length > 0 && (
                                                    <h2 style={{ color: '#186ADE' }} className="font-sh">
                                                        Or, choose from the trending news articles below to get started
                                                        immediately.
                                                    </h2>
                                                )}
                                            </div>
                                            <div className="mt-5">{InputSection()}</div>
                                            {(chatLoader ||
                                                (
                                                    messages.length > 0 &&
                                                    messages.filter(
                                                        message =>
                                                            message.recommendationPosts &&
                                                            message.recommendationPosts.data &&
                                                            message.recommendationPosts.data.length > 0
                                                    )
                                                ).length > 0) && (
                                                <label
                                                    style={{
                                                        textAlign: 'center',
                                                        display: 'block',
                                                        margin: '10px auto',
                                                        paddingTop: '20px',
                                                    }}
                                                    className="font-h"
                                                >
                                                    Trending News You Can Post On
                                                </label>
                                            )}
                                        </>
                                    )}
                                </>
                            )}{' '}
                            {selectedState === 'Thought' && (
                                <>
                                    <div className="d-flex align-items-center fon-h" style={{ cursor: 'pointer' }}>
                                        <span style={{ zIndex: '10' }}>
                                            <ArrowBackIcon
                                                onClick={() => {
                                                    clearChat()
                                                }}
                                            />
                                        </span>{' '}
                                        <label className="font-h" style={{ marginLeft: '10px' }}>
                                            Thought Leadership Content
                                        </label>
                                    </div>
                                    <div style={{ marginTop: messages.length === 0 ? '11%' : '' }}>
                                        {basicSearchResponse.data.length === 0 && (
                                            <label
                                                // className="font-sh"
                                                style={{
                                                    textAlign: 'center',
                                                    display: 'block',
                                                    fontSize: '15px',
                                                }}
                                            >
                                                What would you like to write about?
                                            </label>
                                        )}
                                        <div className="mt-3">{InputSection()}</div>{' '}
                                        {basicSearchResponse.data.length === 0 && !chatLoader && (
                                            <>
                                                <label
                                                    className="font-h mt-3"
                                                    style={{
                                                        textAlign: 'center',
                                                        display: 'block',
                                                    }}
                                                >
                                                    Or, select one of these areas for generating news:
                                                </label>
                                                <CardView values={predefinedThoughts} />
                                            </>
                                        )}{' '}
                                    </div>
                                </>
                            )}{' '}
                            {selectedState !== 'default' &&
                                messages.length > 0 &&
                                messages
                                    .filter(message => message.section_type && message.section_type === selectedState)
                                    .map((message, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                // padding: message.recommendationPosts || message.thoughtNews ? '0' : '15px',
                                                background:
                                                    message.recommendationPosts || message.thoughtNews
                                                        ? '0'
                                                        : message.sender === 'user'
                                                        ? '#BDE2FF'
                                                        : '#E6E8EC',
                                                border: message.sender === 'sender' ? '' : '',
                                                width: message.sender === 'user' ? 'max-content' : '',
                                                display:
                                                    message.sender === 'user' && message.text === '' ? 'none' : 'flex',
                                                marginLeft: 'auto',
                                                marginTop: '15px',
                                                maxWidth: message.sender === 'user' ? '60%' : '',
                                                borderRadius: '5px',
                                            }}
                                            className={
                                                message.sender === 'user'
                                                    ? `${
                                                          message.recommendationPosts || message.thoughtNews
                                                              ? 'message user'
                                                              : 'p-15'
                                                      }`
                                                    : `${
                                                          message.recommendationPosts || message.thoughtNews
                                                              ? 'sender'
                                                              : 'p-15'
                                                      }`
                                            }
                                        >
                                            {typeof message.text === 'string' && (
                                                <div style={{ display: 'block', width: '100%' }}>
                                                    {!message.document ? (
                                                        <p
                                                            className="font-8"
                                                            style={{
                                                                color: message.sender === 'user' ? 'black' : 'black',
                                                                marginTop: 0,
                                                                marginBottom: 0,
                                                            }}
                                                            id={
                                                                message.sender === 'user'
                                                                    ? `user_id_${index}`
                                                                    : `sender_id_${index}`
                                                            }
                                                            dangerouslySetInnerHTML={{ __html: message.text }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                color: message.sender === 'user' ? 'black' : 'black',
                                                                marginTop: 0,
                                                                marginBottom: 0,
                                                            }}
                                                            id={
                                                                message.sender === 'user'
                                                                    ? `user_id_${index}`
                                                                    : `sender_id_${index}`
                                                            }
                                                            onClick={() => {
                                                                setReportView(message.document)
                                                                if (message.type === 'Quick Report') {
                                                                    setTimeout(() => {
                                                                        setQuickReportModal(!quickReportModal)
                                                                    }, 1000)
                                                                } else {
                                                                    setTimeout(() => {
                                                                        setDetailedReportModal(!detailedReportModal)
                                                                    }, 1000)
                                                                }
                                                            }}
                                                        >
                                                            <span className="font-8">{message.text}</span>
                                                            <span
                                                                className="d-flex align-items-center font-8"
                                                                style={{
                                                                    textDecoration: 'underline',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {message.query + '_' + message.type}
                                                                <ArrowOutwardIcon style={{ marginLeft: '5px' }} />
                                                            </span>
                                                        </div>
                                                    )}

                                                    {message.recommendationPosts && (
                                                        <>
                                                            <label
                                                                style={{
                                                                    textAlign: 'center',
                                                                    display:
                                                                        message.recommendationPosts.data &&
                                                                        message.recommendationPosts.data.length > 0 &&
                                                                        message.question === null
                                                                            ? 'none'
                                                                            : 'block',
                                                                    margin:
                                                                        message.question !== null
                                                                            ? '30px 0px 10px 0px'
                                                                            : '10px auto',
                                                                    paddingTop: '20px',
                                                                }}
                                                                className="font-h"
                                                            >
                                                                {/* {message.recommendationPosts.data &&
                                                                message.recommendationPosts.data.length > 0 &&
                                                                message.question === null &&
                                                                'Trending News You Can Post On'} */}
                                                                {message.recommendationPosts.data &&
                                                                    message.recommendationPosts.data.length > 0 &&
                                                                    message.question !== null &&
                                                                    `Top News for ${message.question}`}
                                                            </label>

                                                            <div style={{ marginTop: '10px' }}>
                                                                <RenderInsightsCards
                                                                    data={message.recommendationPosts.data}
                                                                    isSocial={true}
                                                                    type={'recommendation'}
                                                                />
                                                            </div>
                                                            {/* {message.seeMore && (
                                                <p
                                                    onClick={() => handleLoadMore(message.recommendationPosts.next)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: '5px',
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                            margin: '5px',
                                                            display: 'flex',
                                                            textDecoration: 'underline',
                                                            color: '#051340',
                                                        }}
                                                    >
                                                        <span style={{ marginLeft: '10px' }}>See More Posts</span>
                                                    </div>
                                                </p>
                                            )} */}
                                                            {/* {message.showExtra && (
                                                <p style={{ margin: '5px', fontWeight: 'bold', marginLeft: '10px' }}>
                                                    Didn't find what you were looking for?{' '}
                                                    <span
                                                        style={{
                                                            color: '#051340',
                                                            textDecoration: 'italic',
                                                        }}
                                                    >
                                                        Type and search to find posts separate from the settings.
                                                    </span>
                                                </p>
                                            )} */}
                                                        </>
                                                    )}

                                                    {message.thoughtNews && (
                                                        <>
                                                            {basicSearchResponse.data.length !== 0 &&
                                                                basicSearchResponse.data.filter(
                                                                    val =>
                                                                        (val.title && val.title.length > 0) ||
                                                                        (val.title && val.title !== '') ||
                                                                        !val.title
                                                                ).length > 0 && (
                                                                    <>
                                                                        <div
                                                                            style={{ width: '80%' }}
                                                                            className="d-flex align-items-center justify-content-between m-auto btn-margin"
                                                                        >
                                                                            <Button
                                                                                variant="contained"
                                                                                style={{
                                                                                    background: '#186ade',
                                                                                    color: 'white',
                                                                                    borderRadius: '4px',
                                                                                    display: 'flex',
                                                                                    textTransform: 'none',
                                                                                    opacity:
                                                                                        selectedStories.length === 0 ||
                                                                                        newMessage === '' ||
                                                                                        summaryLoader
                                                                                            ? 0.6
                                                                                            : 1,
                                                                                }}
                                                                                disabled={
                                                                                    selectedStories.length === 0 ||
                                                                                    newMessage === '' ||
                                                                                    summaryLoader
                                                                                }
                                                                                onClick={generateIdeaFunction}
                                                                                className="font"
                                                                            >
                                                                                Generate Ideas
                                                                            </Button>
                                                                            <div />
                                                                            <div className="d-flex align-items-center">
                                                                                <Button
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        background: '#186ade',
                                                                                        color: 'white',
                                                                                        borderRadius: '4px',
                                                                                        display: 'flex',
                                                                                        textTransform: 'none',
                                                                                        opacity:
                                                                                            selectedStories.length ===
                                                                                                0 ||
                                                                                            newMessage === '' ||
                                                                                            summaryLoader
                                                                                                ? 0.6
                                                                                                : 1,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        generateDraft()
                                                                                    }}
                                                                                    disabled={
                                                                                        selectedStories.length === 0 ||
                                                                                        newMessage === '' ||
                                                                                        summaryLoader
                                                                                    }
                                                                                    className="font"
                                                                                >
                                                                                    Generate draft
                                                                                </Button>
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className="font-h text-center"
                                                                            style={{
                                                                                fontWeight: 600,
                                                                                marginBottom: '20px',
                                                                            }}
                                                                        >
                                                                            Select articles you like to generate ideas
                                                                            and drafts.
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div
                                                                                onClick={() => {
                                                                                    setNewsFilters(true)
                                                                                }}
                                                                                className="d-flex"
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                <span className="font-h">
                                                                                    Filters{' '}
                                                                                    {selectedSources.length !== 0 &&
                                                                                        '(' +
                                                                                            selectedSources.length +
                                                                                            ')'}
                                                                                </span>
                                                                                <span className="d-none d-sm-block">
                                                                                    <FilterListIcon className="font-h d-none d-sm-block" />
                                                                                </span>
                                                                            </div>
                                                                            <div className="font-h d-none d-sm-block">
                                                                                Showing 1 -{' '}
                                                                                {basicSearchResponse.data.length} of{' '}
                                                                                {basicSearchResponse.total} results
                                                                            </div>
                                                                            <div>
                                                                                {basicSearchResponse.data[0].score && (
                                                                                    <select
                                                                                        style={{
                                                                                            outline: 'none',
                                                                                            borderRadius: '4px',
                                                                                            background: 0,
                                                                                        }}
                                                                                        className="font"
                                                                                        value={sortBy}
                                                                                        onChange={e => {
                                                                                            setSortBy(e.target.value)
                                                                                        }}
                                                                                    >
                                                                                        <option
                                                                                            value={null}
                                                                                            style={{ display: 'none' }}
                                                                                        >
                                                                                            Sort by
                                                                                        </option>
                                                                                        <option
                                                                                            value="date"
                                                                                            style={{
                                                                                                borderRadius: '0px',
                                                                                            }}
                                                                                        >
                                                                                            Date
                                                                                        </option>
                                                                                        <option value="relevance">
                                                                                            Relevance
                                                                                        </option>
                                                                                    </select>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                background: '#00193C',
                                                                                color: 'white',
                                                                                padding: '10px',
                                                                                borderRadius: '4px 4px 0px 0px',
                                                                                marginTop: '10px',
                                                                            }}
                                                                            className="d-flex align-items-center justify-content-between"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: basicSearchResponse.data[0]
                                                                                        .score
                                                                                        ? '52%'
                                                                                        : '57%',
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{ marginLeft: '1%' }}
                                                                                    onClick={() =>
                                                                                        setAllSelected(!isAllSelected)
                                                                                    }
                                                                                >
                                                                                    {!isAllSelected ? (
                                                                                        <CheckBoxOutlineBlankIcon
                                                                                            style={{
                                                                                                fontSize: 'larger',
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <CheckBoxIcon
                                                                                            style={{
                                                                                                fontSize: 'larger',
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </span>
                                                                                <span
                                                                                    className="font-h ml-30"
                                                                                    // style={{
                                                                                    //     marginLeft: basicSearchResponse
                                                                                    //         .data[0].score
                                                                                    //         ? '3%'
                                                                                    //         : '6%',
                                                                                    // }}
                                                                                >
                                                                                    Title
                                                                                </span>
                                                                            </div>
                                                                            {basicSearchResponse.data[0].score && (
                                                                                <div
                                                                                    className="d-none d-sm-block font-h"
                                                                                    style={{ width: '10%' }}
                                                                                >
                                                                                    Relevance
                                                                                </div>
                                                                            )}
                                                                            <div
                                                                                className="d-none d-sm-block font-h"
                                                                                style={{
                                                                                    width: '15%',
                                                                                    marginLeft: basicSearchResponse
                                                                                        .data[0].score
                                                                                        ? '2%'
                                                                                        : '',
                                                                                }}
                                                                            >
                                                                                Source
                                                                            </div>
                                                                            <div
                                                                                className="d-none d-sm-block font-h"
                                                                                style={{
                                                                                    width: '15%',
                                                                                    marginRight: '1%',
                                                                                }}
                                                                            >
                                                                                Date
                                                                            </div>
                                                                            <div />
                                                                        </div>
                                                                        <InfiniteScroll
                                                                            pageStart={0}
                                                                            loadMore={handleLoadQuick}
                                                                            hasMore={
                                                                                basicSearchResponse.next <
                                                                                basicSearchResponse.total
                                                                            }
                                                                            loader={
                                                                                basicSearchResponse.next !== null && (
                                                                                    <Circles
                                                                                        key={0}
                                                                                        type="Circles"
                                                                                        color="#adadad"
                                                                                        height={80}
                                                                                        width={80}
                                                                                    />
                                                                                )
                                                                            }
                                                                        >
                                                                            {basicSearchResponse.data
                                                                                .sort((a, b) =>
                                                                                    sortBy === 'date'
                                                                                        ? a.date.split('T')[0] >
                                                                                          b.date.split('T')[0]
                                                                                            ? -1
                                                                                            : 1
                                                                                        : sortBy === 'relevance'
                                                                                        ? a.score > b.score
                                                                                            ? -1
                                                                                            : 1
                                                                                        : 0
                                                                                )
                                                                                .map(story => {
                                                                                    return (
                                                                                        <NewSearchQueryResult
                                                                                            isFindAndShare={true}
                                                                                            key={story.id}
                                                                                            EachrawNews={story}
                                                                                            isBasicSearch={true}
                                                                                            selectedStories={
                                                                                                selectedStories
                                                                                            }
                                                                                            isStorySelected={
                                                                                                selectedStories.findIndex(
                                                                                                    arr =>
                                                                                                        arr.id ===
                                                                                                        story.id
                                                                                                ) === -1
                                                                                                    ? false
                                                                                                    : true
                                                                                            }
                                                                                            addToSelection={
                                                                                                addToSelection
                                                                                            }
                                                                                            allCategories={[]}
                                                                                            allSubCategories={
                                                                                                allSubCategories
                                                                                            }
                                                                                            allIndustries={[]}
                                                                                            allCompanies={allCompanies}
                                                                                            setAllCompanies={
                                                                                                setAllCompanies
                                                                                            }
                                                                                            defaultData={defaultData}
                                                                                            setDefaultData={
                                                                                                setDefaultData
                                                                                            }
                                                                                            allRegions={[]}
                                                                                            allLinkedInGroups={[]}
                                                                                            removeFromSelection={
                                                                                                removeFromSelection
                                                                                            }
                                                                                            allTopics={[]}
                                                                                            collapsed={!expandedView}
                                                                                            usertype={usertype}
                                                                                            selectAll={isAllSelected}
                                                                                            currentPath={
                                                                                                window.location.pathname
                                                                                            }
                                                                                            selectedNewsOnly={
                                                                                                selectedNewsOnly
                                                                                            }
                                                                                            isScore={true}
                                                                                            generateSummary={
                                                                                                generateSummary
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                })}
                                                                        </InfiniteScroll>
                                                                    </>
                                                                )}
                                                        </>
                                                    )}
                                                    {message.news && (
                                                        <>
                                                            {message.news.map((news, index) => (
                                                                <div
                                                                    style={{
                                                                        borderRadius: '20px',
                                                                        marginRight: '5px',
                                                                        cursor: 'pointer',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                        margin: '10px',
                                                                        width: '80%',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            width: '60%',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        {index + 1 + '.'} {news.title}
                                                                    </span>
                                                                    <span style={{ width: '30%' }}>
                                                                        <a
                                                                            href={news.url}
                                                                            style={{ color: 'black' }}
                                                                            target="_blank"
                                                                        >
                                                                            {news.source_name}
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                    {message.alertNews && (
                                                        <div style={{ marginTop: '10px' }}>
                                                            {message.alertNews.slice(0, 5).map(story => {
                                                                return (
                                                                    <NewSearchQueryResult
                                                                        key={story.id}
                                                                        EachrawNews={story}
                                                                        isAssisstant={true}
                                                                        collapsed={!expandedView}
                                                                        allCategories={[]}
                                                                        allSubCategories={allSubCategories}
                                                                        allIndustries={[]}
                                                                        allCompanies={allCompanies}
                                                                        setAllCompanies={setAllCompanies}
                                                                        allRegions={[]}
                                                                        allLinkedInGroups={[]}
                                                                        allTopics={[]}
                                                                        usertype={usertype}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                    {message.schedule && (
                                                        <div className="d-flex align-items-center ">
                                                            <CronTab
                                                                isEnabled={isEnabled}
                                                                setIsEnabled={setIsEnabled}
                                                                cronTabValue={cronTabValue}
                                                                setCronTabValue={setCronTabValue}
                                                                isAssistant={true}
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                endIcon={<AiOutlineSave />}
                                                                style={{
                                                                    background: 'black',
                                                                    color: 'white',
                                                                    borderRadius: '20px',
                                                                    display: 'flex',
                                                                    marginLeft: '30px',
                                                                    marginTop: '30px',
                                                                }}
                                                                onClick={() => {
                                                                    setChatLoader(true)
                                                                    const defaultTemp = templates
                                                                        .filter(val =>
                                                                            val.label.toLowerCase().includes('compan')
                                                                        )
                                                                        .map(val => val.value)

                                                                    const defaultTemps = templates.map(
                                                                        val => val.value
                                                                    )[0]
                                                                    const saveQuery = {
                                                                        query_name: 'Alert: ' + message.type,
                                                                        quick_search_query: message.type,
                                                                        template:
                                                                            defaultTemp.length > 0
                                                                                ? defaultTemp[0]
                                                                                : defaultTemps,
                                                                        user_recipients: [userID],
                                                                        scheduled: true,
                                                                        cron_expression: LastCronValue(
                                                                            cronTabValue,
                                                                            '-'
                                                                        ),
                                                                        recipients: [userEmail],
                                                                        newsletter: 'saved_search_newsletter',
                                                                        section: 'companies',
                                                                        subject: 'Alerts :: ' + message.type,
                                                                        has_section: false,
                                                                        include_subcategory: false,
                                                                        curation_level: 'level0',
                                                                    }

                                                                    axios
                                                                        .post('/news/search-query/', saveQuery)
                                                                        .then(res => {
                                                                            const messagess = {
                                                                                text: `Your news alerts for ${message.type} are scheduled`,
                                                                                sender: 'sender',
                                                                                type: selectedState,
                                                                            }
                                                                            setMessages(history => [
                                                                                ...history,
                                                                                messagess,
                                                                            ])
                                                                        })
                                                                        .catch(err => {
                                                                            console.log(err)
                                                                        })
                                                                        .finally(() => {
                                                                            setChatLoader(false)
                                                                            window.scrollTo({
                                                                                top: document.body.scrollHeight,
                                                                                behavior: 'smooth',
                                                                            })
                                                                            setNewMessage('')
                                                                        })
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {typeof message.text === 'object' && (
                                                <div>
                                                    {message.report && (
                                                        <div style={{ marginTop: '5px' }}>
                                                            <p className="font-8">
                                                                Great! I can create a report for {message.text}. We have
                                                                two types of {selectedState} reports. Which one would
                                                                you prefer?
                                                            </p>
                                                            <div style={{ marginTop: '5px' }}>
                                                                {Object.entries(reportTypes).map(
                                                                    ([key, value], index) => (
                                                                        <span
                                                                            style={{
                                                                                border: '1px solid #bab4b4',
                                                                                padding: '5px',
                                                                                borderRadius: '20px',
                                                                                marginRight: '5px',
                                                                                fontWeight: 'bold',
                                                                                cursor: 'pointer',
                                                                                display: 'block',
                                                                                margin: '10px',
                                                                            }}
                                                                            className="font-8"
                                                                            onClick={() => {
                                                                                const userMessage = {
                                                                                    text: `Create ${key} for ${message.text}`,
                                                                                    sender: 'user',
                                                                                    section_type: selectedState,
                                                                                }
                                                                                setChatLoader(true)
                                                                                setMessages(prevMessages => [
                                                                                    ...prevMessages,
                                                                                    userMessage,
                                                                                ])
                                                                                handleReports(
                                                                                    message.text,
                                                                                    key,
                                                                                    message.optionType
                                                                                )
                                                                            }}
                                                                        >
                                                                            {`${index + 1}. ${key} - ${value}`}
                                                                        </span>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}{' '}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            {chatLoader && (
                                <div
                                    className="m-40"
                                    // style={{ margin: showSection ? '40px' : '10px' }}
                                >
                                    <div
                                        style={{
                                            // border: '1px solid lightgray',
                                            padding: '15px',
                                            borderRadius: '5px',
                                            marginRight: '5px',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            margin: '5px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            background: '#E6E8EC',
                                        }}
                                        className="font"
                                    >
                                        <span className="round_loader" />{' '}
                                        <span style={{ marginLeft: '10px' }} className="font">
                                            {progressContent ? progressContent : 'Working on it'}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {draftSection && !ideaSection && (
                    <div style={{ padding: '10px' }}>
                        <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '20px' }}
                        >
                            <div style={{ zIndex: '10' }}>
                                <span>
                                    <ArrowBackIcon
                                        onClick={() => {
                                            setDraftSection(false)
                                            if (generateIdeas.length > 0) {
                                                setIdeaSection(true)
                                            }
                                            setDraftContent(null)
                                        }}
                                        style={{ marginLeft: '-20px' }}
                                    />
                                </span>{' '}
                                <label className="font-h" style={{ marginLeft: '0px' }}>
                                    AI Generated Draft
                                </label>
                            </div>

                            {draftContent !== null && (
                                <>
                                    <FiDownload
                                        style={{ cursor: 'pointer', width: '24px', height: '24px', zIndex: '10' }}
                                        onClick={wordDownload}
                                        className="mr-40"
                                    />
                                </>
                            )}
                        </div>
                        {draftLoader ? (
                            <div className="m-40">
                                <div
                                    style={{
                                        // border: '1px solid lightgray',
                                        padding: '15px',
                                        borderRadius: '5px',
                                        marginRight: '5px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        margin: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: '#E6E8EC',
                                    }}
                                    className="font"
                                >
                                    <span className="round_loader" />{' '}
                                    <span style={{ marginLeft: '10px' }}>Working on it</span>
                                </div>
                            </div>
                        ) : (
                            <div id="draft_content" style={{ margin: '20px' }}>
                                {draftContent !== null ? (
                                    <div>
                                        {/* <div style={{ fontSize: '20px', marginLeft: '10px', color: '#3048A6' }}>
                                            Innovations in {newMessage + ': '} Enhancing Efficiency and Scalability
                                        </div> */}

                                        <div>
                                            {draftContent['Executive summary'] && (
                                                <div style={{ marginTop: '20px' }}>
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            fontWeight: 'bold',
                                                            color: 'black',
                                                        }}
                                                        className="font-18"
                                                    >
                                                        Executive summary
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            marginTop: '20px',
                                                            textAlign: 'justify',
                                                        }}
                                                        className="font-16"
                                                    >
                                                        {draftContent['Executive summary']}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {draftContent.Introduction && draftContent.Introduction.content && (
                                                <div style={{ marginTop: '20px' }}>
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            fontWeight: 'bold',
                                                            color: 'black',
                                                        }}
                                                        className="font-18"
                                                    >
                                                        {draftContent.Introduction.title
                                                            ? draftContent.Introduction.title
                                                            : 'Introduction'}
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            marginTop: '20px',
                                                            textAlign: 'justify',
                                                        }}
                                                        className="font-16"
                                                    >
                                                        {draftContent.Introduction.content}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            {draftContent['Detail Section'] &&
                                                draftContent['Detail Section'].sections &&
                                                draftContent['Detail Section'].sections.length > 0 && (
                                                    <div
                                                    // style={{ marginTop: '20px' }}
                                                    >
                                                        {/* <div
                                                            style={{
                                                                fontSize: '18px',
                                                                marginLeft: '10px',
                                                                fontWeight: 'bold',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {draftContent['Detail Section'].title &&
                                                                draftContent['Detail Section'].title}
                                                        </div> */}
                                                        <div>
                                                            {draftContent['Detail Section'].sections.map(val => (
                                                                <div style={{ marginTop: '20px' }}>
                                                                    <div
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            textDecoration: 'underline',
                                                                        }}
                                                                        className="font-18"
                                                                    >
                                                                        {val.sectionTitle && val.sectionTitle}
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            marginTop: '15px',
                                                                            textAlign: 'justify',
                                                                        }}
                                                                        className="font-16"
                                                                    >
                                                                        {val.content && val.content}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                        </div>

                                        <div>
                                            {draftContent['Practical Applications'] &&
                                                draftContent['Practical Applications'].sections &&
                                                draftContent['Practical Applications'].sections.length > 0 && (
                                                    <div
                                                    //  style={{ marginTop: '20px' }}
                                                    >
                                                        {/* <div
                                                            style={{
                                                                fontSize: '18px',
                                                                marginLeft: '10px',
                                                                fontWeight: 'bold',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {draftContent['Practical Applications'].title &&
                                                                draftContent['Practical Applications'].title}
                                                        </div> */}
                                                        <div>
                                                            {draftContent['Practical Applications'].sections.map(
                                                                val => (
                                                                    <div style={{ marginTop: '20px' }}>
                                                                        <div
                                                                            style={{
                                                                                marginLeft: '10px',
                                                                                textDecoration: 'underline',
                                                                            }}
                                                                            className="font-18"
                                                                        >
                                                                            {val.sectionTitle && val.sectionTitle}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                marginLeft: '10px',
                                                                                marginTop: '15px',
                                                                                textAlign: 'justify',
                                                                            }}
                                                                            className="font-16"
                                                                        >
                                                                            {val.content && val.content}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                        <div>
                                            {draftContent['Future Trends'] &&
                                                draftContent['Future Trends'].sections &&
                                                draftContent['Future Trends'].sections.length > 0 && (
                                                    <div
                                                    // style={{ marginTop: '20px' }}
                                                    >
                                                        {/* <div
                                                            style={{
                                                                fontSize: '18px',
                                                                marginLeft: '10px',
                                                                fontWeight: 'bold',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {draftContent['Future Trends'].title &&
                                                                draftContent['Future Trends'].title}
                                                        </div> */}
                                                        <div>
                                                            {draftContent['Future Trends'].sections.map(val => (
                                                                <div style={{ marginTop: '20px' }}>
                                                                    <div
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            textDecoration: 'underline',
                                                                        }}
                                                                        className="font-18"
                                                                    >
                                                                        {val.sectionTitle && val.sectionTitle}
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            marginLeft: '10px',
                                                                            marginTop: '15px',
                                                                            textAlign: 'justify',
                                                                        }}
                                                                        className="font-16"
                                                                    >
                                                                        {val.content && val.content}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                        </div>

                                        <div>
                                            {draftContent.Conclusion && draftContent.Conclusion.content && (
                                                <div style={{ marginTop: '20px' }}>
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            fontWeight: 'bold',
                                                            color: 'black',
                                                        }}
                                                        className="font-18"
                                                    >
                                                        {draftContent.Conclusion.title
                                                            ? draftContent.Conclusion.title
                                                            : 'Conclusion'}
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            marginTop: '20px',
                                                            textAlign: 'justify',
                                                        }}
                                                        className="font-16"
                                                    >
                                                        {draftContent.Conclusion.content}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="p-15"
                                        style={{
                                            marginTop: '50px',
                                            width: '90%',
                                            margin: 'auto',
                                            background: '#E6E8EC',
                                        }}
                                    >
                                        Sorry, there was an error in generating. Try again
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {ideaSection && !draftSection && (
                    <>
                        <div className="d-flex align-items-center font-h" style={{ cursor: 'pointer' }}>
                            <span style={{ zIndex: '10' }}>
                                <ArrowBackIcon
                                    onClick={() => {
                                        setIdeaSection(false)
                                        setGenerateIdeas([])
                                    }}
                                />
                            </span>{' '}
                            <label className="font-h" style={{ marginLeft: '10px' }}>
                                Thought Leadership Ideas
                            </label>
                        </div>

                        {draftLoader ? (
                            <div className="m-40">
                                <div
                                    style={{
                                        padding: '15px',
                                        borderRadius: '5px',
                                        marginRight: '5px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        margin: '5px',
                                        display: 'flex',
                                        background: '#E6E8EC',
                                    }}
                                    className="font"
                                >
                                    <span className="round_loader" />{' '}
                                    <span style={{ marginLeft: '10px' }}>Working on it</span>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {generateIdeas.length > 0 ? (
                                    <>
                                        {generateIdeas.map(idea => {
                                            return (
                                                <div
                                                    style={{
                                                        background: 'white',
                                                        padding: '20px',
                                                        width: '80%',
                                                        margin: 'auto',
                                                        marginTop: '30px',
                                                        cursor: 'pointer',
                                                        borderRadius: '4px',
                                                    }}
                                                    className="idea_container"
                                                >
                                                    <div style={{ fontWeight: 800 }} className="font-16">
                                                        {idea.Title}
                                                    </div>
                                                    <div
                                                        className="font-16"
                                                        style={{ padding: '10px 0px', textAlign: 'justify' }}
                                                    >
                                                        {idea.Storyline}
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            cursor: 'pointer',
                                                            borderRadius: '5px',
                                                            fontWeight: 500,
                                                            textTransform: 'none',
                                                            display: 'flex',
                                                            marginLeft: 'auto',
                                                        }}
                                                        className="font draft_btn"
                                                        onClick={() => {
                                                            generateDraft(idea)
                                                        }}
                                                    >
                                                        Generate Draft
                                                    </Button>
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <div
                                        className="p-15"
                                        style={{
                                            marginTop: '50px',
                                            width: '90%',
                                            margin: 'auto',
                                            background: '#E6E8EC',
                                        }}
                                    >
                                        Sorry, there was an error in generating. Try again
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        position: 'fixed',
                        bottom: 0,
                        paddingBottom: '10px',
                        background: '#F2F5F7',
                        zIndex: 1,
                    }}
                >
                    {selectedState !== 'default' &&
                        selectedState !== 'Thought' &&
                        (selectedState !== 'SocialClient' || !defaultRecommendationSearch) &&
                        openOPtions &&
                        newMessage.length > 2 &&
                        optionData.length > 0 && (
                            <div style={{ width: '80%', margin: '10px' }}>
                                {optionData.length > 5 ? (
                                    <ScrollMenu
                                        data={dataMatches}
                                        arrowLeft={<ArrowBackIosNewSharp style={{ cursor: 'pointer' }} />}
                                        arrowRight={<ArrowForwardIosSharp style={{ cursor: 'pointer' }} />}
                                        dragging={true}
                                        wheel={false}
                                        hideSingleArrow={true}
                                        translate={5}
                                    />
                                ) : (
                                    <div className="d-flex">{dataMatches}</div>
                                )}
                            </div>
                        )}
                    {selectedState !== 'default' &&
                        selectedState !== 'SocialClient' &&
                        selectedState !== 'Thought' &&
                        InputSection()}

                    {/* {showSection && document.getElementById('use_search') === null && InputSection()} */}
                    {showSection && !defaultRecommendationSearch && InputSection()}
                </div>
            </div>

            <Modal
                isOpen={iframeReport}
                toggle={() => {
                    setIframeReport(!iframeReport)
                }}
                style={{ height: '100%' }}
                size="lg"
                className="pdf_viewer"
            >
                <ModalBody style={{ height: '100%' }}>
                    <div class="container">
                        <iframe title="PDF Viewer" src={chatDownload.document} />
                    </div>
                </ModalBody>
            </Modal>

            {quickReportModal && (
                <QuickReportPreview
                    quickReportModal={quickReportModal}
                    setQuickReportModal={setQuickReportModal}
                    jsonData={reportView}
                />
            )}

            {detailedReportModal && (
                <DetailedReportPreview
                    detailedReportModal={detailedReportModal}
                    setDetailedReportModal={setDetailedReportModal}
                    jsonData={reportView}
                />
            )}
        </BaseScreen>
    )
}
