import React, { useState, useEffect } from 'react'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Form, Formik, Field } from 'formik'
import { Button } from 'reactstrap'
import AsyncSelect from 'react-select/async'
import { newUserValidate } from './AnalystAdminValidation'
import AutoCompleteFilter from '../../components/AutoCompleteFilter/AutoCompleteFilter'
import BaseScreen from '../BaseScreen/BaseScreen'
import AdminHeader from './components/AdminHeader'
import { addUserFields as fields } from './constants/AnalystAdminConstants'
import AssignContacts from './components/AssignContacts'
import store from '../../Store'
import { getCookie } from '../../utils'
import RegionHiearchyMultiSelect from '../NewsFeedScreen/components/RegionHiearchyMultiSelect'
import { FaArrowLeft, FaLongArrowAltLeft, FaMinus, FaPlus } from 'react-icons/fa'
import { display } from '@mui/system'
import { Tab, Tabs } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Cron } from 'react-js-cron'
import PreferenceContainers from './PreferenceContainers'
import PreferenceSectorContainer from './PreferenceSectorContainer'
import DataTable from 'react-data-table-component'
import CronTab from './CronTab'
import { Tooltip } from 'reactstrap'
import InfoIcon from '@mui/icons-material/Info'
import { LastCronValue } from '../../utils/dashboardStrings'
const qs = require('query-string')
var lo_ = require('lodash')

export default function AnalystAdminAddUser(props) {
    const path = props.match.path
    const usertype = store.getState().auth.user.user_type
    const demo = store.getState().auth

    const [allPracticeAreas, setAllPracticeAreas] = useState([])
    const [selectedPracticeArea, setSelectedPracticeArea] = useState({})
    const [allSectors, setAllSectors] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])
    const [allIndustries, setAllIndustries] = useState([])
    const [selectedIndustries, setSelectedIndustries] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [pageError, setPageError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [emailExists, setEmailExists] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const [onEditPage, setOnEditPage] = useState(false)
    const [selectedContacts, setSelectedContacts] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [selectedCountry, setSelectedCountry] = useState([])
    const [defaultCountry, setDefaultCountry] = useState([])
    const [cronTabValue, setCronTabValue] = useState('0 0 * * 1')
    const [editCron, setEditCron] = useState(null)
    const [lastScheduled, setLastScheduled] = useState(null)
    const [nextScheduled, setNextScheduled] = useState(null)

    const [allSubCats, setAllSubCats] = useState([])
    const [selectedSubCats, setSelectedSubCats] = useState([])
    const [allTopics, setAllTopics] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [allRegions, setAllRegions] = useState([])
    const [userId, setUserId] = useState()
    const [userFirmId, setUserFirmId] = useState(null)
    const [isEnabled, setIsEnabled] = useState(false)
    const [role, setRole] = useState([])
    const [selectedRole, setSelectedRole] = useState(null)
    const [allCompetitors, setAllCompetitors] = useState([])
    const [selectedCompetitors, setSelectedCompetitors] = useState([])
    const [allFirms, setAllFirms] = useState([])
    const [selUSer, setSelUser] = useState('Regular User')

    const [activeTab, setActiveTab] = useState(0)
    const [input, setInput] = useState('')
    const [selectedinput, setSelectedInput] = useState('')
    // const [allCompanies, setAllCompanies] = useState([])
    const [allHierarchy, setAllHierarchy] = useState([])
    const [sectorOpen, setSectorOpen] = useState([])
    const [topicOpen, setTopicOpen] = useState([])
    const [sectorInput, setsectorInput] = useState('')
    const [checkedTopics, setCheckedTopics] = useState(false)
    const stateMap = {
        Industry: [selectedIndustries, setSelectedIndustries],
        sector: [selectedSectors, setSelectedSectors],
        company: [selectedCompanies, setSelectedCompanies],
        business: [selectedSubCats, setSelectedSubCats],
        topic: [selectedTopics, setSelectedTopics],
        competitor: [selectedCompetitors, setSelectedCompetitors],
        savedsearch: [selectSavedSearches, setSelectSavedSearches],
    }
    const [tooltips, setTooltips] = useState({
        user_info_tooltip: false,
        user_companies: false,
        user_topics: false,
        user_role: false,
        user_practice_area: false,
    })
    const [allWithoutHierarchy, setAllWithoutHierarchy] = useState([])
    const [allSavedSearches, setAllSavedSearches] = useState([])
    const [selectSavedSearches, setSelectSavedSearches] = useState([])
    const [thoughtCronTabValue, setThoughtCronTabValue] = useState('0 0 * * 1')
    const [thoughtEditCron, setThoughtEditCron] = useState(null)
    const [thoughtIsEnabled, setThoughtIsEnabled] = useState(false)
    const [digestCronTabValue, setDigestCronTabValue] = useState('0 0 * * 1')
    const [digestEditCron, setDigestEditCron] = useState(null)
    const [digestIsEnabled, setDigestIsEnabled] = useState(false)

    const tooltipStyle = {
        backgroundColor: '#333', // Background color
        color: '#fff', // Text color
    }

    const toggle = tooltip => {
        setTooltips({ ...tooltips, [tooltip]: !tooltips[tooltip] })
    }

    const allIndustriesName = allHierarchy.map(val => val)

    const allSectorsName = allHierarchy.map(val => val.sectors).reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const handleSelectAllTopics = () => {
        if (checkedTopics) {
            setSelectedSectors([])
        } else {
            const filteredItems = allHierarchy.filter(ind => {
                const matchingSectors = ind.sectors.filter(sec =>
                    sec.topics.some(topic => topic.name.toLowerCase().includes(sectorInput.toLowerCase()))
                )

                return (
                    ind.name.toLowerCase().includes(sectorInput.toLowerCase()) ||
                    matchingSectors.length > 0 ||
                    ind.sectors.some(sec => sec.name.toLowerCase().includes(sectorInput.toLowerCase()))
                )
            })

            const filteredTopicLabels = filteredItems.reduce((result, val) => {
                result.push(val.name)

                val.sectors.forEach(sector => {
                    result.push(sector.name)

                    sector.topics.forEach(topic => {
                        if (topic.name.toLowerCase().includes(sectorInput.toLowerCase())) {
                            result.push(topic.name)
                        }
                    })
                })

                return result
            }, [])
            setSelectedSectors(filteredTopicLabels)
        }
        setCheckedTopics(!checkedTopics)
    }

    const handleRemoveTopic = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        setSelectedItems(selectedItems.filter(val => val !== id))
    }

    const handleRemove = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        setSelectedItems(selectedItems.filter(val => val.value !== id))
    }

    const handleToggle = (type, id) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        // const isItemSelected = selectedItems.includes(id)
        // if (isItemSelected) {
        //     const filteredItems = selectedItems.filter(item => item !== id)
        //     setSelectedItems(filteredItems)
        // } else {
        //     setSelectedItems([...selectedItems, id])
        // }
        setSelectedItems([...id])
    }

    const toggleid = (Id, type) => {
        type(prevExpandedValues => {
            if (prevExpandedValues.includes(Id)) {
                return prevExpandedValues.filter(id => id !== Id)
            } else {
                return [...prevExpandedValues, Id]
            }
        })
    }

    const bulkChange = (type, data, name) => {
        const [selectedItems, setSelectedItems] = stateMap[type]

        let result = selectedItems

        const isSelectedItem = selectedItems.find(item => item === name)
        data.forEach(list => {
            if (isSelectedItem) {
                result = result.filter(item => item !== list.name)
            } else {
                result.push(list.name)
            }
        })

        setSelectedItems([...result])
    }

    const changeCheck = (type, id, typeOf = null, value = null) => {
        const [selectedItems, setSelectedItems] = stateMap[type]
        const isItemSelected = selectedItems.includes(id)
        if (isItemSelected) {
            const filteredItems = selectedItems.filter(item => item !== id)
            setSelectedItems(filteredItems)
        } else {
            setSelectedItems([...selectedItems, id])
        }
    }

    useEffect(() => {
        if (path === '/app/preferences/') {
            const id = store.getState().auth.user.id
            setUserId(id)
        } else {
            const id = props.match.params.id
            setUserId(id)
        }
        setUserFirmId(store.getState().auth.user.firm_id)
    }, [store.getState().auth.user.id])
    const { query } = qs.parseUrl(props.location.search)

    useEffect(() => {
        getInitialFetchData()
        // const id = store.getState().auth.user.id
        // setUserId(id)
        // setUserId(userId)
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'Add User'
    }, [userId, defaultCountry.length > 0])

    const getInitialFetchData = async () => {
        let allPracticeAreasTemp
        await axios
            .get('/news/practice-areas/')
            .then(response => {
                allPracticeAreasTemp = response.data
                setAllPracticeAreas(response.data)
            })
            .catch(err => console.log(err))

        getAllTopics()
        getAllRegions()
        getRegionHierarchy()
        getRoleSearch()
        getAllCompetitors()
        // getCron()

        axios
            .get('/news/all-companies/')
            .then(response => {
                let remappedTopics = response.data.map(item => {
                    return { value: item.value, label: item.label }
                })
                setAllCompanies(remappedTopics)
            })
            .catch(err => console.log(err))

        await axios
            .get('/news/industries-sectors-topics/')
            .then(industriesResponse => {
                const result = industriesResponse.data.flatMap(industry => [
                    { value: industry.id, label: industry.name, type: 'industries' },
                    ...industry.sectors.flatMap(sector => [
                        { value: sector.id, label: sector.name, type: 'sectors' },
                        ...sector.topics.map(topic => ({
                            value: topic.id,
                            label: topic.name,
                            type: 'topics',
                        })),
                    ]),
                ])

                return axios.get('/news/all-subcategories/').then(subCategoriesResponse => {
                    const subCategories = subCategoriesResponse.data.map(val => ({
                        value: val.value,
                        label: val.label,
                        type: 'subcategories',
                    }))

                    // Combine results and subCategories
                    const combinedResults = [...result, ...subCategories]

                    // Create a Set to ensure unique values
                    const uniqueResults = Array.from(
                        new Set(combinedResults.map(item => JSON.stringify(item)))
                    ).map(item => JSON.parse(item))

                    setAllSubCats(subCategories)
                    setAllWithoutHierarchy(uniqueResults)
                    setAllHierarchy(industriesResponse.data)
                })
            })
            .catch(err => console.log(err))

        await axios
            .get('/analyst/firms/')
            .then(response => {
                setAllFirms(response.data.firms)
            })
            .catch(err => console.log(err))

        await axios
            .get(`/news/all-industries-sectors/`)
            .then(response => {
                const updatedIndustries = []
                response.data.forEach(item => {
                    if (item.nick_name) {
                        const tempObj = { ...item }
                        tempObj.label = item.nick_name
                        updatedIndustries.push(tempObj)
                    } else {
                        updatedIndustries.push(item)
                    }
                })
                setAllSectors(updatedIndustries.filter(item => item.type === 'Sector'))
                setAllIndustries(updatedIndustries.filter(item => item.type === 'Industry'))
            })
            .catch(err => console.log(err))
        if (query.firm) {
            getFirmSectorsCompanies(query.firm, { subscriptionType: query.subType })
        }
        if (userId !== null && userId !== undefined && userId !== '') {
            setOnEditPage(true)
            await axios
                .get(`endusers/all-endusers/?pk=${userId}`)
                .then(async userResponse => {
                    setUserInfo(userResponse.data)
                    setSelectedRole(userResponse.data.role)
                    const addType = (items, type) => items.map(item => ({ ...item, type }))

                    const combinedData = [
                        ...addType(userResponse.data.sectors, 'sectors'),
                        ...addType(userResponse.data.industries, 'industries'),
                        ...addType(userResponse.data.topics, 'topics'),
                        ...addType(userResponse.data.subcategories, 'subcategories'),
                    ]
                    setSelectedSectors(combinedData)

                    setSelectedCompanies(userResponse.data.companies)
                    const userPracticeArea = allPracticeAreasTemp.find(
                        item => item.name === userResponse.data.practice_area
                    )
                    setSelectedPracticeArea({
                        value: userPracticeArea ? userPracticeArea.id : 0,
                        label: userPracticeArea ? userPracticeArea.name : '',
                    })
                    // setSelectedSubCats(userResponse.data.subcategories.map(val => val.value))
                    setSelectedCompetitors(userResponse.data.competitors)
                    axios
                        .get('/news/search-query/')
                        .then(res => {
                            const savedData = res.data
                                .filter(val => val.firm === userResponse.data.firm)
                                .map(val => {
                                    return { value: val.id, label: val.query_name }
                                })

                            setAllSavedSearches(savedData)
                        })
                        .catch(err => console.log(err))
                    setSelectSavedSearches(userResponse.data.saved_search_queries)

                    const scheduled_newsletters = userResponse.data && userResponse.data.scheduled_newsletters
                    if (scheduled_newsletters) {
                        if (scheduled_newsletters.social_post_recommendations) {
                            if (scheduled_newsletters.social_post_recommendations.cron_expression) {
                                setCronTabValue(
                                    LastCronValue(
                                        scheduled_newsletters.social_post_recommendations.cron_expression,
                                        '+'
                                    )
                                )
                                setIsEnabled(true)
                            } else {
                                setIsEnabled(false)
                            }
                            if (scheduled_newsletters.social_post_recommendations.apscheduler_id) {
                                setEditCron(scheduled_newsletters.social_post_recommendations.apscheduler_id)
                            } else {
                                setEditCron(null)
                            }
                        }
                    }
                    if (scheduled_newsletters.thought_leadership) {
                        if (scheduled_newsletters.thought_leadership.cron_expression) {
                            setThoughtCronTabValue(
                                LastCronValue(scheduled_newsletters.thought_leadership.cron_expression, '+')
                            )
                            setThoughtIsEnabled(true)
                        } else {
                            setThoughtIsEnabled(false)
                        }
                        if (scheduled_newsletters.thought_leadership.apscheduler_id) {
                            setThoughtEditCron(scheduled_newsletters.thought_leadership.apscheduler_id)
                        } else {
                            setThoughtEditCron(null)
                        }
                    }
                    if (scheduled_newsletters.kaitongo_digest) {
                        if (scheduled_newsletters.kaitongo_digest.cron_expression) {
                            setDigestCronTabValue(
                                LastCronValue(scheduled_newsletters.kaitongo_digest.cron_expression, '+')
                            )
                            setDigestIsEnabled(true)
                        } else {
                            setDigestIsEnabled(false)
                        }
                        if (scheduled_newsletters.kaitongo_digest.apscheduler_id) {
                            setDigestEditCron(scheduled_newsletters.kaitongo_digest.apscheduler_id)
                        } else {
                            setDigestEditCron(null)
                        }
                    }

                    const addCountry = userResponse.data.country.map(val => {
                        return {
                            id: val.value,
                            name: val.label,
                            type: 'Country',
                        }
                    })
                    const addRegion = userResponse.data.regions.map(val => {
                        return {
                            id: val.value,
                            name: val.label,
                            type: 'Region',
                        }
                    })
                    const addProvince = userResponse.data.provinces.map(val => {
                        return {
                            id: val.value,
                            name: val.label,
                            type: 'Province',
                        }
                    })
                    const addingAll = [...addRegion, ...addCountry, ...addProvince]

                    setDefaultCountry(addingAll)
                })
                .catch(err => console.log(err))
            // getUserContacts()
        }
    }

    const getAllCompetitors = async () => {
        await axios
            .get('/news/all-companies/')
            .then(response => {
                let remappedTopics = response.data.map(item => {
                    return { value: item.value, label: item.label }
                })
                setAllCompetitors(remappedTopics)
            })
            .catch(err => console.log(err))
    }

    const getAllRegions = () => {
        axios
            .get(`/news/all-regions/`)
            .then(response => {
                setAllRegions(response.data)
            })
            .catch(err => console.log(err))
    }

    const getCompaniesSearch = value => {
        if (value.length > 2) {
            return axios
                .get(`/news/auto-complete/?type=company&string=${encodeURIComponent(value)}`)
                .then(response => {
                    return response.data.map(item => ({ label: item.name, value: item.id }))
                })
                .catch(err => console.log(err))
        }
    }

    const getFirmSectorsCompanies = (id, values) => {
        if (values.subscriptionType === 'Firm') {
            axios
                .get(`/accounts/firm/?pk=${id}`)
                .then(response => {
                    setAllIndustries(response.data.industries)
                    setAllSectors(response.data.sectors)
                    setAllCompanies(response.data.companies)
                })
                .catch(err => console.log(err))
        }
    }

    const getRegionHierarchy = async () => {
        await axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)
        })
    }

    const getCron = () => {
        axios
            .get(`/core/schedule-newsletter/`)
            .then(res => {
                if (res.data.length > 0) {
                    setEditCron(res.data[res.data.length - 1].ap_scheduler)
                    setCronTabValue(res.data[res.data.length - 1].cron_expression)
                }
            })
            .catch(err => console.log(err))
    }

    const validateEmail = mail => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            checkEmailExists(mail)
            return setEmailError(false)
        } else {
            return setEmailError(true)
        }
    }

    const checkEmailExists = email => {
        axios
            .get(`/endusers/check-email?email=${email}`)
            .then(response => {
                if (response.data.message === true) {
                    setEmailExists(true)
                } else {
                    setEmailExists(false)
                }
            })
            .catch(err => console.log(err))
    }

    const sendNewsletter = () => {
        axios
            .get(`/accounts/analystmail/${userId}/`)
            .then(res => {
                if (res.status === 200) {
                    toast('Newsletter sent to kaitongoanalyst@kaitongo.com')
                } else if (res.status === 204) {
                    toast.warn('No content to send')
                }
            })
            .catch(err => {
                console.log(err)
                toast.error('error sending newsletter')
            })
    }

    const getUserContacts = () => {
        axios
            .get(`/news/user-contacts/?id=${userId}`)
            .then(response => {
                const contacts = response.data.people
                setSelectedContacts(lo_.orderBy(contacts, ['first_name'], ['asc']))
            })
            .catch(err => console.log(err))
    }

    const getAllTopics = async () => {
        await axios
            .get('/news/topics/')
            .then(response => {
                let remappedTopics = response.data.map(item => {
                    return { value: item.id, label: item.name }
                })
                remappedTopics = lo_.orderBy(remappedTopics, ['label'], ['asc'])
                setAllTopics(remappedTopics)
            })
            .catch(err => console.log(err))
    }

    const getRoleSearch = () => {
        axios
            .get(`/accounts/user-roles/`)
            .then(response => {
                setRole(response.data)
            })
            .catch(err => console.log(err))
    }

    const getSubcategoriesFromPracticeArea = ev => {
        let selectedPracticeTemp
        selectedPracticeTemp = allPracticeAreas.find(item => item.id === Number(ev.target.value))
        setSelectedPracticeArea({
            label: selectedPracticeTemp.name,
            value: Number(ev.target.value),
        })
        // setSelectedSubCats([])
        // axios
        //     .get(`/news/subcategories-from-practice-area/?practiceAreas=${ev.target.value}`)
        //     .then(res => {
        //         setSelectedSubCats(res.data.results)
        //     })
        //     .catch(err => console.log(err))
    }

    const onSubmit = values => {
        if (values.email === '' || values.first_name === '') {
            toast.warn('Some mandatory fields missing.')
        } else if (emailError) {
            toast.warn('Email invalid')
        } else if (emailExists) {
            toast.warn('Email already exists')
        } else {
            const userSubmitObject = { ...values }
            if (onEditPage) {
                userSubmitObject.id = userId
            }
            if (selectedRole === 'null') {
                userSubmitObject.role = null
            } else {
                userSubmitObject.role = selectedRole
            }
            // userSubmitObject.sectors = selectedSectors.map(item => item.value)
            // userSubmitObject.industries = selectedIndustries.map(item => item.value)
            if (usertype === 'Power User') {
                userSubmitObject.firm = userFirmId
            } else {
                userSubmitObject.firm = Number(values.firm)
            }

            if (userSubmitObject.user_type) {
                userSubmitObject.user_type = selUSer
            }

            userSubmitObject.sectors = selectedSectors.filter(val => val.type === 'sectors').map(val => val.value)
            userSubmitObject.industries = selectedSectors.filter(val => val.type === 'industries').map(val => val.value)
            userSubmitObject.topics = selectedSectors.filter(val => val.type === 'topics').map(val => val.value)
            userSubmitObject.subcategories = selectedSectors
                .filter(val => val.type === 'subcategories')
                .map(val => val.value)
            userSubmitObject.saved_search_queries = selectSavedSearches.map(val => val.value)
            userSubmitObject.companies = selectedCompanies.map(ele => ele.value)
            userSubmitObject.competitors = selectedCompetitors.map(ele => ele.value)
            userSubmitObject.practice_area = selectedPracticeArea.value
            userSubmitObject.country = selectedCountry
                .filter(value => value.type === 'Country')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                })
            userSubmitObject.regions = selectedCountry
                .filter(value => value.type === 'Region')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                })
            userSubmitObject.provinces = selectedCountry
                .filter(value => value.type === 'Province')
                .map(value => {
                    return parseInt(value.value.split('_')[1])
                })
            userSubmitObject.receive_verification_emails = true
            const token = getCookie('csrftoken')
            axios.defaults.headers.common['X-CSRFToken'] = token
            axios
                .post('/accounts/create-user/', userSubmitObject, {
                    headers: { 'X-CSRFToken': token },
                })
                .then(response => {
                    let contactString
                    if (selectedContacts.length === 0) {
                        contactString = []
                    } else {
                        contactString = selectedContacts.map(item => item.id).join()
                    }
                    let contactObject = {
                        userID: response.data.pk,
                        contactIDs: contactString,
                    }
                    let scheduling = {
                        scheduled_newsletter: {
                            thought_leadership: {},
                            social_post_recommendations: {},
                            kaitongo_digest: {},
                        },
                        recipients: [values && values.email],
                        user: response.data.pk,
                    }

                    if (editCron !== null) {
                        scheduling.scheduled_newsletter.social_post_recommendations.apscheduler_id = editCron
                        scheduling.scheduled_newsletter.social_post_recommendations.cron_expression = LastCronValue(
                            cronTabValue,
                            '-'
                        )
                        scheduling.scheduled_newsletter.social_post_recommendations.enabled = isEnabled
                        scheduling.scheduled_newsletter.social_post_recommendations.subject =
                            'Kaitongo Social Media Post Recommendation'
                    } else {
                        scheduling.scheduled_newsletter.social_post_recommendations.cron_expression = LastCronValue(
                            cronTabValue,
                            '-'
                        )
                        scheduling.scheduled_newsletter.social_post_recommendations.enabled = isEnabled
                        scheduling.scheduled_newsletter.social_post_recommendations.subject =
                            'Kaitongo Social Media Post Recommendation'
                    }
                    if (thoughtEditCron !== null) {
                        scheduling.scheduled_newsletter.thought_leadership.apscheduler_id = thoughtEditCron
                        scheduling.scheduled_newsletter.thought_leadership.cron_expression = LastCronValue(
                            cronTabValue,
                            '-'
                        )
                        scheduling.scheduled_newsletter.thought_leadership.enabled = thoughtIsEnabled
                        scheduling.scheduled_newsletter.thought_leadership.subject =
                            'Kaitongo Thought Leadership Content Ideas'
                    } else {
                        scheduling.scheduled_newsletter.thought_leadership.cron_expression = LastCronValue(
                            cronTabValue,
                            '-'
                        )
                        scheduling.scheduled_newsletter.thought_leadership.enabled = thoughtIsEnabled
                        scheduling.scheduled_newsletter.thought_leadership.subject =
                            'Kaitongo Thought Leadership Content Ideas'
                    }
                    if (digestEditCron !== null) {
                        scheduling.scheduled_newsletter.kaitongo_digest.apscheduler_id = digestEditCron
                        scheduling.scheduled_newsletter.kaitongo_digest.cron_expression = LastCronValue(
                            digestCronTabValue,
                            '-'
                        )
                        scheduling.scheduled_newsletter.kaitongo_digest.enabled = digestIsEnabled
                        scheduling.scheduled_newsletter.kaitongo_digest.subject =
                            'Kaitongo Digest - Your Personalized News Digest'
                    } else {
                        scheduling.scheduled_newsletter.kaitongo_digest.cron_expression = LastCronValue(
                            digestCronTabValue,
                            '-'
                        )
                        scheduling.scheduled_newsletter.kaitongo_digest.enabled = digestIsEnabled
                        scheduling.scheduled_newsletter.kaitongo_digest.subject =
                            'Kaitongo Digest - Your Personalized News Digest'
                    }
                    //scheduling
                    axios.post(`/core/schedule-newsletter/`, scheduling).then(res => {})
                    toast(onEditPage ? 'User Updated' : 'User Created')
                    props.history.push('/app/admin/enduser/')
                })
                .catch(err => {
                    console.log(err)
                    toast.warn('Error creating user-', err.message)
                })
        }
    }

    return (
        <BaseScreen>
            {/* <AdminHeader currentPage={'addUser'} /> */}
            <div>
                <div
                    style={{
                        width: '100%',
                        marginTop: '-33px',
                        padding: '32px 32px 32px 15px',
                    }}
                >
                    <div className="d-flex">
                        <NavLink style={{ 'text-decoration': 'none' }} to="/app/admin/enduser/">
                            <div
                                style={{
                                    color: 'black',
                                    fontWeight: 500,
                                    textTransform: 'none',
                                }}
                                className="d-flex align-items-center"
                            >
                                <FaArrowLeft style={{ fontSize: '16px' }} />
                                <label
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        marginLeft: '10px',
                                    }}
                                >
                                    Add New User
                                </label>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div style={{ padding: '0px 40px' }}>
                    <Formik
                        enableReinitialize
                        initialValues={
                            onEditPage
                                ? {
                                      first_name: userInfo.first_name,
                                      last_name: userInfo.last_name,
                                      email: userInfo.email,
                                      firm: userInfo.firm,
                                      country: userInfo.country,
                                      subscriptionType: userInfo.subscription_type,
                                      title: userInfo.title,
                                      is_active: userInfo.is_active,
                                      user_type: userInfo.user_type,
                                  }
                                : {
                                      first_name: '',
                                      last_name: '',
                                      email: '',
                                      firm: query.firm
                                          ? query.firm
                                          : allFirms && allFirms.length > 0 && allFirms.map(val => val.value)[0],
                                      country: query.country ? query.country : [],
                                      subscriptionType: query.subType ? query.subType : 'Firm',
                                      title: '',
                                      is_active: true,
                                      user_type: query.user_type ? query.user_type : 'Regular User',
                                  }
                        }
                        onSubmit={onSubmit}
                    >
                        {props => {
                            const { initialValues, values } = props

                            return (
                                <Form>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <Button
                                            type="submit"
                                            style={{
                                                width: 'max-content',
                                                height: '45px',
                                                position: 'fixed',
                                                right: '40px',
                                                top: '85px',
                                                backgroundColor: 'rgb(24, 106, 222)',
                                                color: 'white',
                                                fontSize: '16px',
                                                padding: '0px 15px',
                                                zIndex: 1,
                                                borderRadius: '4px',
                                                textTransform: 'none',
                                            }}
                                            disabled={values.first_name === '' || values.email === '' ? true : false}
                                        >
                                            Save
                                        </Button>
                                    </div>

                                    <div
                                        style={{
                                            marginTop: '-10px',
                                            background: 'white',
                                            borderRadius: '10px',
                                            boxShadow: '0px 2px 2px 0px #00000024',
                                        }}
                                    >
                                        <div>
                                            <div style={{ padding: '20px 40px 0px 40px' }}>
                                                <div
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    <span> User Information</span>
                                                    <span style={{ marginLeft: '10px' }}>
                                                        <InfoIcon
                                                            id="user_info_tooltip"
                                                            style={{
                                                                cursor: 'pointer',
                                                                display: 'inline-block',
                                                            }}
                                                            className="tooltip_icon"
                                                        />
                                                        <Tooltip
                                                            placement="top-end"
                                                            isOpen={tooltips.user_info_tooltip}
                                                            target="user_info_tooltip"
                                                            toggle={() => toggle('user_info_tooltip')}
                                                            className="custom-tooltip"
                                                        >
                                                            Species the searches, topics and companies that interest the
                                                            user.This will help tailer their content feed and overall
                                                            experience
                                                        </Tooltip>
                                                    </span>
                                                </div>
                                                <label
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '300',
                                                        color: 'lightgray',
                                                    }}
                                                >
                                                    Enter the new user's details to get them started.
                                                </label>
                                            </div>
                                            <div
                                                className="d-md-flex d-sm-block"
                                                style={{
                                                    marginTop: '10px',
                                                    padding: '20px 40px 0px 40px',
                                                }}
                                            >
                                                <div className="responsive-width">
                                                    <div>
                                                        <label style={{ fontSize: '16px', fontWeight: '400' }}>
                                                            Name
                                                        </label>
                                                        <br />
                                                        <Field
                                                            type={fields.firstName.type}
                                                            name={fields.firstName.name}
                                                            id={fields.firstName.name}
                                                            placeholder={'Enter Name'}
                                                            style={{
                                                                marginRight: '20px',
                                                                width: '90%',
                                                                height: '40px',
                                                                borderRadius: '4px',
                                                                background: 'white',
                                                                border: '1px solid hsl(0,0%,80%)',
                                                                outline: 'none',
                                                                fontSize: '14px',
                                                                paddingLeft: '2%',
                                                                marginTop: '10px',
                                                            }}
                                                            className="adminEndUser__textInput"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div style={{ width: '32%' }}>
                                                    <label>Last Name</label>
                                                    <br />
                                                    <Field
                                                        type={fields.lastName.type}
                                                        name={fields.lastName.name}
                                                        id={fields.lastName.name}
                                                        placeholder={fields.lastName.placeholder}
                                                        style={{
                                                            marginTop: '10px',
                                                            marginRight: '10px',
                                                            height: '45px',
                                                        }}
                                                        className="adminEndUser__textInput"
                                                    />
                                                </div> */}
                                                <div className="responsive-width mt-3 mt-sm-0">
                                                    <div>
                                                        <label style={{ fontSize: '16px', fontWeight: '400' }}>
                                                            Email
                                                        </label>
                                                        <br />
                                                        <Field
                                                            type={fields.email.type}
                                                            name={fields.email.name}
                                                            id={fields.email.name}
                                                            onBlur={ev => validateEmail(ev.target.value)}
                                                            placeholder={'Enter Email'}
                                                            style={{
                                                                marginRight: '20px',
                                                                width: '90%',
                                                                height: '40px',
                                                                borderRadius: '4px',
                                                                background: 'white',
                                                                border: '1px solid hsl(0,0%,80%)',
                                                                outline: 'none',
                                                                fontSize: '14px',
                                                                paddingLeft: '2%',
                                                                marginTop: '10px',
                                                            }}
                                                            className="adminEndUser__textInput"
                                                        />
                                                    </div>
                                                    <div className="responsive-width">
                                                        {emailExists && (
                                                            <div
                                                                className="adminEndUser__error"
                                                                style={{ marginBottom: '0px' }}
                                                            >
                                                                Email already exists
                                                            </div>
                                                        )}
                                                        {emailError && (
                                                            <div
                                                                className="adminEndUser__error"
                                                                style={{ marginBottom: '0px' }}
                                                            >
                                                                Email not valid
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="d-md-flex d-sm-block"
                                                style={{ padding: '20px 40px 0px 40px' }}
                                            >
                                                {/* {usertype !== 'client_analyst' && (
                                                    <div style={{ width: '32%' }}>
                                                        <label>Subcription Type</label>
                                                        <br />
                                                        <Field
                                                            name={fields.subscriptionType.name}
                                                            as={fields.subscriptionType.type}
                                                            id={fields.subscriptionType.name}
                                                            placeholder={fields.subscriptionType.placeholder}
                                                            style={{
                                                                marginTop: '10px',
                                                                marginRight: '10px',
                                                                height: '45px',
                                                            }}
                                                            className="adminEndUser__textInput"
                                                        >
                                                            <option disabled value="0">
                                                                Subscription Type
                                                            </option>
                                                            <option value="Direct">Subscription Type: Direct</option>
                                                            <option value="Firm">Subscription Type: Firm</option>
                                                        </Field>
                                                    </div>
                                                )} */}

                                                <div className="responsive-width">
                                                    <div style={{ width: '90%' }}>
                                                        <label
                                                            style={{
                                                                fontSize: '16px',
                                                                fontWeight: '400',
                                                                marginBottom: '30px',
                                                            }}
                                                        >
                                                            Region
                                                        </label>
                                                        <div style={{ marginTop: '-25px' }}>
                                                            <RegionHiearchyMultiSelect
                                                                regionHierarchy={regionHierarchy}
                                                                selectedQueries={selectedCountry}
                                                                setSelectedQueries={setSelectedCountry}
                                                                isAnalystAdminAdd={true}
                                                                selectedPreference={defaultCountry}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="responsive-width mt-3 mt-sm-0">
                                                    <div>
                                                        <label
                                                            style={{
                                                                fontSize: '16px',
                                                                fontWeight: '400',
                                                                marginBottom: '10px',
                                                            }}
                                                            className="d-flex align-items-center"
                                                        >
                                                            Practice Area
                                                            <span style={{ marginLeft: '10px' }}>
                                                                <InfoIcon
                                                                    id="user_practice_area"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        display: 'inline-block',
                                                                    }}
                                                                    className="tooltip_icon"
                                                                />
                                                                <Tooltip
                                                                    placement="top-end"
                                                                    isOpen={tooltips.user_practice_area}
                                                                    target="user_practice_area"
                                                                    toggle={() => toggle('user_practice_area')}
                                                                    className="custom-tooltip"
                                                                >
                                                                    Select a practice area that is closest to your
                                                                    specialization - this setting is used to customize
                                                                    prompts for GPT.
                                                                </Tooltip>
                                                            </span>
                                                        </label>

                                                        <Field
                                                            name={fields.practiceArea.name}
                                                            as={fields.practiceArea.type}
                                                            id={fields.practiceArea.name}
                                                            placeholder={'Select Practice Area'}
                                                            style={{
                                                                marginRight: '20px',
                                                                width: '90%',
                                                                height: '40px',
                                                                borderRadius: '4px',
                                                                background: 'white',
                                                                border: '1px solid hsl(0,0%,80%)',
                                                                outline: 'none',
                                                                fontSize: '14px',
                                                                paddingLeft: '2%',
                                                                marginTop: '10px',
                                                            }}
                                                            className="adminEndUser__textInput"
                                                            onChange={getSubcategoriesFromPracticeArea}
                                                            value={selectedPracticeArea.value}
                                                        >
                                                            <option disabled value="0">
                                                                Practice Area
                                                            </option>
                                                            {allPracticeAreas.map(area => {
                                                                return (
                                                                    <option
                                                                        key={'area-options-' + area.id}
                                                                        value={area.id}
                                                                    >
                                                                        {area.name}
                                                                    </option>
                                                                )
                                                            })}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="d-md-flex d-sm-block"
                                                style={{ padding: '20px 40px 0px 40px' }}
                                            >
                                                <div className="responsive-width">
                                                    <div>
                                                        <label style={{ fontSize: '16px', fontWeight: '400' }}>
                                                            User Category
                                                        </label>
                                                        <br />
                                                        <Field
                                                            name={fields.user_type.name}
                                                            as={fields.user_type.type}
                                                            id={fields.user_type.name}
                                                            placeholder={'Select User Category'}
                                                            style={{
                                                                marginRight: '20px',
                                                                width: '90%',
                                                                height: '40px',
                                                                borderRadius: '4px',
                                                                background: 'white',
                                                                border: '1px solid hsl(0,0%,80%)',
                                                                outline: 'none',
                                                                fontSize: '14px',
                                                                paddingLeft: '2%',
                                                                marginTop: '10px',
                                                            }}
                                                            className="adminEndUser__textInput"
                                                            onChange={e => {
                                                                setSelUser(e.target.value)
                                                            }}
                                                            value={selUSer}
                                                        >
                                                            <option value="Lite User">Lite User</option>
                                                            <option value="Power User">Power User</option>
                                                            <option value="Regular User">Regular User</option>
                                                        </Field>
                                                    </div>
                                                </div>

                                                {selUSer === 'Regular User' && (
                                                    <div className="responsive-width mt-3 mt-sm-0">
                                                        <div>
                                                            <label
                                                                style={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '400',
                                                                }}
                                                                className="d-flex align-items-center"
                                                            >
                                                                Role
                                                                <span style={{ marginLeft: '10px' }}>
                                                                    <InfoIcon
                                                                        id="user_role"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            display: 'inline-block',
                                                                        }}
                                                                        className="tooltip_icon"
                                                                    />
                                                                    <Tooltip
                                                                        placement="top-end"
                                                                        isOpen={tooltips.user_role}
                                                                        target="user_role"
                                                                        toggle={() => toggle('user_role')}
                                                                        className="custom-tooltip"
                                                                    >
                                                                        Choose the role that best describes user's
                                                                        position within the organization. Role is used
                                                                        to customize GPT prompts for some of the outputs
                                                                        such as Thought Leadership and Social Posts.
                                                                    </Tooltip>
                                                                </span>
                                                            </label>

                                                            <Field
                                                                name={fields.role.name}
                                                                as={fields.role.type}
                                                                id={fields.role.name}
                                                                placeholder={'Select Role'}
                                                                style={{
                                                                    marginRight: '20px',
                                                                    width: '90%',
                                                                    height: '40px',
                                                                    borderRadius: '4px',
                                                                    background: 'white',
                                                                    border: '1px solid hsl(0,0%,80%)',
                                                                    outline: 'none',
                                                                    fontSize: '14px',
                                                                    paddingLeft: '2%',
                                                                    marginTop: '10px',
                                                                }}
                                                                className="adminEndUser__textInput"
                                                                value={selectedRole}
                                                                onChange={e => setSelectedRole(e.target.value)}
                                                            >
                                                                <option value="null">Select Role</option>
                                                                {role.map(val => {
                                                                    return (
                                                                        <option
                                                                            key={'role-options-' + val.id}
                                                                            value={val.id}
                                                                        >
                                                                            {val.role}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className="d-md-flex d-sm-block"
                                                style={{ padding: '20px 40px 20px 40px' }}
                                            >
                                                {usertype === 'analyst' && (
                                                    <div className="responsive-width">
                                                        <div>
                                                            <label style={{ fontSize: '16px', fontWeight: '400' }}>
                                                                Firm
                                                            </label>
                                                            <br />
                                                            <Field
                                                                name={fields.firm.name}
                                                                as={fields.firm.type}
                                                                id={fields.firm.name}
                                                                placeholder={'Select Firm'}
                                                                onChange={ev => {
                                                                    props.setFieldValue(
                                                                        fields.firm.name,
                                                                        ev.target.value
                                                                    )
                                                                    getFirmSectorsCompanies(
                                                                        ev.target.value,
                                                                        props.values
                                                                    )
                                                                }}
                                                                style={{
                                                                    marginRight: '20px',
                                                                    width: '90%',
                                                                    height: '40px',
                                                                    borderRadius: '4px',
                                                                    background: 'white',
                                                                    border: '1px solid hsl(0,0%,80%)',
                                                                    outline: 'none',
                                                                    fontSize: '14px',
                                                                    paddingLeft: '2%',
                                                                    marginTop: '10px',
                                                                }}
                                                                className="adminEndUser__textInput"
                                                            >
                                                                <option disabled value="0">
                                                                    Firm
                                                                </option>
                                                                {allFirms.map(firm => {
                                                                    return (
                                                                        <option
                                                                            key={'firm-options-' + firm.value}
                                                                            value={firm.value}
                                                                        >
                                                                            {firm.label}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                )}
                                                {usertype !== 'Regular User' && (
                                                    <div className="responsive-width mt-3 mt-sm-0">
                                                        <div
                                                            style={{
                                                                marginLeft: usertype === 'Power User' ? '' : 'auto',
                                                            }}
                                                        >
                                                            <label style={{ fontSize: '16px', fontWeight: '400' }}>
                                                                Active
                                                            </label>
                                                            <br />
                                                            <Field
                                                                name={fields.is_active.name}
                                                                as={fields.is_active.type}
                                                                id={fields.is_active.name}
                                                                placeholder={'Select Active'}
                                                                style={{
                                                                    marginRight: '20px',
                                                                    width: '90%',
                                                                    height: '40px',
                                                                    borderRadius: '4px',
                                                                    background: 'white',
                                                                    border: '1px solid hsl(0,0%,80%)',
                                                                    outline: 'none',
                                                                    fontSize: '14px',
                                                                    paddingLeft: '2%',
                                                                    marginTop: '10px',
                                                                }}
                                                                className="adminEndUser__textInput"
                                                            >
                                                                <option value="false">Not Active</option>
                                                                <option value="true">Active</option>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="pre-padding bg-white"
                                        style={{
                                            marginTop: '20px',
                                            borderRadius: '10px',
                                            boxShadow: '0px 2px 2px 0px #00000024',
                                        }}
                                    >
                                        <div className="bg-white">
                                            <div
                                                style={{
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                <span> User Preferences</span>
                                            </div>
                                            <label
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: '300',
                                                    color: 'lightgray',
                                                }}
                                            >
                                                Assign user preferences here. This will help us deliver tailored news,
                                                thought leadership topics and social media recommendations to user
                                                inbox. 
                                            </label>
                                        </div>

                                        <div className="d-md-flex d-sm-block mt-3 align-items-center justify-content-between">
                                            <div className="responsive-width">
                                                <PreferenceContainers
                                                    header={'Assign Saved Search'}
                                                    subHeader={'Selected saved search'}
                                                    placeHolder={'Select from list'}
                                                    title={'select saved search that you are interested in.'}
                                                    data={allSavedSearches}
                                                    selectedData={selectSavedSearches}
                                                    setSelectedData={setSelectSavedSearches}
                                                    input={input}
                                                    setInput={setInput}
                                                    selectedinput={selectedinput}
                                                    setSelectedInput={setSelectedInput}
                                                    state={'savedsearch'}
                                                    handleToggle={handleToggle}
                                                    handleRemove={handleRemove}
                                                    toggle={toggle}
                                                />
                                            </div>

                                            <div
                                                className="d-none d-sm-block"
                                                style={{ width: '50%', fontSize: '16px', fontWeight: 400 }}
                                            >
                                                Choose relevant saved searches to include in the user's news updates.
                                            </div>
                                        </div>

                                        <div className="d-md-flex d-sm-block mt-4">
                                            <div className="responsive-width">
                                                <PreferenceContainers
                                                    header={'Companies of Interest'}
                                                    title={'Search and select companies that you are interested in.'}
                                                    subHeader={'Selected Companies'}
                                                    placeHolder={'Search and Select Companies of Interest'}
                                                    data={allCompetitors}
                                                    selectedData={selectedCompanies}
                                                    setSelectedData={setSelectedCompanies}
                                                    input={input}
                                                    setInput={setInput}
                                                    selectedinput={selectedinput}
                                                    setSelectedInput={setSelectedInput}
                                                    state={'company'}
                                                    handleToggle={handleToggle}
                                                    handleRemove={handleRemove}
                                                    tooltip={tooltips.user_companies}
                                                    toggle={toggle}
                                                />
                                            </div>

                                            {/* <div className="responsive-width">
                                                <PreferenceSectorContainer
                                                    header={'All Topics'}
                                                    subHeader={'Selected Topics'}
                                                    placeHolder={'Search Topics'}
                                                    selectedData={selectedSectors}
                                                    selectedSectors={selectedSectors}
                                                    setSelectedData={setSelectedSectors}
                                                    input={input}
                                                    selectedinput={selectedinput}
                                                    setSelectedInput={setSelectedInput}
                                                    allHierarchy={allHierarchy}
                                                    checkedTopics={checkedTopics}
                                                    setInput={setInput}
                                                    sectorInput={sectorInput}
                                                    setsectorInput={setsectorInput}
                                                    state={'sector'}
                                                    sectorOpen={sectorOpen}
                                                    setSectorOpen={setSectorOpen}
                                                    topicOpen={topicOpen}
                                                    setTopicOpen={setTopicOpen}
                                                    toggleid={toggleid}
                                                    bulkChange={bulkChange}
                                                    handleRemove={handleRemoveTopic}
                                                    changeCheck={changeCheck}
                                                    handleSelectAllTopics={handleSelectAllTopics}
                                                    tooltip={tooltips.user_topics}
                                                    toggle={toggle}
                                                />
                                            </div> */}

                                            {allWithoutHierarchy.length > 0 && (
                                                <div className="responsive-width mt-3 mt-sm-0">
                                                    <PreferenceContainers
                                                        header={'Topics'}
                                                        subHeader={'Selected Topics'}
                                                        placeHolder={'Search and Select Topics of Interest'}
                                                        title={'Search and select topics that you are interested in.'}
                                                        data={allWithoutHierarchy}
                                                        selectedData={selectedSectors}
                                                        setSelectedData={setSelectedSectors}
                                                        input={input}
                                                        setInput={setInput}
                                                        selectedinput={selectedinput}
                                                        setSelectedInput={setSelectedInput}
                                                        state={'sector'}
                                                        handleToggle={handleToggle}
                                                        handleRemove={handleRemove}
                                                        tooltip={tooltips.user_topics}
                                                        toggle={toggle}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="mt-4 bg-white"
                                        style={{ borderRadius: '10px', boxShadow: '0px 2px 2px 0px #00000024' }}
                                    >
                                        <div className="pre-padding bg-white" style={{ borderRadius: '10px' }}>
                                            <div
                                                style={{
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                <span> Select Products</span>
                                            </div>
                                            <label
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: '300',
                                                    color: 'lightgray',
                                                }}
                                            >
                                                Select which type of emails you want in the user inbox: Kaitongo Digest,
                                                Thought Leadership Ideas, Social Media Content.{' '}
                                            </label>
                                        </div>
                                        <div className="d-md-flex d-sm-block align-items-center">
                                            <div className="responsive_cron mt-3">
                                                <div
                                                    className="crontab_container"
                                                    style={{
                                                        border: '1px solid lightgray',
                                                        borderRadius: '5px',
                                                        padding: '20px',
                                                        height: '180px',
                                                    }}
                                                    title="Click the Select button to allow newsletter scheduling."
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '18px',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        Kaitongo Digest
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        Get tailored news updates straight to your inbox{' '}
                                                    </div>

                                                    <Button
                                                        style={{
                                                            cursor: 'pointer',
                                                            borderRadius: '5px',
                                                            fontWeight: 500,
                                                            textTransform: 'none',
                                                            display: 'flex',
                                                            margin: 'auto',
                                                            padding: '3px 15px',
                                                            fontSize: '14px',
                                                        }}
                                                        className={
                                                            digestIsEnabled
                                                                ? 'crontab_button_enable mt-3'
                                                                : 'crontab_button_disable mt-3'
                                                        }
                                                        onClick={() => {
                                                            setDigestIsEnabled(true)
                                                        }}
                                                    >
                                                        {digestIsEnabled ? 'Selected' : 'Select +'}
                                                    </Button>

                                                    {digestIsEnabled && (
                                                        <Button
                                                            style={{
                                                                cursor: 'pointer',
                                                                borderRadius: '5px',
                                                                fontWeight: 500,
                                                                textTransform: 'none',
                                                                display: 'flex',
                                                                margin: 'auto',
                                                                display: 'none',
                                                                padding: '3px 15px',
                                                                fontSize: '14px',
                                                            }}
                                                            className="crontab_remove_button mt-2"
                                                            onClick={() => {
                                                                setDigestIsEnabled(false)
                                                            }}
                                                        >
                                                            {'remove x'}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    margin: 'auto',
                                                    padding: '20px 0px 20px 10px',
                                                    color: !digestIsEnabled ? 'lightgray' : 'black',
                                                }}
                                                className="responsive-width"
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '500',
                                                        marginLeft: '20px',
                                                    }}
                                                >
                                                    Kaitongo Digest Email Frequency
                                                </div>

                                                <CronTab
                                                    isEnabled={digestIsEnabled}
                                                    setIsEnabled={setDigestIsEnabled}
                                                    cronTabValue={digestCronTabValue}
                                                    setCronTabValue={setDigestCronTabValue}
                                                    isMypreference={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-md-flex d-sm-block align-items-center">
                                            <div className="responsive_cron">
                                                <div
                                                    className="crontab_container"
                                                    style={{
                                                        border: '1px solid lightgray',
                                                        borderRadius: '5px',
                                                        padding: '20px',
                                                        height: '180px',
                                                    }}
                                                    title="Click the Select button to allow newsletter scheduling."
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '18px',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        Thought Leadership
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        Get thought leadership content and ideas
                                                    </div>

                                                    <Button
                                                        style={{
                                                            cursor: 'pointer',
                                                            borderRadius: '5px',
                                                            fontWeight: 500,
                                                            textTransform: 'none',
                                                            display: 'flex',
                                                            margin: 'auto',
                                                            padding: '3px 15px',
                                                            fontSize: '14px',
                                                        }}
                                                        className={
                                                            thoughtIsEnabled
                                                                ? 'crontab_button_enable mt-3'
                                                                : 'crontab_button_disable mt-3'
                                                        }
                                                        onClick={() => {
                                                            setThoughtIsEnabled(true)
                                                        }}
                                                    >
                                                        {thoughtIsEnabled ? 'Selected' : 'Select +'}
                                                    </Button>

                                                    {thoughtIsEnabled && (
                                                        <Button
                                                            style={{
                                                                cursor: 'pointer',
                                                                borderRadius: '5px',
                                                                fontWeight: 500,
                                                                textTransform: 'none',
                                                                display: 'flex',
                                                                margin: 'auto',
                                                                display: 'none',
                                                                padding: '3px 15px',
                                                                fontSize: '14px',
                                                            }}
                                                            className="crontab_remove_button mt-2"
                                                            onClick={() => {
                                                                setThoughtIsEnabled(false)
                                                            }}
                                                        >
                                                            {'remove x'}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    margin: 'auto',
                                                    padding: '20px 0px 20px 10px',
                                                    color: !thoughtIsEnabled ? 'lightgray' : 'black',
                                                }}
                                                className="responsive-width"
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '500',
                                                        marginLeft: '20px',
                                                    }}
                                                >
                                                    Kaitongo Thought Leadership Frequency
                                                </div>

                                                <CronTab
                                                    isEnabled={thoughtIsEnabled}
                                                    setIsEnabled={setThoughtIsEnabled}
                                                    cronTabValue={thoughtCronTabValue}
                                                    setCronTabValue={setThoughtCronTabValue}
                                                    isMypreference={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-md-flex d-sm-block align-items-center">
                                            <div className="responsive_cron">
                                                <div
                                                    className="crontab_container"
                                                    style={{
                                                        border: '1px solid lightgray',
                                                        borderRadius: '5px',
                                                        padding: '20px',
                                                        height: '180px',
                                                    }}
                                                    title="Click the Select button to allow newsletter scheduling."
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '18px',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        Social Media Content
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        Get relevant, ready-to-post LinkedIn posts
                                                    </div>

                                                    <Button
                                                        style={{
                                                            cursor: 'pointer',
                                                            borderRadius: '5px',
                                                            fontWeight: 500,
                                                            textTransform: 'none',
                                                            display: 'flex',
                                                            margin: 'auto',
                                                            padding: '3px 15px',
                                                            fontSize: '14px',
                                                        }}
                                                        className={
                                                            isEnabled
                                                                ? 'crontab_button_enable mt-3'
                                                                : 'crontab_button_disable mt-3'
                                                        }
                                                        onClick={() => {
                                                            setIsEnabled(true)
                                                        }}
                                                    >
                                                        {isEnabled ? 'Selected' : 'Select +'}
                                                    </Button>

                                                    {isEnabled && (
                                                        <Button
                                                            style={{
                                                                cursor: 'pointer',
                                                                borderRadius: '5px',
                                                                fontWeight: 500,
                                                                textTransform: 'none',
                                                                display: 'flex',
                                                                margin: 'auto',
                                                                display: 'none',
                                                                padding: '3px 15px',
                                                                fontSize: '14px',
                                                            }}
                                                            className="crontab_remove_button mt-2"
                                                            onClick={() => {
                                                                setIsEnabled(false)
                                                            }}
                                                        >
                                                            {'remove x'}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    margin: 'auto',
                                                    padding: '20px 0px 20px 10px',
                                                    color: !isEnabled ? 'lightgray' : 'black',
                                                }}
                                                className="responsive-width"
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '500',
                                                        marginLeft: '20px',
                                                    }}
                                                >
                                                    Kaitongo Social Media Content Frequency
                                                </div>

                                                <CronTab
                                                    isEnabled={isEnabled}
                                                    setIsEnabled={setIsEnabled}
                                                    cronTabValue={cronTabValue}
                                                    setCronTabValue={setCronTabValue}
                                                    isMypreference={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </BaseScreen>
    )
}
