import axios from 'axios'
import { toast } from 'react-toastify'
import store from '../Store'

const landingPageMappings = store.getState().auth.landingPageMappings
export const redirectUser = (user, history, next = null) => {
    switch (user.user_type) {
        case 'analyst':
            // axios.get(`/accounts/analyst/${user.id}/groups/`).then(response => {
            history.push(`/app/analyst-dashboard/`)
            // })
            break

        case 'client_analyst':
            history.push('/app/firm-analyst/')
            break

        case 'Power User':
            history.push('/app/assistant/')
            break

        case 'Regular User':
            if (user.subscription_type === 'Direct') {
                history.push(`/app/direct-dashboard/`)
            } else if (user.subscription_type === 'Firm') {
                if (next) {
                    history.push(next.replace('?next=', ''))
                } else if (user.landing_page && landingPageMappings[user.landing_page]) {
                    if (user.login_count === 1) {
                        history.push(`/app/preferences/`)
                    } else {
                        if (landingPageMappings[user.landing_page].includes('/app/assistant/')) {
                            history.push(`/app/assistant/`)
                        } else if (
                            !landingPageMappings[user.landing_page].includes('/app/topic/') &&
                            !landingPageMappings[user.landing_page].includes('/app/company/')
                        ) {
                            history.push(landingPageMappings[user.landing_page])
                        } else if (landingPageMappings[user.landing_page].includes('/app/company/')) {
                            history.push(`/app/companies/`)
                        } else if (landingPageMappings[user.landing_page].includes('/app/topic/')) {
                            history.push(`/app/sectors/`)
                        }
                    }
                } else {
                    history.push('/')
                }
            } else {
                history.push('/app/recommendations/')
            }
            break

        case 'user':
            history.push(`/`)
            toast.warn('User type error')
            break
        default:
            return history.push('/')
    }
}
